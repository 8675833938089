<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">Stores</h3>
                  <b-button
                  class="btn-danger export-api-btn"
                  @click="reloadDatatable"
                  v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="storesTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th width="8%">Merchant ID</th>
                        <th width="8%">Store ID</th>
                        <th width="11%">Retailer</th>
                        <th width="11%">Address</th>
                        <th width="10%">Contact No.</th>
                        <th width="10%">Email</th>
                        <th width="5%">Created On</th>
                        <th width="5%">Action</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Store Daily transaction Email Modal Start -->
    <b-modal
      id="sm-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="exampleInputEmail1">
                Email addresses for Daily Transaction Activity (Emails separated by Comma)
                <span class="red">*</span>
              </label>
              <input
                type="email"
                v-model="storeDetails.cp_emails"
                placeholder="Enter email addresses for Daily Transaction Activity (Emails separated by Comma)"
                class="form-control"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Store Daily transaction Email Modal End -->
  </div>
</template>
<script>
import moment from "moment";
import api from "@/api/stores.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
export default {
  mixins: [validationMixin],
  data() {
    return {
      modalTitle: "",
      storeDetails:{},
      allStoreModel: {},
      headerTextVariant: "light",
      currentUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      showReloadBtn:false,
      constants: Constants,
    };
  },
  created() {
    this.editEmailDailyTransaction();
  },
  methods: {
    reloadDatatable(){
      var self = this;
      self.loadDT();
    },
    loadDT: function () {
      var self = this;
      $("#storesTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [7] },
          { className: "dt-left break-all", targets: [0, 1, 2, 3, 4, 5, 6] },
          { className: "dt-center", targets: [7] },
        ],
        order: [[6, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Stores Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchantadmin/getmerchantstores",
          type: "POST",
          data: { _token: "{{csrf_token()}}" },
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allStoreModel = result.data;
            return self.allStoreModel;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#storesTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "merchantID"},
          { data: "store_id" },
          { data: "retailer" },
          {
            render: function (data, type, full, meta) {
              var address = full.address != null ? full.address : '';
              var city = full.city != null ? full.city : '';
              var state = full.state != null ? full.state : '';
              var county = full.county != null ? full.county : '';
              var zip = full.zip != null ? full.zip : '';
              return (
                address +
                ", " +
                city +
                ", " +
                state +
                ", " +
                county +
                ", " +
                zip
              );
            },
          },
          { data: "contact_no" },
          { data: "email" },
          { data: "created_at" },
          {
            render: function (data, type, full, meta) {
              return (
                '<b-button data-user-id="' +
                full.id +
                '" class="editEmailDailyTransaction custom-edit-btn" title="Add Email Address for Daily Transaction Email" variant="outline-success"><i class="nav-icon fas fa-edit"></i></b-button>'
              );
            },
          },
        ],
      });

      $("#storesTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#storesTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
    checkValidation() {
      let self = this;
      var error_flag = 0;
      if(self.storeDetails.cp_emails!= '' && self.storeDetails.cp_emails!= null){
        var cp_emails = self.storeDetails.cp_emails.split(',');
        $.each(cp_emails, function( index, value ) {
          if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim())  == false){
            error_flag = 1;
            error("Please enter a valid Email Address.");
            return false;
          }
        });
      }else{
        error("The Email Address Field Is Required.");
        return false;
      }

      if(error_flag == 0){
        return true;
      }
    },
    resetModal() {
      var self = this;
      self.storeDetails = {};
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },
    save() {
      var self = this;
      if (self.checkValidation()) {
        api
        .addStoreDailyTransactionEmail(self.storeDetails)
        .then((response) => {
          if (response.code == 200) {
            success(response.message);
            $("#storesTable").DataTable().ajax.reload(null, false);
            self.$bvModal.hide("sm-modal");
            self.resetModal();
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
      }
    },
    editEmailDailyTransaction() {
      var self = this;
      $(document).on("click", ".editEmailDailyTransaction", function (e) {
        self.storeDetails = self.allStoreModel.find(
          (p) => p.id == $(e.currentTarget).attr("data-user-id")
        );
        self.modalTitle = "Add Email Address for Daily Transaction Email";
        self.$bvModal.show("sm-modal");
      });
    },
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    document.title = "CanPay - Stores";
  },
};
</script>

