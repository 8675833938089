<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">Void Transactions</h3>
                </div>
                <div class="loading" v-if="loading">
                  <hour-glass></hour-glass>
                </div>
                <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="transaction-date form-control"
                        placeholder="Transaction Date"
                        id="transaction-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                    <div class="col-md-4">
                        <div class="form-group">
                        <multiselect
                            id="store"
                            v-model="selectedStore"
                            placeholder="Select Store (Min 3 chars)"
                            label="retailer"
                            :options="storelist"
                            :loading="isLoading"
                            :internal-search="false"
                            @search-change="getAllAuthorizedStores"
                        ></multiselect>
                        </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                        <input
                          autocomplete="off"
                          class="form-control"
                          placeholder="Amount"
                          id="amount"
                          v-model="amount"
                        />
                      </div>
                    </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="generateReport(false)"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="generateReport(true)"
                  class="btn btn-danger ml-10"
                >
                  Generate & Export<i
                    class="fa fa-download ml-10"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <div class="row">
                  <div class="col-12"><b-table-simple
                      responsive
                      show-empty
                      bordered
                      sticky-header="800px"
                    >
                      <b-thead head-variant="light">
                        <b-tr>
                          <b-th class="text-center">Transaction Number</b-th>
                          <b-th class="text-center">Consumer</b-th>
                          <b-th class="text-center">Merchant</b-th>
                          <b-th class="text-center">Store</b-th>
                          <b-th class="text-center">Terminal</b-th>
                          <b-th class="text-center">Amount ($)</b-th>
                          <b-th class="text-center">Transaction Time</b-th>
                          <b-th class="text-center">Status</b-th>
                          <b-th class="text-center">Cancel Transaction</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody v-for="(row, index) in report" :key="index">
                        <b-tr>
                          <b-td class="text-left text-gray">{{
                            row.transaction_number
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.consumer_name
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.merchant_name
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.store_name
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.terminal_name
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.amount
                          }}</b-td>
                          <b-td v-html="row.transaction_time" class="text-left text-gray"></b-td>
                          <b-td class="text-left text-gray">{{
                            row.status
                          }}</b-td>
                          <b-td class="text-left text-gray" v-if="row.cancelable && row.status != 'Voided'">
                            <a style="color: white !important" class="btn btn-danger leave-comment" :data-id="row.edit" @click="openVoidModal(row.edit);">Cancel</a></b-td>
                          <b-td class="text-left text-gray" v-else></b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Transaction cancellation comment modal start -->
    <b-modal
      id="comment-modal"
      ref="comment-modal"
      ok-title="Save & Cancel"
      cancel-title="Close"
      ok-variant="success"
      @ok="cancelTransaction"
      cancel-variant="outline-secondary"
      hide-header
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <label for="comment">
            Tell us why you want to cancel this transaction
            <span class="red">*</span>
          </label>
          <textarea
            name="comment"
            type="text"
            v-model="comment"
            class="form-control"
          />
        </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>
      <div class="row" v-if="showMsg">
        <div class="col-12">
          <label for="comment" class="red"
            >Please fill in the required fields.</label
          >
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction cancellation comment modal end -->
  </div>
</template>
<script>
import api from "@/api/transaction.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
import { HourGlass } from "vue-loading-spinner";
export default {
  mixins: [validationMixin],
  data() {
    return {
      allTransactionModel: {},
      showMsg: false,
      transaction_id: null,
      comment: "",
      currentUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      showReloadBtn:false,
      constants: Constants,
      isLoading: false,
      loading: false,
      report: [],
      consumerList: [],
      selectedConsumer: null,
      email:"",
      phone_no:"",
      storelist: [],
      selectedStore: null,
      amount:""
    };
  },
  components: {
    HourGlass,
  },
  created() {
    //fetch store list for that
    this.getAllAuthorizedStores();
  },
  methods: {
    //API call to fetch authorized stores
    getAllAuthorizedStores() {
      var self = this;
      apiuser
        .getStores()
        .then(function (response) {
          if (response.code == 200) {
            self.storelist = response.data;
            if (self.storelist.length == 1) {
              self.selectedStore = self.storelist[0];
            }
          } else if (response.code == 401) {
            self.getConfirmation();
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {});
    },
    //get the consumer list
    getConsumers(searchtxt) {
      var self = this;
      if(searchtxt.length >= 3){
        self.isLoading = true;
        var request = {
          searchtxt: searchtxt,
        };
        api
          .getConsumers(request)
          .then(function (response) {
            if (response.code == 200) {
              self.consumerList = response.data;
              self.isLoading = false;
            } else {
              error(response.message);
            }
          })
          .catch(function (error) {
            error(error);
          });
      }
    },
    openVoidModal(id){
      var self = this;
      self.$refs["comment-modal"].show();
      self.comment = "";
      self.transaction_id = id;
    },
    cancelTransaction(bvModalEvt) {
      var self = this;
      if (self.comment == "") {
        self.showMsg = true;
        bvModalEvt.preventDefault();
      } else {
        self.showMsg = false;
        var request = {
          transaction_id: self.transaction_id,
          comment: self.comment,
          transaction_place: "Web",
        };
        api
          .cancelTransaction(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
              self.$refs["comment-modal"].hide();
              self.comment = "";
              self.transaction_id = null;
              self.generateReport();
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            error(err);
          });
      }
    },
    generateReport(reportExport) {
      var self = this;
      if (
        moment($("#transaction-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD") && $("#transaction-date").val()!= ''
      ) {
        error("Transaction date cannot be from future.");
        return false;
      }

      if(self.selectedStore === null){
        var store_id = '';
      }else{
        var store_id = self.selectedStore.id;
      }

      if(store_id == ''){
        error("Please select Store to generate report.");
        return false;
      }
      
      if($("#transaction-date").val()!=''){
        var from_date = moment($("#transaction-date").val()).format("YYYY-MM-DD");
      }else{
        var from_date = '';
      }
      
      self.report = [];
      var request = {
        from_date: from_date,
        amount: self.amount,
        store_id:store_id
      };
      self.loading = true;
      api
        .generateConsumerTransactionReport(request)
        .then(function (response) {
          if (response.code == 200) {
            self.report = response.data;
            if(self.report.length > 0){
              if (reportExport) {
                self.exportReport();
              } else {
                self.loading = false;
              }
            }else {
              error("No records found!");
              self.loading = false;
            }
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },

    // exports the report
    exportReport() {
      var self = this;
      self.loading = true;

      var request = {
        report: self.report,
      };
      api
        .exportgenerateConsumerTransactionReport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_consumer_void_transaction_report.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
  },
  mounted() {
    var self = this;
    document.title = "CanPay - Void Transactions";

    $("#transaction-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#transaction-date").datepicker("setDate", new Date());
  },
};
</script>

F