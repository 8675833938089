<template>
    <div>
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6"></div>
                </div>
            </div>
        </section>

        <div class="hold-transition sidebar-mini">
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card card-success">
                                <div class="card-header">
                                <h3 class="card-title">Reduced Transaction Fees Report</h3>
                                <b-button
                                class="btn-danger export-api-btn"
                                @click="reloadDatatable"
                                v-if="showReloadBtn"
                                >
                                    <i class="fas fa-redo"></i> Reload
                                </b-button>
                                </div>
                                <div class="loading" v-if="loading">
                                    <hour-glass></hour-glass>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <!--- multiselect start --->
                                        <div class="col-md-4">
                                            <div class="form-group">
                                            <multiselect
                                                id="store"
                                                name="store"
                                                v-model="selectedStore"
                                                placeholder="Select Store"
                                                label="retailer"
                                                track-by="id"
                                                :options="storeList"
                                                :multiple="false"
                                                :searchable="true"
                                                :show-labels="false"
                                            ></multiselect>
                                            </div>
                                        </div>
                                        <!--- multiselect end --->
                                        <!--- start date start --->
                                        <div class="col-md-4">
                                            <div class="form-group">
                                            <input
                                                class="start-date form-control"
                                                placeholder="Start Date"
                                                id="start-date"
                                                onkeydown="return false"
                                                autocomplete="off"
                                            />
                                            </div>
                                        </div>
                                        <!--- start date end --->
                                        <!--- end date start --->
                                        <div class="col-md-4">
                                            <div class="form-group">
                                            <input
                                                class="end-date form-control"
                                                placeholder="End Date"
                                                id="end-date"
                                                onkeydown="return false"
                                                autocomplete="off"
                                            />
                                            </div>
                                        </div>
                                        <!--- end date end --->
                                    </div>
                                </div>
                                <!--- footer start --->
                                <div class="card-footer">
                                    <!-- generate Result -->
                                    <button
                                    type="button"
                                    class="btn btn-success"
                                    @click="generateReport(false)"
                                    >
                                    Generate
                                    </button>
                                    <!-- generate Excel Result -->
                                    <button
                                    type="button"
                                    @click="generateReport(true)"
                                    class="btn btn-danger ml-10"
                                    >
                                    Export <i
                                        class="fa fa-download ml-10"
                                    ></i>
                                    </button>
                                </div>
                                <!--- footer end --->
                                <!--- start of transaction table --->
                                <div class="card-body">
                                <table
                                    id="transactionTable"
                                    class="table"
                                    style="width: 100%; white-space: normal"
                                >
                                    <thead>
                                    <tr>
                                        <th>Transaction Number</th>
                                        <th>Store</th>
                                        <th>Terminal</th>
                                        <th>Amount ($)</th>
                                        <th>Tip Amount ($)</th>
                                        <th>Total Fees ($)</th>
                                        <th>Reduced Fee Amount ($)</th>
                                        <th>Transaction Time</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                </table>
                                </div>
                                <!--- end of transaction table --->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import user_api from "@/api/user.js";
import moment from "moment";
import api from "@/api/transaction.js";
export default {
data(){
    return {
        storeList:[],
        selectedStore: "",
        loading:false,
        showReloadBtn:false,
        allTransactionModel: {},
        from_date: "",
        to_date: "",
    }
},
methods:{
    generateReport(isExport){
        var self = this;
        if(self.selectedStore == ''){
            error("Please select store.");
            return false;
        }
        if (
            moment($("#start-date").val()).format("YYYY-MM-DD") >
            moment().format("YYYY-MM-DD")
        ) {
            error("Start date cannot be from future.");
            return false;
        }
        if (
            moment($("#end-date").val()).format("YYYY-MM-DD") >
            moment().format("YYYY-MM-DD")
        ) {
            error("End date cannot be from future.");
            return false;
        }
        if (
            moment($("#start-date").val()).format("YYYY-MM-DD") >
            moment($("#end-date").val()).format("YYYY-MM-DD")
        ) {
            error("End Date not greater than Start Date.");
            return false;
        }
        
        self.from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
        self.to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
        if(isExport){
            self.exportReport();
        }else{
            self.from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
            self.to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
            self.loadDT();
        }
    },
    exportReport(){
        var self = this;
        self.loading = true;
        var request = {
          from_date: self.from_date,
          to_date: self.to_date,
          store_id: self.selectedStore.id,
          merchant_fee_discount_id:1
        };
        api.getTransactionExport(request)
          .then(function (response) {
            var FileSaver = require("file-saver");
            var blob = new Blob([response], {
              type: "application/xlsx",
            });
            FileSaver.saveAs(
              blob,
              moment().format("MM/DD/YYYY") +
                "_ReducedTransactionFeesReport.xlsx"
            );
            self.loading = false;
          })
          .catch(function (error) {          
            self.loading = false;
            self.getStores();
          });
      },
    getStores() {
      var self = this;
      user_api
        .getStores()
        .then((response) => {
          if ((response.code = 200)) {
            self.storeList = response.data;
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
    },
    loadDT: function () {
      var self = this;
      var dataString = {
        _token: "{{csrf_token()}}",
        from_date: self.from_date,
        to_date: self.to_date,
        store_id: self.selectedStore.id,
        merchant_fee_discount_id:1
      };
      $("#transactionTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [4] },
          { className: "dt-left", targets: [0, 1, 2, 3, 4, 5, 6, 7, 8] },
        ],
        order: [[5, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Transactions Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchant/gettransactions",
          type: "POST",
          data: dataString,
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allTransactionModel = result.data;
            return self.allTransactionModel;
          },
          error: function (request, status, error) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#transactionTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "transaction_number" },
          { data: "store_name" },
          { data: "terminal_name" },
          { data: "last_approve_amount" },
          { data: "last_approve_tip_amount" },
          { data: "total_fees" },
          { data: "discount_fees_amount" },
          { data: "transaction_time" },
          { data: "status" },
          
        ],
      });

      $("#transactionTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#transactionTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
},
mounted(){
    var self = this;
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-07-01'),
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date").datepicker("setDate", "-7d");
    $("#end-date").datepicker("setDate", new Date());
    self.getStores();
    // setTimeout(function () {
    //   self.loadDT();
    // }, 2000);
    document.title = "CanPay - Reduced Transaction Fees Report";
}
}
</script>
<style scoped>

</style>