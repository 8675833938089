const commonConstants = {
    role_corporate_parent: 'Corporate Parent',
    role_regional_manager: 'Old Regional Manager',
    role_new_regional_manager: 'Regional Manager',
    role_new_remotepay_manager: 'Remotepay Manager',
    role_store_manager: 'Store Manager',
    role_accountant: 'Accountant',
    role_employee: 'Employee',
    role_device_manager: 'Device Manager',
    datatable_error: 'Try reloading data, if problem persists please contact administrator.',
    app_key_copy_success: 'APP Key copied successfully.',
    api_secret_copy_succes: 'API Secret copied successfully.',
    role_permission: {
        'Old Regional Manager': ['Dashboard', 'RemotePay Management', 'Releases', 'Users', 'Stores', 'Email addresses for Daily Transaction Activity', 'POS Transaction History', 'RemotePay Transaction History', 'RemotePay Holiday List and Timings', 'Reports', 'POS Terminals', 'POS Settings', 'POS Transaction', 'POS Transaction Void'],
        'Regional Manager': ['Dashboard', 'RemotePay Management', 'Releases', 'Users', 'Stores', 'Email addresses for Daily Transaction Activity', 'POS Transaction History', 'RemotePay Transaction History', 'RemotePay Holiday List and Timings', 'Reports', 'POS Terminals', 'POS Settings', 'POS Transaction', 'POS Transaction Void'],
        'Remotepay Manager': ['RemotePay Management', 'RemotePay Transaction History'],
        'Store Manager': ['Dashboard', 'Releases', 'Void Transactions', 'Reports', 'POS Terminals', 'POS Settings', 'POS Transaction', 'POS Transaction Void'],
        'Accountant': ['Dashboard', 'Releases', 'Reports'],
        'Device Manager': ['POS Terminals', 'POS Settings', 'POS Transaction'],
        'Employee': ['POS Transaction', 'POS Transaction Void'],
    }
};

export default commonConstants;