<template>
  <div>
    <section class="content-header">
    <div class="container-fluid">
    <div class="row mb-2">
        <div class="col-sm-12">
        <h1 class="m-0">Recent Releases</h1>
        </div>
    </div>
    </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="card-body">
                  <ul class="todo-list ui-sortable" data-widget="todo-list"  v-if="allReleaseNotes.length!=0">
                    <span v-for="release, index in allReleaseNotes" :key="release.id" class="todo-list">
                      <h5 v-if="(index > 0 && release.tag!=allReleaseNotes[index - 1].tag) || index == 0"># Release {{release.tag}} on {{dateFormatChange(release.created_at)}}</h5>
                      <ul class="todo-list ui-sortable" data-widget="todo-list">
                        <li><p class="direct-chat-name">{{release.release_note}}</p>
                        <p class="release-note-description">{{release.release_note_description}}</p></li>
                      </ul>
                    </span>
                  </ul>
                  <p v-else>No Recent Releases</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import moment from "moment";
import { HourGlass } from "vue-loading-spinner";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      allReleaseNotes:{},
      loading: false,
    };
  },
  components: {
    HourGlass,
  },
  created() {
    this.allReleases();
  },
  mounted() {
  },
  methods: {
    allReleases(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .allReleases(request)
        .then(function (response) {
          if (response.code == 200) {
            self.allReleaseNotes = response.data;
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    dateFormatChange(date) {
      return moment
        .utc(date)
        .local()
        .format(" M-DD-YYYY");
    },
  },
};
</script>