const getAllTerminals = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/getallterminalsunderstore', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const generatemerchantLocationTransactionReport = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/getmerchantlocationtransactionreport', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getmerchantlocationtransactionexport = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/getmerchantlocationtransactionexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const generateV1SettlementReport = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/settlementreport', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const viewMerchantRewarsList = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/viewmerchantrewards', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportv1SettlementReport = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/getsettlementexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportv1SettlementReportAllStores = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/getsettlementexportallstores', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getAuthorizedMerchants = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/getmerchants', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const generateLongTermSettlementReport = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/longtermsettlementreport', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportLongTermSettlementReport = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/getlongtermsettlementexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const generateTipReportV1 = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/tipreportv1', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportTipReportV1 = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/gettipreportv1export', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const generateTipReportV2 = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/tipreportv2', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportTipReportV2 = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/gettipreportv2export', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getMerchantTransactionDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/dashboard/storewisetransactiondetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const exportStoreTransactionDetails = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/dashboard/reports/storewisetransactiondetailsexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkNewReleases = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/dashboard/newreleases', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const allReleases = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/dashboard/allreleases', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const readReleaseNotes = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/dashboard/readreleasenotes', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const generateHistoricalSettlementReport = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/historicalsettlementreport', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportHistoricalSettlementReport = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/gethistoricalsettlementexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportHistoricalSettlementReportAllStores = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/gethistoricalsettlementexportallstores', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const generateRefreshToken = () => {
    return new Promise((res, rej) => {
        axios.get('/merchantadmin/reports/refreshtokenforblobtypes')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getMerchantTransactionToExpireDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/ecommercedashboard/daywisetransactionexpiredetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const viewStoreWiseTransactionExpireCount = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/ecommercedashboard/storewisetransactionexpirecount', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const preLoadTransactionModification = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/ecommercedashboard/preloadtransactionmodification', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const exportTransactionReportAllStores = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/gettransactionreportexportallstores', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getlatestTransactionReportHistory = () => {
    return new Promise((res, rej) => {
        axios.get('/merchantadmin/reports/getLatestTransactionReportHistory')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getlinkedUserList = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/sponsorlinkedusers', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    getAllTerminals,
    generatemerchantLocationTransactionReport,
    getmerchantlocationtransactionexport,
    generateV1SettlementReport,
    viewMerchantRewarsList,
    exportv1SettlementReport,
    exportv1SettlementReportAllStores,
    getAuthorizedMerchants,
    generateLongTermSettlementReport,
    exportLongTermSettlementReport,
    generateTipReportV1,
    exportTipReportV1,
    generateTipReportV2,
    exportTipReportV2,
    getMerchantTransactionDetails,
    exportStoreTransactionDetails,
    checkNewReleases,
    allReleases,
    readReleaseNotes,
    generateHistoricalSettlementReport,
    exportHistoricalSettlementReport,
    exportHistoricalSettlementReportAllStores,
    generateRefreshToken,
    getMerchantTransactionToExpireDetails,
    viewStoreWiseTransactionExpireCount,
    preLoadTransactionModification,
    exportTransactionReportAllStores,
    getlatestTransactionReportHistory,
    getlinkedUserList,
};