import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardLayout from '@/components/Layout/DashboardLayout.vue';
import AuthLayout from "@/components/Layout/AuthLayout.vue";
import Login from "@/components/Login.vue";
import Dashboard from "@/components/Dashboard.vue";
import SponsorDashboard from "@/components/SponsorDashboard.vue";
import EcommerceDashboard from "@/components/EcommerceDashboard.vue";
import EcommerceDashboardTemplate from "@/components/EcommerceDashboardTemplate.vue";
import UserProfile from "@/components/UserProfile.vue";
import RegionalManager from "@/components/users/RegionalManager.vue";
import RegionalManagers from "@/components/users/RegionalManagers.vue";
import RemotepayManager from "@/components/users/RemotepayManager.vue";
import StoreManager from "@/components/users/StoreManager.vue";
import ITPersonnel from "@/components/users/ITPersonnel.vue";
import Employee from "@/components/users/Employee.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import MerchantBankDetails from "@/components/MerchantBankDetails.vue";
import Transactions from "@/components/Transactions/POS.vue";
import EcommerceTransactionsHistory from "@/components/Transactions/EcommerceTransactionsHistory.vue";
import VoidTransactions from "@/components/VoidTransactions.vue";
import MerchantLocationTransactions from '@/components/Reports/MerchantLocationTransactions.vue';
import V1SettlementReport from '@/components/Reports/V1SettlementReport.vue';
import HistoricalSettlementReport from '@/components/Reports/HistoricalSettlementReport.vue';
import LongTermSettlementReport from '@/components/Reports/LongTermSettlementReport.vue';
import TipReportByShiftV1 from '@/components/Reports/TipReportByShiftV1.vue';
import TipReportByEmployeeV2 from '@/components/Reports/TipReportByEmployeeV2.vue';
import Accountant from "@/components/users/Accountant.vue";
import EmailaddressDailyTransaction from '@/components/EmailaddressDailyTransaction.vue';
import Stores from '@/components/Stores.vue';
import StoreLevelActivity from '@/components/StoreLevelActivity.vue';
import Releases from '@/components/Releases.vue';
import StoreTimingHoliday from '@/components/HolidayListAndTiming/StoreTimingHoliday.vue';
import HolidayTemplateMaster from '@/components/HolidayListAndTiming/TemplateMaster.vue';
import HolidayMaster from '@/components/HolidayListAndTiming/HolidayMaster.vue';
import CashbackMaster from '@/components/Cashback/CashbackMaster.vue';
import CashbackProgramReport from '@/components/Reports/CashbackProgramReport.vue';
import DiscountedFeeReport from "@/components/Reports/DiscountedFeeReport.vue"

import StoreApiKeys from '@/components/StoreApiKeys.vue';
Vue.use(VueRouter)

let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [{
        path: "/login",
        name: "Login",
        component: Login
    }, {
        path: "/login/:id",
        name: "Login",
        component: Login
    }]
};

const routes = [{
        path: "/",
        redirect: "/login",
        name: "Login"
    },
    {
        path: "/",
        name: "Login",
        get component() {
            if (localStorage.getItem("token") == null) {
                return Login;
            }
        }
    },
    authPages,
    {
        path: "/",
        component: DashboardLayout,
        children: [{
                path: "/dashboard",
                name: "Dashboard",
                components: { default: Dashboard }
            },
            {
                path: "/sponsordashboard",
                name: "SponsorDashboard",
                components: { default: SponsorDashboard }
            },
            {
                path: "/ecommercedashboard",
                name: "EcommerceDashboard",
                components: { default: EcommerceDashboard }
            },
            {
                path: "/ecommercedashboardtemplate",
                name: "EcommerceDashboardTemplate",
                components: { default: EcommerceDashboardTemplate }
            },
            {
                path: "userProfile",
                name: "userProfile",
                components: { default: UserProfile },

            },
            {
                path: "regionalManagers",
                name: "regionalManagers",
                components: { default: RegionalManager },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Regional Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "regionalManager",
                name: "regionalManager",
                components: { default: RegionalManagers },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "remotepayManager",
                name: "remotepayManager",
                components: { default: RemotepayManager },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "storeManagers",
                name: "storeManagers",
                components: { default: StoreManager },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "accountant",
                name: "accountant",
                components: { default: Accountant },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "deviceManagers",
                name: "deviceManagers",
                components: { default: ITPersonnel },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "employees",
                name: "employees",
                components: { default: Employee },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "changePassword",
                name: "changePassword",
                components: { default: ChangePassword }
            },
            {
                path: "bankDetails",
                name: "bankDetails",
                components: { default: MerchantBankDetails },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "transactions",
                name: "transactions",
                components: { default: Transactions },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "remotepaytransactionshistory",
                name: "remotepaytransactionshistory",
                components: { default: EcommerceTransactionsHistory },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant' || JSON.parse(localStorage.getItem("store_user")).role_name === 'Store Manager') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "voidtransactions",
                name: "voidtransactions",
                components: { default: VoidTransactions },
                beforeEnter(to, from, next) {
                    if (JSON.parse(localStorage.getItem("store_user")).role_name === 'Accountant') {
                        return next({ path: "/dashboard" });
                    } else {
                        return next();
                    }
                }
            },
            {
                path: "/merchantLocationTransactions",
                name: "merchantLocationTransactions",
                components: { default: MerchantLocationTransactions }
            },
            {
                path: "/settlementReport",
                name: "V1SettlementReport",
                components: { default: V1SettlementReport }
            },
            {
                path: "/historicalSettlementReport",
                name: "HistoricalSettlementReport",
                components: { default: HistoricalSettlementReport }
            },
            {
                path: "/settlementReportLongTerm",
                name: "LongTermSettlementReport",
                components: { default: LongTermSettlementReport }
            },
            {
                path: "/tipReportv1",
                name: "TipReportByShiftV1",
                components: { default: TipReportByShiftV1 }
            },
            {
                path: "/tipReportv2",
                name: "TipReportByEmployeeV2",
                components: { default: TipReportByEmployeeV2 }
            },
            {
                path: "/emailaddressDailyTransaction",
                name: "EmailaddressDailyTransaction",
                components: { default: EmailaddressDailyTransaction }
            },
            {
                path: "/stores",
                name: "Stores",
                components: { default: Stores }
            },
            {
                path: "/storeapikeys",
                name: "StoreApiKeys",
                components: { default: StoreApiKeys }
            },
            {
                path: "/storelevelactivity",
                name: "StoreLevelActivity",
                components: { default: StoreLevelActivity }
            },
            {
                path: "/releases",
                name: "Releases",
                components: { default: Releases }
            },
            {
                path: "/storeTimingHoliday",
                name: "StoreTimingHoliday",
                components: { default: StoreTimingHoliday }
            },
            {
                path: "/holidayTemplateMaster",
                name: "HolidayTemplateMaster",
                components: { default: HolidayTemplateMaster }
            },
            {
                path: "/holidayMaster",
                name: "HolidayMaster",
                components: { default: HolidayMaster }
            },
            {
                path: "/pointsbackmaster",
                name: "PointsbackMsaster",
                components: { default: CashbackMaster }
            },
            {
                path: "/pointsbackprogramreport",
                name: "Pointsbackprogramreport",
                components: { default: CashbackProgramReport }
            },
            {
                path: "/discountedfeereport",
                name: "discountedfeereport",
                components: { default: DiscountedFeeReport }
            }
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (localStorage.getItem("token") == null && to.fullPath !== '/login' && !to.fullPath.match(/^\/login\/((?:[^\/]+?))(?:\/(?=$))?$/i)) {
        return next({ path: "/login" });
    } else {
        return next();
    }
})


export default router;