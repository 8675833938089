<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        Logged in as
        <b>{{full_name}}</b>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>
<script>
import Constants from "@/common/constant.js";
import { db } from "../../firebaseConfig.js";
export default {
  name: "TopNavbar",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("store_user")),
      full_name: ''
    };
  },
  // Initializing the firestore collection
  firestore() {
    return {
      users: db.collection("merchant-admin-users")
    };
  },
  created() {
    var self = this;
    self.getdata();
  },
  methods: {
    // To fetch the document of Firestore
    getdata() {
      console.log(11);
      let ref = db
        .collection("merchant-admin-users")
        .doc(String(String(this.user.user_id)));
        ref.get().then(snapshot => { // Fetching the collection with the document id
        if (snapshot.exists) { // if the document exists then proceed
          this.users = snapshot.data();
          const containsKey = (obj, key) => Object.keys(obj).includes(key);
          const hasName = containsKey(this.users, "role_changed"); // Check wheather the key exists or not
          console.log(hasName);
          if (hasName == true) { // If the key exists then procedd
            if (this.users.role_changed == true) { // If the key value is true then procedd
              console.log('Call set Data');
              this.setdata(); // Needs to update the document
            }
          }
        } else {
          console.log("No such user!");
        }

        // Observer initialization of any changes in that document
        ref.onSnapshot(convo => {
          let source = convo.metadata.hasPendingWrites ? "Local" : "Server";
          let ref = db
            .collection("merchant-admin-users")
            .doc(String( this.user.user_id
        ? this.user.user_id
        : null));
            ref.get().then(snapshot => { // Fetching the collection with the document id
            if (snapshot.exists) { // if the document exists then proceed
              this.users = snapshot.data();
              const containsKey = (obj, key) => Object.keys(obj).includes(key);
              const hasName = containsKey(this.users, "role_changed"); // Check wheather the key exists or not
              if (hasName == true) { // If the key exists then procedd
                if (this.users.role_changed == true) { // If the key value is true then procedd
                  console.log('Call set Data 1211111');
                  this.setdata(); // Needs to update the document
                }
              }
            } else {
              console.log("No such User!");
            }
          });
        });
      });
    },
    // To update the document of Firestore
    setdata() {
      var ref = db
        .collection("merchant-admin-users")
        .doc(String(this.user.user_id)); // Fetching the collection with the document id
      console.log("check user Details...");
      return ref
        .update({
          role_changed: false // Updating the Document value with key
        })
        .then(function() {
            // Do the necessary steps after updation
            alert("User Role updated. Please log back in to access the new features.");
            console.log("Log Out User!");
            localStorage.clear();
            setTimeout(function() {
                window.location.reload();
            }, 2000);
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  },
  mounted() {
    var self = this;
    if(self.user.role_name == Constants.role_corporate_parent){
      self.full_name = self.user.contact_person_first_name + " " + self.user.contact_person_last_name;
    }else{
      if (self.user.middle_name) {
        self.full_name =
        self.user.first_name +
        " " +
        self.user.middle_name +
        " " +
        self.user.last_name;
      } else {
        self.full_name = self.user.first_name + " " + self.user.last_name;
      }
    }
  },
};
</script>
