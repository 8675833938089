<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Historical Settlement/Fees Report</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storeList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="generateReport(false)"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="generateReport(true)"
                  class="btn btn-danger ml-10"
                >
                  Generate & Export<i
                    class="fa fa-download ml-10"
                    aria-hidden="true"
                  ></i>
                </button>
                <button
                  type="button"
                  @click="exportReportAllStores()"
                  class="btn btn-danger ml-10"
                >
                  Export All Stores<i
                    class="fa fa-download ml-10"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <b-table-simple
                      responsive
                      show-empty
                      bordered
                      sticky-header="800px"
                    >
                      <b-thead head-variant="light" style="position: sticky; top:0;">
                        <b-tr>
                          <b-th colspan="7" class="text-center th-white">
                            <span v-if="selectedStore != []"
                              >Store Name:
                              {{ selectedStore.retailer }}</span
                            >
                          </b-th>
                          <b-th
                            colspan="7"
                            class="text-center b-left b-top b-right"
                            >Old CanPay Sales Activity</b-th
                          >
                          <b-th colspan="7" class="text-center b-top b-right"
                            >New CanPay Sales Activity</b-th
                          >
                        </b-tr>
                        <b-tr>
                          <b-th class="text-center">Sales Date</b-th>
                          <b-th class="text-center b-left b-top"
                            >Total Volume</b-th
                          >
                          <b-th
                            class="text-center report-table-border b-top b-right"
                            >Total Fees</b-th
                          >
                          <b-th class="text-center">Old CanPay Deposit</b-th>
                          <b-th class="text-center">Fees 1</b-th>
                          <b-th class="text-center">New CanPay Deposit</b-th>
                          <b-th class="text-center">Fees 2</b-th>
                          <b-th class="text-center">Volume</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Vol Fee</b-th>
                          <b-th class="text-center"># of Trans</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Trans Fees</b-th>
                          <b-th class="text-center">Sub Total Fees</b-th>
                          <b-th class="text-center">Volume</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Vol Fee</b-th>
                          <b-th class="text-center"># of Trans</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Trans Fees</b-th>
                          <b-th class="text-center">Sub Total Fees</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody v-for="(row, index) in report" :key="index">
                        <b-tr v-if="row.sales_date === 'Totals'" class="report-total-cls">
                          <b-td class="text-left"
                            ><b>{{ row.sales_date }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.total_volume }}</b></b-td
                          >
                          <b-td class="text-right report-table-border"
                            ><b>{{ row.total_fees }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_deposit }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_fees }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_deposit }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_fees }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_volume }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_vol_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_vol_fee }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v1_no_of_trans }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_fee_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_trans_fee }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v1_sub_total_fees }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_volume }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_vol_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_vol_fee }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v2_retail_no_of_trans }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_fee_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_trans_fee }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_sub_total_fees }}</b></b-td
                          >
                        </b-tr>
                        <b-tr v-else>
                          <b-td class="text-left">{{ row.sales_date }}</b-td>
                          <b-td class="text-right"><b>{{ row.total_volume }}</b></b-td>
                          <b-td class="text-right report-table-border"><b>{{
                            row.total_fees
                          }}</b></b-td>
                          <b-td class="text-right">{{ row.v1_deposit }}</b-td>
                          <b-td class="text-right">{{ row.v1_fees }}</b-td>
                          <b-td class="text-right">{{ row.v2_deposit }}</b-td>
                          <b-td class="text-right">{{ row.v2_fees }}</b-td>

                          <b-td class="text-right old-sales-act-cls"><b>{{ row.v1_volume }}</b></b-td>
                          <b-td class="text-right old-sales-act-cls">{{ row.v1_vol_rate }}</b-td>
                          <b-td class="text-right old-sales-act-cls">{{ row.v1_vol_fee }}</b-td>
                          <b-td class="text-center old-sales-act-cls">{{
                            row.v1_no_of_trans
                          }}</b-td>
                          <b-td class="text-right old-sales-act-cls">{{ row.v1_fee_rate }}</b-td>
                          <b-td class="text-right old-sales-act-cls">{{ row.v1_trans_fee }}</b-td>
                          <b-td class="text-right old-sales-act-cls"><b>{{
                            row.v1_sub_total_fees
                          }}</b></b-td>
                          <b-td class="text-right new-sales-act-cls"><b>{{
                            row.v2_retail_volume
                          }}</b></b-td>
                          <b-td class="text-right new-sales-act-cls">{{
                            row.v2_retail_vol_rate
                          }}</b-td>
                          <b-td class="text-right new-sales-act-cls">{{
                            row.v2_retail_vol_fee
                          }}</b-td>
                          <b-td class="text-center new-sales-act-cls">{{
                            row.v2_retail_no_of_trans
                          }}</b-td>
                          <b-td class="text-right new-sales-act-cls">{{
                            row.v2_retail_fee_rate
                          }}</b-td>
                          <b-td class="text-right new-sales-act-cls">{{
                            row.v2_retail_trans_fee
                          }}</b-td>
                          <b-td class="text-right new-sales-act-cls"><b>{{
                            row.v2_retail_sub_total_fees
                          }}</b></b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { saveAs } from "file-saver";
import { HourGlass } from "vue-loading-spinner";
export default {
  data() {
    return {
      report: [],
      fields: [],
      v1_label: "Hide",
      web_label: "Hide",
      retail_label: "Hide",
      all_label: "Hide",
      toggle_v1: 1,
      toggle_web: 1,
      toggle_retail: 1,
      toggle_all: 1,
      loading: false,
      storeList: [],
      selectedStore: "",
    };
  },
  components: {
    HourGlass,
  },
  watch: {
    toggle_v1: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.v1_label = "Show";
      } else {
        self.v1_label = "Hide";
      }
    },
    toggle_web: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.web_label = "Show";
      } else {
        self.web_label = "Hide";
      }
    },
    toggle_retail: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.retail_label = "Show";
      } else {
        self.retail_label = "Hide";
      }
    },
    toggle_all: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.all_label = "Show";
      } else {
        self.all_label = "Hide";
      }
    },
  },
  mounted() {
    this.getAuthorizedStores();
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-01-01'),
      endDate: new Date('2021-06-30')
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-01-01'),
      endDate: new Date('2021-06-30')
    });
    $("#start-date , #end-date").datepicker("setDate", "-1d");

    $("#checkall").change(function () {
      $(".cb-element").prop("checked", this.checked);
    });

    $(".cb-element").change(function () {
      if ($(".cb-element:checked").length == $(".cb-element").length) {
        $("#checkall").prop("checked", true);
      } else {
        $("#checkall").prop("checked", false);
      }
    });
  },
  methods: {
    //get the autorized merchant list
    getAuthorizedStores() {
      var self = this;
      apiuser
        .getStores()
        .then(function (response) {
          if (response.code == 200) {
            self.storeList = response.data;
            } else {
            error(response.message);
            }
        })
        .catch(function (error) {
          error(error);
           });
    },
    // API call to generate the merchant location transaction report
    generateReport(reportExport) {
      var self = this;
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      self.report = [];
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        store_id: self.selectedStore.id,
      };
      if(request.from_date > request.to_date){
        error("To Date cannot be greater than From date");
        return false;
      }
      self.loading = true;
      api
        .generateHistoricalSettlementReport(request)
        .then(function (response) {
          if (response.code == 200) {
            self.report = response.data;
            if (reportExport) {
              self.exportReport();
            } else {
              self.loading = false;
            }
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },

    // exports the report
    exportReport() {
      var self = this;
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        report: self.report,
        store_name: self.selectedStore.retailer,
      };
      api
        .exportHistoricalSettlementReport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_CanPay_Historical_Settlement_Fees_Report.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    //Export Report for all the Stores
    exportReportAllStores(){
      var self = this;
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
      };
      api
        .exportHistoricalSettlementReportAllStores(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_CanPay_Historical_Settlement_Fees_Report_All_Stores.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    }
  },
};
</script>