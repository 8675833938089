const updateProfile = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/updateuserprofile', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const changeManagerStatus = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/statuschange', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getStores = (request) => {
    return new Promise((res, rej) => {
        axios.get('/merchant/getauthorizedstores', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateUser = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/edituser', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const addUser = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchant/adduser', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const generatePIN = () => {
    return new Promise((res, rej) => {
        axios.get('/merchant/getterminaluserpin')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const changePassword = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/changePassword', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const saveBankDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/saveBankDetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getBankDetails = (request) => {
    return new Promise((res, rej) => {
        axios.get('/merchantadmin/getMerchantBankDetails')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getUserStatus = () => {
    return new Promise((res, rej) => {
        axios.get('/merchantadmin/getAllUserStatus')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const addEmailAddress = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/addemailaddress', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateEmailAddress = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/updateemailaddress', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const deleteEmailAddress = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/deleteemailaddress', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const resetPassword = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/resetpassword', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getModificationReason = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchant/getmodificationreason', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const fetchRoleList = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/userroles', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateUserRole = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/updateuserrole', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const lastPinResetData = () => {
    return new Promise((res, rej) => {
        axios.get('/merchantadmin/lastpinresetdata')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const resetLoginPin = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/resetloginpin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    updateProfile,
    changeManagerStatus,
    getStores,
    updateUser,
    addUser,
    generatePIN,
    changePassword,
    saveBankDetails,
    getBankDetails,
    getUserStatus,
    addEmailAddress,
    updateEmailAddress,
    deleteEmailAddress,
    resetPassword,
    fetchRoleList,
    updateUserRole,
    getModificationReason,
    lastPinResetData,
    resetLoginPin
};