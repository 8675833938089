<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Settlement Report Long Term</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedMerchant"
                        placeholder="Select Merchant"
                        label="merchant_name"
                        track-by="merchant_id"
                        :options="merchantList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        v-model="toggle_v1"
                        class="cb-element"
                      />
                      {{ v1_label }} V1
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        v-model="toggle_web"
                        class="cb-element"
                      />
                      {{ web_label }} V2 Web
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        v-model="toggle_retail"
                        class="cb-element"
                      />
                      {{ retail_label }} V2 Retail
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        v-model="toggle_all"
                        name="all"
                        id="checkall"
                      />
                      {{ all_label }} All
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="generateReport(false)"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="generateReport(true)"
                  class="btn btn-danger ml-10"
                >
                  Generate & Export<i
                    class="fa fa-download ml-10"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <b-table-simple
                      responsive
                      show-empty
                      bordered
                      sticky-header="800px"
                    >
                      <b-thead head-variant="light" style="position: sticky; top:0;">
                        <b-tr>
                          <b-th colspan="3" class="th-white text-center">
                          </b-th>
                          <b-th colspan="7" class="text-center b-top b-right"
                            >Retail</b-th
                          >
                          <b-th colspan="7" class="text-center b-top b-right"
                            >Web</b-th
                          >
                        </b-tr>
                        <b-tr>
                          <b-th class="text-center">Sales Date</b-th>
                          <b-th class="text-center b-left b-top"
                            >Total Volume</b-th
                          >
                          <b-th
                            class="text-center report-table-border b-top b-right"
                            >Total Fees</b-th
                          >
                          <b-th class="text-center">Volume</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Vol Fee</b-th>
                          <b-th class="text-center"># of Trans</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Trans Fees</b-th>
                          <b-th class="text-center">Sub Total Fees</b-th>
                          <b-th class="text-center">Volume</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Vol Fee</b-th>
                          <b-th class="text-center"># of Trans</b-th>
                          <b-th class="text-center">Rate</b-th>
                          <b-th class="text-center">Trans Fees</b-th>
                          <b-th class="text-center">Sub Total Fees</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody v-for="(row, index) in report" :key="index">
                        <b-tr v-if="row.sales_date === 'Totals'">
                          <b-td class="text-left"
                            ><b>{{ row.sales_date }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.total_volume }}</b></b-td
                          >
                          <b-td class="text-right report-table-border"
                            ><b>{{ row.total_fees }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_volume }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_vol_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_vol_fee }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v2_retail_no_of_trans }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_fee_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_trans_fee }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_retail_sub_total_fees }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_web_volume }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_web_vol_rate }}</b></b-td
                          >
                          <b-td class="text-right"
                            ><b>{{ row.v2_web_vol_fee }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v2_web_no_of_trans }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v2_web_fee_rate }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v2_web_trans_fee }}</b></b-td
                          >
                          <b-td class="text-center"
                            ><b>{{ row.v2_web_sub_total_fees }}</b></b-td
                          >
                        </b-tr>
                        <b-tr v-else>
                          <b-td class="text-left">{{ row.sales_date }}</b-td>
                          <b-td class="text-right">{{ row.total_volume }}</b-td>
                          <b-td class="text-right report-table-border">{{
                            row.total_fees
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_retail_volume
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_retail_vol_rate
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_retail_vol_fee
                          }}</b-td>
                          <b-td class="text-center">{{
                            row.v2_retail_no_of_trans
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_retail_fee_rate
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_retail_trans_fee
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_retail_sub_total_fees
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_web_volume
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_web_vol_rate
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_web_vol_fee
                          }}</b-td>
                          <b-td class="text-center">{{
                            row.v2_web_no_of_trans
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_web_fee_rate
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_web_trans_fee
                          }}</b-td>
                          <b-td class="text-right">{{
                            row.v2_web_sub_total_fees
                          }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { saveAs } from "file-saver";
import { HourGlass } from "vue-loading-spinner";
export default {
  data() {
    return {
      report: [],
      fields: [],
      web_label: "Hide",
      retail_label: "Hide",
      all_label: "Hide",
      toggle_web: 1,
      toggle_retail: 1,
      toggle_all: 1,
      loading: false,
      merchantList: [],
      selectedMerchant: "",
    };
  },
  components: {
    HourGlass,
  },
  watch: {
    toggle_web: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.web_label = "Show";
      } else {
        self.web_label = "Hide";
      }
    },
    toggle_retail: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.retail_label = "Show";
      } else {
        self.retail_label = "Hide";
      }
    },
    toggle_all: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.all_label = "Show";
      } else {
        self.all_label = "Hide";
      }
    },
  },
  mounted() {
    this.getAuthorizedMerchants();
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date , #end-date").datepicker("setDate", new Date());

    $("#checkall").change(function () {
      $(".cb-element").prop("checked", this.checked);
    });

    $(".cb-element").change(function () {
      if ($(".cb-element:checked").length == $(".cb-element").length) {
        $("#checkall").prop("checked", true);
      } else {
        $("#checkall").prop("checked", false);
      }
    });
  },
  methods: {
    //get the autorized merchant list
    getAuthorizedMerchants() {
      var self = this;
      var request = {
        user_id: JSON.parse(localStorage.getItem("store_user")).user_id,
      };
      api
        .getAuthorizedMerchants(request)
        .then(function (response) {
          if (response.code == 200) {
            self.merchantList = response.data;
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
        });
    },
    // API call to generate the merchant location transaction report
    generateReport(reportExport) {
      var self = this;
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment($().val()).format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment($().val()).format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      self.report = [];
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        // show_v1: self.toggle_v1,
        // show_web: self.toggle_web,
        // show_retail: self.toggle_retail,
        merchant_id: self.selectedMerchant.merchant_id,
      };
      if(request.from_date > request.to_date){
        error("To Date cannot be greater than From date");
        return false;
      }
      self.loading = true;
      api
        .generateLongTermSettlementReport(request)
        .then(function (response) {
          if (response.code == 200) {
            self.report = response.data;
            if (reportExport) {
              self.exportReport();
            } else {
              self.loading = false;
            }
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },

    // exports the report
    exportReport() {
      var self = this;
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        report: self.report,
        merchant_name: self.selectedMerchant.merchant_name,
      };
      api
        .exportLongTermSettlementReport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_settlement_report_long_term.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
  },
};
</script>