const cancelTransaction = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchant/cancelTransaction', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const merchantTransactionUpdate = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchant/merchanttransactionupdate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getConsumers = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchantadmin/getAllConsumers', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const generateConsumerTransactionReport = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/getConsumerTransactionReport', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const exportgenerateConsumerTransactionReport = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/reports/getconsumertransactionexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const modifiedTransactionHistory = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchant/modifiedtransactionhistory', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const postToBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchant/posttobank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const reSendModification = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchant/resendtransactionupdate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getTransactionExport = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('merchant/reports/gettransactionsexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    cancelTransaction,
    merchantTransactionUpdate,
    getConsumers,
    generateConsumerTransactionReport,
    exportgenerateConsumerTransactionReport,
    modifiedTransactionHistory,
    postToBank,
    reSendModification,
    getTransactionExport
};