<template>
  <footer class="main-footer">
    <strong>
      Copyright &copy; {{ new Date().getFullYear() }}
      <a href="javascript:void(0)">CanPay</a>.
    </strong>
    All rights reserved.
  </footer>
</template>
<script>
export default {
  name: "ContentFooter"
};
</script>
