<template>
    <div>
        <button
        v-if="!submitFlag"
        @click="clickEvent"
        type="button"
        class="btn btn-success btn-block"
        >
        {{label}}
        </button>

        <button
        disabled
        v-if="submitFlag"
        type="button"
        class="btn btn-success btn-block"
        >
        {{counterLabel}} {{timer}}s
        </button>
    </div>
</template>

<script>
import api from "@/api/auth.js";
import moment from "moment";

export default {
    props:{
        value: String,
        onClick: {
            type: Function,
            required: true
        },
        label: {
            type: String,
            default: "Send"
        },
        counterLabel: {
            type: String,
            default: "Send After"
        },
    },
    data(){
        return{
            submitFlag: false,
            timer: null,
        }
    },
    methods:{
        clickEvent(){
            var self = this;
            self.onClick();
        }
    },
    mounted(){
        this.timer = this.value
    },
    watch:{
        value: {
            handler(val) {
                this.timer = val
            },
            immediate: false, // This ensures the watcher is triggered upon creation
        },
        timer: {
            handler(val) {
                if (val > 0) {
                    this.submitFlag = true
                    setTimeout(() => {
                        this.timer--;
                    }, 1000);
                }else{
                    this.submitFlag = false
                    this.$emit("input", null)
                }
            },
            immediate: false, // This ensures the watcher is triggered upon creation
        },
    }
}
</script>