const getStoreBySearch = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/searchstores', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const saveStoreTiming = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/savestoretiming', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateStoreSyncing = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/updatestoresyncing', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAllStores = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/getstores', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getStoresForTemplates = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/getstoresfortemplate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAllTemplates = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/getalltemplates', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getHolidays = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/getholidaylist', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const assignHolidayToStore = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/assignholidaytostore', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const removeStoreFromHoliday = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/removestorefromholiday', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const assignStoreToMulitpleHoliday = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/assignmultipleholidaystostore', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const assignTemplateToStore = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/assigntemplatetostores', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const saveHoliday = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/saveholiday', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getTemplateDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/gettemplatedetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const saveTemplate = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/savetemplate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const deleteTemplate = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/deletetemplate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    getStoreBySearch,
    saveStoreTiming,
    updateStoreSyncing,
    getAllStores,
    getStoresForTemplates,
    getAllTemplates,
    getHolidays,
    assignHolidayToStore,
    removeStoreFromHoliday,
    assignStoreToMulitpleHoliday,
    assignTemplateToStore,
    saveHoliday,
    getTemplateDetails,
    saveTemplate,
    deleteTemplate,
};