<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1 class="m-0">Sponsor Management</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="loading" v-if="loading">
      <hour-glass></hour-glass>
    </div>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header border-transparent">
                <h3 class="card-title">Linked Consumer Details</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <b-table
                    show-empty
                    responsive
                    head-variant="light"
                    sticky-header="600px"
                    id="merchantLinkedUsersTable"
                    :items="linkedUserList"
                    :fields="fields"
                  >
                  TODO:
                  <!-- <template #cell(action)="row">
                      <a id="view-btn" class="custom-edit-btn " align-self="center"  title="View Consumer Details" variant="outline-success" style="border:none"><i class="nav-icon fas fa-eye"></i></a>
                  </template> -->
                  </b-table>
                  <div class="float-right">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        @change="onPageChanged"
                        prev-text="Prev"
                        next-text="Next"
                        :ellipsis="true"
                        :limit="5"
                        align="right"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { HourGlass } from "vue-loading-spinner";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      fields: [
        { key: "consumer_name", label: "Consumer Name", class: "text-center"},
        { key: "phone", label: "Phone No", class: "text-center"},
        { key: "account_no", label: "Account ID", class: "text-center"},
        //TODO: { key: "action", label: "Action", class: "text-center"},
      ],
      linkedUserList:[],
      releaseNotes:{},
      loading: false,
      user: JSON.parse(localStorage.getItem("store_user")),
      perPage: 2,
      currentPage: 1,
      rows: 0,
    };
  },
  components: {
    HourGlass,
  },
  created() {
    //Fetch the Transaction details
    this.checkNewReleases();
    this.getlinkedUserList();
  },
  mounted() {
    var self = this;
  },
  methods: {
    onPageChanged(page) {
      // console.log(page);
      var self = this;
      self.currentPage = page;
      self.getlinkedUserList();
    },
    dateFormatChange(date) {
      return moment
        .utc(date)
        .local()
        .format(" DD-M-YYYY");
    },
    checkNewReleases(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .checkNewReleases(request)
        .then(function (response) {
          if (response.code == 200) {
            if(response.data.length > 0){
              self.releaseNotes = response.data;
              var releaseTxt = '';
              self.releaseNotes.forEach(function(releaseNote,index) {
                if((index > 0 && releaseNote.tag!=self.releaseNotes[index - 1].tag) || index == 0){
                  releaseTxt+= '<h3 class="releasetagcls"># Release '+releaseNote.tag+' on '+self.dateFormatChange(releaseNote.created_at)+'</h3>';
                }
                releaseTxt+= '<ul class="todo-list ui-sortable" data-widget="todo-list">';
                releaseTxt+= '<li><p class="direct-chat-name">'+releaseNote.release_note+'</p><p class="release-note-description">'+releaseNote.release_note_description+'</p></li>';
                releaseTxt+= '</ul>';
              });
              self.$swal({
                html: releaseTxt,
                showConfirmButton: false,
              }).then(function () {
                self.readReleaseNotes();
              });
            }
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    readReleaseNotes(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .readReleaseNotes(request)
        .then(function (response) {
          if (response.code!= 200) {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    getlinkedUserList(){
      var self = this;
      var request = {
        current_page: self.currentPage,
        per_page: self.perPage
      };
      api
        .getlinkedUserList(request)
        .then(function (response) {
          if (response.code == 200) {
            self.linkedUserList = response.data.users;
            self.rows = response.data.total;
            self.loading = false;
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
  },
};
</script>
<style>
.page-link {
  font-size: 14.88px;
  font-family: -apple-system,BlinkMacSystemFont;
}
</style>