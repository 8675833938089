<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Bank Details</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label> Account Number: </label
                      ><span> {{ account_number }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label> Routing Number: </label
                      ><span> {{ routing_number }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>
                        Routing Number
                        <span class="red">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="routing_number"
                        placeholder="Routing Number"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label> Fees Account Number </label>
                      <input
                        type="text"
                        placeholder="Fees Account Number"
                        v-model="fees_account_number"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label> Financial Institution Name </label>
                      <input
                        type="text"
                        placeholder="Financial Institution Name"
                        v-model="bank_name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label> Financial Institution Contact Person </label>
                      <input
                        type="text"
                        v-model="banker"
                        placeholder="Financial Institution Contact Person"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="card-footer">
                <!-- <button
                  type="button"
                  @click="saveBankDetails"
                  class="btn btn-success"
                >
                  Save
                </button> -->
                <span
                  >Contact CanPay Support at
                  <a href="support@canpaydebit.com">support@canpaydebit.com</a>
                  if you like to change your bank details.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/user.js";
export default {
  data() {
    return {
      routing_number: "",
      account_number: "",
      banker: "",
      bank_name: "",
      fees_account_number: "",
    };
  },
  mounted: function () {
    this.getBankDetails();
  },
  methods: {
    checkValidation() {
      let self = this;
      if (self.routing_number == "" || self.account_number == "") {
        error("Please fill in the required fields.");
        return false;
      }
      return true;
    },
    getBankDetails() {
      let self = this;
      //save bank details
      api
        .getBankDetails()
        .then((response) => {
          if (response.code == 200) {
            self.routing_number = response.data.routing_no;
            self.account_number = response.data.account_no;
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
    },
    saveBankDetails() {
      let self = this;
      //save bank details
      let request = {
        routing_number: self.routing_number,
        account_number: self.account_number,
        banker: self.banker,
        bank_name: self.bank_name,
        fees_account_number: self.fees_account_number,
      };
      if (self.checkValidation()) {
        api
          .saveBankDetails(request)
          .then((response) => {
            if (response.code == 200) {
              success(response.message);
              self.getBankDetails();
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            error(err);
          });
      }
    },
  },
};
</script>
