<template>
        <b-tr>
            <b-td class="text-left text-gray">
                {{row.day}}
            </b-td>
            <b-td class="text-left text-gray">
                <CPTimePicker 
                v-validate="'required'" 
                v-model="row.ecommerce_close_time"
                />
                <input
                type="hidden"
                v-model="row.ecommerce_close_time"
                name="ecommerce_close_time"
                v-validate="'required'"
                />
                <span v-show="errors.has('ecommerce_close_time')" class="text-danger">{{
                errors.first("ecommerce_close_time")
                }}</span>
            </b-td>
        </b-tr>
</template>

<script>
import CPTimePicker from './CPTimePicker.vue';

export default {
    props:{
        row: {
            default: {},
            type: Object
        }
    },
    data() {
        return{
            
        }
    },
    components: {
        CPTimePicker
    },
    methods:{
    },
}
</script>