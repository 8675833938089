<template>
  <div class="wrapper">
    <top-navbar></top-navbar>
    <sidebar></sidebar>
    <div class="content-wrapper" style="min-height: 36px;">
      <router-view></router-view>
    </div>
    <content-footer></content-footer>
  </div>
</template>
<script>
import TopNavbar from "./Header.vue";
import ContentFooter from "./Footer.vue";
import Sidebar from "./Sidebar.vue";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    Sidebar
  }
};
</script>
