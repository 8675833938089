import { loadProgressBar } from 'axios-progress-bar'
import { reward_wheel_axios } from '../main'

const checkMerchantCashbackAcceptance = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/checkandupdatemerchantcashbackacceptance', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getMerchantStates = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/getmerchantstates', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getStoresForCashbackProgram = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/getstoresforcashbackprogram', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const addeditCashbackProgram = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/addeditcashbackprogram', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const saveCashbackFeedback = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/savecashbackfeedback', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getStatus = () => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.get('/api/merchantadmin/getstatus')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getCashbackProgramData = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/getcashbackprogramdata', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const statusChangeCashBackProgram = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/statuschangecashbackprogram', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAllCashbackPrograms = () => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.get('/api/getallcashbackprograms')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getCashbackProgramReportExport = (request) => {
    var header = {
        responseType: 'blob',
    };
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/export/getcashbackprogramreportexport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const editDefaultCashbackProgram = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/editdefaultcashbackprogram', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const editAddedDefaultCashbackProgram = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/editaddeddefaultcashbackprogram', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    checkMerchantCashbackAcceptance,
    getMerchantStates,
    getStoresForCashbackProgram,
    addeditCashbackProgram,
    saveCashbackFeedback,
    getStatus,
    getCashbackProgramData,
    statusChangeCashBackProgram,
    getAllCashbackPrograms,
    getCashbackProgramReportExport,
    editDefaultCashbackProgram,
    editAddedDefaultCashbackProgram
};