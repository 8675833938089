<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Merchant Points Program Report</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="card" style="box-shadow: none;">
                  <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                <label for="start-date">
                                    Start Date
                                    <span class="red">*</span>
                                </label>
                                <input
                                    autocomplete="off"
                                    class="start-date form-control"
                                    placeholder="Start Date"
                                    id="start-date"
                                    onkeydown="return false"
                                />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                <label for="end-date">
                                    End Date
                                    <span class="red">*</span>
                                </label>
                                <input
                                    autocomplete="off"
                                    class="end-date form-control"
                                    placeholder="End Date"
                                    id="end-date"
                                    onkeydown="return false"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" style="box-shadow: none;">
                  <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                <label for="merchant">
                                    Select Store
                                </label>
                                <multiselect
                                    id="store"
                                    v-model="selectedStore"
                                    placeholder="Select Store"
                                    label="retailer"
                                    :options="storelist"
                                    :loading="isLoading"
                                    :internal-search="false"
                                    v-validate="'required'"
                                    @search-change="getAllStores"
                                ></multiselect>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                <label for="merchant">
                                    Select Merchant Points Program
                                </label>
                                <multiselect
                                    id="cashback_program"
                                    v-model="selectedCashbackProgram"
                                    placeholder="Select Merchant Points Program"
                                    label="cashback_program_name"
                                    :options="cashbackProgramList"
                                    :internal-search="true"
                                    v-validate="'required'"
                                ></multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-md-9">
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="generateReport(false)"
                          id="generateBtn"
                        >
                          Generate</button
                        >
                        <button
                          type="button"
                          @click="exportReport()"
                          class="btn btn-danger ml-10"
                        >
                          Export
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </button>
                        <button
                          type="button"
                          @click="reset()"
                          class="btn btn-success ml-10"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                      </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <div class="row" v-if="allCashbackProgramReports.length > 0">
                      <div class="col-4">
                        <b-table-simple bordered>
                          <b-tbody>
                            <b-tr>
                              <b-td class="text-left">Total Merchant Points Program Point Earned</b-td>
                              <b-td class="text-right">{{total_cashback_points_earned}}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="text-left">Total Merchant Points Program Amount Earned ($)</b-td>
                              <b-td class="text-right">{{total_cashback_amount_earned}}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="text-left">Total Merchant Points Program Point Redeemed</b-td>
                              <b-td class="text-right">{{total_cashback_points_used}}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="text-left">Total Merchant Points Program Amount Redeemed ($)</b-td>
                              <b-td class="text-right">{{total_cashback_amount_used}}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <table
                          id="cashbackProgramReportTable"
                          class="table table-responsive"
                          style="width: 100%; white-space: normal"
                        >
                          <thead>
                            <tr>
                              <th width="10%">Store</th>
                              <th width="13%">Merchant Points Earning Program</th>
                              <th width="13%">Merchant Points Redemption Program</th>
                              <th width="12%">Transaction Number</th>
                              <th width="15%">Transaction Time</th>
                              <th width="10%">Transaction Amount ($)</th>
                              <th width="10%">Merchant Points Program Point Earned</th>
                              <th width="10%">Merchant Points Program Amount Earned ($)</th>
                              <th width="10%">Merchant Points Program Point Redeemed</th>
                              <th width="10%">Merchant Points Program Amount Redeemed ($)</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import cashback from "@/api/cashback.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { HourGlass } from "vue-loading-spinner";
import commonConstants from "@/common/constant.js";
export default {
  mixins: [validationMixin],
  data() {
    return {
      allCashbackProgramReports: {},
      currentUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      loading: false,
      report: [],
      generateExport: false,
      showReloadBtn:false,
      constants: commonConstants,

      selectedStore: null,
      selectedCashbackProgram: null,

      storelist: [],
      cashbackProgramList: [],

      isLoading: false,
      total_cashback_points_earned: 0,
      total_cashback_amount_earned: 0,
      total_cashback_points_used: 0,
      total_cashback_amount_used: 0,
    };
  },
  components: {
    HourGlass,
  },
  methods: {
    //API call to fetch All stores
    getAllStores(searchtxt) {
      var self = this;
      if(searchtxt.length >= 3){
        self.isLoading = true;
        var request = {
          searchtxt: searchtxt,
        };
      api
        .getStores(request)
        .then(function (response) {
          if (response.code == 200) {
            self.storelist = response.data;
            self.isLoading = false;
          }else {
            error(response.message);
            self.isLoading = false;
          }
        })
        .catch(function (error) {
        });
      }
    },
    getAllCashbackPrograms() {
      var self = this;
      cashback
        .getAllCashbackPrograms()
        .then(function (response) {
          if (response.code == 200) {
            self.cashbackProgramList = response.data;
          }else {
            error(response.message);
          }
        })
        .catch(function (error) {
        });
    },
    reloadDatatable(){
      var self = this;
      var from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      var to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      var store = "";
      var cashback_program = "";

      this.store = ""
      this.cashback_program = ""

      this.selectedStore = null,
      this.selectedCashbackProgram = null,

      self.report = [];
      var request = {
        from_date: from_date,
        to_date: to_date,
        store: store,
        cashback_program: cashback_program,
      };
      self.loading = true;
      self.loadDT(request);
    },
    // API call to generate the all the Transactions Report
    generateReport() {
      var self = this;
      if($("#end-date").val() == '' &&  $("#start-date").val() == ''){
          error("Please select Start Date & End Date then Generate.");
          return false;
      }
      if (
          moment($("#start-date").val()).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD") && $("#start-date").val()!= ''
      ) {
          error("Start date cannot be from future.");
          return false;
      }
      if (
          moment($("#end-date").val()).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD") && $("#end-date").val()!= ''
      ) {
          error("End date cannot be from future.");
          return false;
      }
      if($("#start-date").val()!=''){
          var from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      }else{
          var from_date = '';
      }
      if($("#end-date").val()!=''){
          var to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      }else{
          var to_date = '';
      }

      if(self.selectedStore === null){
        var store = '';
      }else{
        var store = self.selectedStore.id;
      }

      if(self.selectedCashbackProgram === null){
        var cashback_program = '';
      }else{
        var cashback_program = self.selectedCashbackProgram.id;
      }

      self.report = [];
      var request = {
        from_date: from_date,
        to_date: to_date,
        store: store,
        cashback_program: cashback_program,
      };
      if (self.generateExport == false) {
        self.loading = true;
      }
      self.loadDT(request);
    },
    loadDT: function (request) {
      var self = this;
      $("#cashbackProgramReportTable").DataTable({
          pagingType: "simple_numbers",
          processing: true,
          serverSide: true,
          destroy: true,
          searching: false,
          "lengthChange": false,
          "pageLength": 25,
        columnDefs: [
          { orderable: false, targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
          { className: "dt-left", targets: [0, 1, 2, 3, 5,7] },
          { className: "dt-right", targets: [4, 6, 8] },
        ],
        order: [[4,"desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only"></span> ',
          emptyTable: "No Data Available.",
          oPaginate: {
              sNext: '<i class="fas fa-angle-double-right"></i>',
              sPrevious: '<i class="fas fa-angle-double-left"></i>',
            },
            sLengthMenu:
              "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_REWARD_WHEEL_API_URL + "/api/getcashbackprogramreport",
          type: "POST",
          data: {
            _token: "{{csrf_token()}}",
            from_date: request.from_date,
            to_date: request.to_date,
            store: request.store,
            cashback_program: request.cashback_program,
          },
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allCashbackProgramReports = result.data;
            self.total_cashback_points_earned = result.total_cashback_points_earned;
            self.total_cashback_amount_earned = result.total_cashback_amount_earned;
            self.total_cashback_points_used = result.total_cashback_points_used;
            self.total_cashback_amount_used = result.total_cashback_amount_used;
            if (self.generateExport == false) {
              self.loading = false;
            }
            return self.allCashbackProgramReports;
          },
          error: function(data){
            error(commonConstants.datatable_error);
            $('#cashbackProgramReportTable_processing').hide();
            self.loading = false;
            self.showReloadBtn = true;
          }
        },
        columns: [
          { data: "retailer" },
          { data: "cashback_program_name" },
          { data: "used_cashback_program_name" },
          { data: "transaction_number" },
          { data: "transaction_time" },
          { data: "transaction_amount" },
          { data: "cashback_points_earned" },
          { data: "cashback_amount_earned" },
          { data: "cashback_point_used" },
          { data: "cashback_amount_used" }
        ],
      });
    },
    exportReport() {
      var self = this;
      self.generateExport = true;
      self.loading = true;
      if($("#end-date").val() == '' &&  $("#start-date").val() == ''){
          error("Please select Start Date & End Date then Generate.");
          return false;
      }
      if (
          moment($("#start-date").val()).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD") && $("#start-date").val()!= ''
      ) {
          error("Start date cannot be from future.");
          return false;
      }
      if (
          moment($("#end-date").val()).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD") && $("#end-date").val()!= ''
      ) {
          error("End date cannot be from future.");
          return false;
      }
      var from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      var to_date = moment($("#end-date").val()).format("YYYY-MM-DD");

      if(self.selectedStore === null){
        var store = '';
      }else{
        var store = self.selectedStore.id;
      }

      if(self.selectedCashbackProgram === null){
        var cashback_program = '';
      }else{
        var cashback_program = self.selectedCashbackProgram.id;
      }

      var request = {
        from_date: from_date,
        to_date: to_date,
        store: store,
        cashback_program: cashback_program,
        consumer: self.consumer,
        email: self.email,
        phone_no: self.phone_no,
      };

      cashback
        .getCashbackProgramReportExport(request)
        .then(function (response) {
          self.generateExport = false;
          self.loading = false;
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_points_back_program_report_export.xlsx"
          );
        })
        .catch(function (error) {
          // error(error);
          self.generateExport = false;
          self.loading = false;
        });
    },
    reset(){
      var self = this;
      self.selectedStore = null;
      self.selectedCashbackProgram = null;
    },
    //API call to fetch authorized stores
    getAllAuthorizedStores() {
      var self = this;
      apiuser
        .getStores()
        .then(function (response) {
          if (response.code == 200) {
            self.storelist = response.data;
            if (self.storelist.length == 1) {
              self.selectedStore = self.storelist[0];
            }
          } else if (response.code == 401) {
            self.getConfirmation();
            } else {
            error(response.message);
            }
        })
        .catch(function (error) {});
    },
  },
  mounted() {
    var self = this;
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    }).datepicker("setDate", new Date());
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      });
    $("#start-date , #end-date").datepicker("setDate", new Date());

    self.getAllCashbackPrograms();
    this.getAllAuthorizedStores();
  },
};
</script>
