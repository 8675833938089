<template>
  <div>
    <!-- SM Modal Start -->
    <b-modal
      id="role-permission-modal"
      ref="role-permission-modal"
      :title="modalTitle"
      :header-text-variant="headerTextVariant"
      hide-footer 
    >
    <div class="row">
      <div class="col-md-12">
        <b-table-simple
        class="cp-table"
        responsive
        show-empty
        bordered
        >
          <b-tbody v-for="(value, index) in constants.role_permission[role_name]" :key="index">
            <b-tr>
              <b-td width="20%" class="text-left text-gray">{{index + 1}}</b-td>
              <b-td width="80%" class="text-left text-gray">{{value}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>   
    </b-modal>
  </div>
</template>
<script>
import Constants from "@/common/constant.js";
export default {
  name: "RolePermissionModal",
  data() {
    return {
      constants: Constants,
      modalTitle: "Permissions",
      headerTextVariant: "light",
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    showPermission() {
      var self = this;
      self.$bvModal.show("role-permission-modal");
    },
  },
  props: ['role_name'],
  mounted() {
  },
};
</script>

<style lang="scss">
#role-permission-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>

