<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-button
                variant="outline-success"
                style="margin-top: -48px"
                @click="openModal('add')"
              >
                <i class="fas fa-user-plus"></i> Add Holiday
              </b-button>
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title mb-0">Holiday Master</h3>
                  <b-button
                    class="btn-danger export-api-btn"
                    @click="reloadDatatable"
                    v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="holidayMasterTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th>Holiday Name</th>
                        <th>Date/Day</th>
                        <th>Type</th>
                        <!-- <th>Repeat</th> -->
                        <th>Action</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Holiday Modal Start -->
    <b-modal
      id="ht-modal"
      ref="ht-modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      :ok-disabled="okdisabled"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="holidaymasterForm" @submit.stop.prevent="save" class="needs-validation">
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="holiday_name">
              Holiday Name
              <span class="red">*</span>
            </label>
            <input
              name="holiday_name"
              v-validate="'required'"
              type="text"
              v-model="holidayMasterDetails.holiday_name"
              class="form-control"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('holiday_name')" class="text-danger">{{
              errors.first("holiday_name")
            }}</span>
          </div>
        </div>
         <div class="row mb-3">
          <div class="col-md-12">
            <label for="holiday_name_for_consumer">
              Display Name <small>(If you leave this field blank, then holiday name will be visible to consumer)</small>
            </label>
            <input
              name="holiday_name_for_consumer"
              type="text"
              v-model="holidayMasterDetails.holiday_name_for_consumer"
              class="form-control"
              autocomplete="dontdoit"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="required">
              Repeat
            </label>
            <select 
            class="form-control"
            name="repeat" 
            id="repeat"
            v-model="holidayMasterDetails.repeat"
            >
              <option selected hidden value="">Select a Repeat Time</option>
              <option value="">None of below</option>
              <option value="yearly">Yearly</option>
              <option value="Monthly">Monthly</option>
            </select>
          </div>
        </div>
        <div class="row mb-3" v-if="holidayMasterDetails.repeat">
          <div class="col-md-12">
            <label for="required">
              Occurrences
              <span class="red">*</span>
            </label>
            <input
              v-if="holidayMasterDetails.repeat"
              name="occurrences"
              type="number"
              v-model="holidayMasterDetails.occurrences"
              class="form-control"
              v-validate="'required'"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('occurrences')" class="text-danger">{{
              errors.first("occurrences")
            }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="holiday_id">
              Type
              <span class="red">*</span>
            </label>
            <select 
            class="form-control"
            name="type" 
            id="type"
            v-validate="'required'"
            v-model="holidayMasterDetails.type"
            >
              <option selected hidden value="">Select a Type</option>
              <option value="allday">All Day</option>
              <option value="restricted">Restricted Operation</option>
            </select>
            <span v-show="errors.has('type')" class="text-danger">{{
              errors.first("type")
            }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="holiday_id">
              Date
              <span class="red">*</span>
            </label>
            <input
              class="holiday-date form-control"
              placeholder="Date"
              id="holiday-date"
              onkeydown="return false"
              autocomplete="off"
            />
            <input
              name="date"
              type="hidden"
              v-model="holidayMasterDetails.date"
              v-validate="'required'"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('date')" class="text-danger">{{
              errors.first("date")
            }}</span>
          </div>
        </div>
        <div class="row" v-if="holidayMasterDetails.type == 'restricted' && holidayMasterDetails.date">
          <div class="col-md-12">
            <label for="holiday_id">
              Restricted Hours Details
              <span class="red">*</span>
            </label>
            <b-table-simple
            class="cp-table"
            responsive
            show-empty
            bordered
            sticky-header="800px"
            >
                <b-thead head-variant="light">
                    <tr>
                        <th width="25%">Date</th>
                        <th width="25%">Ecommerce Close</th>
                    </tr>
                </b-thead>
                <b-tbody>
                  <HolidayMasterTimingRow :row="holidayMasterDetails.timings" />
                </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Holiday Modal End -->

    <!-- Assign Holiday Modal Start -->
    <b-modal
      id="assign-holiday-modal"
      ref="assign-holiday-modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="assignHolidayToStore"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div v-if="loadingStoreDetails == true" class="d-flex flex-column align-items-center">
        <i  class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <div>Please wait, we are featching store details...</div>
      </div>
      <form v-else ref="assignForm" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <label for="template_name">
              Holiday Name: {{assignHolidayDetails.holiday_name}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="assigned_name">
              Assigned Stores:
            </label>
          </div>
          <AssignStoreBadgeToHoliday
          class="col-auto"
          v-for="assignedStore in assignedStores" 
          :key="assignedStore.id" 
          :assigned-store="assignedStore"
          :holiday-id="holidayId"
          />
        </div>
        <div class="row my-3">
          <div class="col-md-12">
            <label for="store_id">
              Available Stores
              <span class="red">*</span>
            </label>
            <multiselect
              id="store_id"
              name="store_id"
              v-model="assignHolidayDetails.store_ids"
              :options="storeOptions"
              :multiple="true"
              track-by="retailer"
              label="retailer" 
              :custom-label="customStoreSelectLabel"
              placeholder="Select Store"
              selectLabel
              deselectLabel
              :close-on-select="false"
              :clear-on-select="false"
              group-values="storesIndividual"
              group-label="selectAll"
              :group-select="true"
            ></multiselect>
             <input
              type="hidden"
              v-model="assignHolidayDetails.store_ids"
              name="store_ids"
              v-validate="'required'"
              />
            <span v-show="errors.has('store_ids')" class="text-danger">{{
            errors.first("store_ids")
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="exampleInputEmail1">
                Do not sync with Google
              </label>
              <br>
              <label class="switch mb-0"
              ><input
                class="enable-employee-login"
                type="checkbox"
                v-model="assignHolidayDetails.sync_disabled" /><span
                class="slider round"
              ></span
              ></label>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Assign Holiday Modal End -->

  </div>
</template>
<script>
import moment from "moment";
import api from "@/api/holidaymaster.js";
import { validationMixin } from "vuelidate";
import Constants from "@/common/constant.js";
import HolidayMasterTimingRow from "./components/HolidayMasterTimingRow.vue";
import AssignStoreBadgeToHoliday from "./components/AssignStoreBadgeToHoliday.vue";

export default {
  mixins: [validationMixin],
  data() {
    return {
        modalTitle: "",
        headerTextVariant: "light",
        holidayId: null,

        allHolidayModel: [],
        holidayMasterDetails: {
          holiday_name: '',
          type: '',
          date: null,
          repeat: null,
          occurrences: null,
          holiday_name_for_consumer: '',
          timings: {
            date: '',
            day: '',
            start_time: null,
            end_time: null,
            ecommerce_close_time: null,
          }
        },

        showReloadBtn: false,
        constants: Constants,

        assignHolidayDetails: {},
        storeOptions: [],
        assignedStores: [],
        loadingStoreDetails: false,
        okdisabled: false
    };
  },
  components:{
    HolidayMasterTimingRow,
    AssignStoreBadgeToHoliday
  },
  created() {
    var self = this;
    self.assignHolidayMasterToStore();
  },
  methods: {
    reloadDatatable() {
      var self = this;
      self.loadDT();
    },
    customStoreSelectLabel(store) {
      return `${store.retailer}`;
    },
    openModal(type) {
      var self = this;
      self.dob = "";
      if (type == "edit") {
        self.modalTitle = "Edit Holiday Master";
        self.formType = 'edit';
        self.$bvModal.show("ht-modal");
      } else {
        self.formType = 'add';
        self.modalTitle = "Add Holiday Master";
        self.$bvModal.show("ht-modal");
      }
    },
    resetModal() {
      var self = this;
      self.holidayMasterDetails = {
        holiday_name: '',
        type: '',
        date: null,
        repeat: null,
        occurrences: null,
        holiday_name_for_consumer: '',
        timings: {
          date: '',
          day: '',
          start_time: null,
          end_time: null,
          ecommerce_close_time: null,
        }
      },
      self.okdisabled = false;
      self.assignHolidayDetails = {};
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },
    assignHolidayToStore(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.$validator.validateAll().then((result) => {
        if (result) {
          // CHECK WITH formTYPE 
          //call to api to save the details
          var request = {
            holiday_id: null,
            store_ids: [],
            sync_disabled: null,
          }
          request.holiday_id = self.assignHolidayDetails.edit;
          self.assignHolidayDetails.store_ids.forEach(store => {
            request.store_ids.push(store.id);
          });
          request.sync_disabled = self.assignHolidayDetails.sync_disabled == true ? 0 : 1;
          // Assign section
          api
            .assignHolidayToStore(request)
            .then((response) => {
              if (response.code == 200) {
                success(response.message);
                self.$bvModal.hide("assign-holiday-modal");
                  $("#holidayMasterTable").DataTable().ajax.reload(null, false);
                self.resetModal();
              } else {
                error(response.message);
              }
            })
            .catch((err) => {
              error(err.response.data.message);
            });
        }
      });
    },
    save() {
      var self = this;
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {

        console.log("result", result)
        if (result && !self.okdisabled) {
          self.okdisabled = true;
          var request = {};

          request.holiday_name = self.holidayMasterDetails.holiday_name
          request.type = self.holidayMasterDetails.type
          request.repeat = self.holidayMasterDetails.repeat
          if(self.holidayMasterDetails.repeat != ""){
            request.occurrences = self.holidayMasterDetails.occurrences
          }
          request.date = self.holidayMasterDetails.date
          request.holiday_name_for_consumer = self.holidayMasterDetails.holiday_name_for_consumer

          if(self.holidayMasterDetails.type == 'restricted'){
            request.start_time = self.holidayMasterDetails.timings.start_time
            request.end_time = self.holidayMasterDetails.timings.end_time
            request.ecommerce_close_time = self.holidayMasterDetails.timings.ecommerce_close_time
          }


          if(self.holidayMasterDetails.type)
          // CHECK WITH formTYPE 
          // call to api to save the details
          if (!self.holidayMasterDetails.id) {
            // Add section
            api
              .saveHoliday(request)
              .then((response) => {
                if (response.code == 200) {
                  success(response.message);
                  $("#holidayMasterTable").DataTable().ajax.reload(null, false);
                  self.$bvModal.hide("ht-modal");
                  self.getHolidays();
                  self.resetModal();
                  self.loadDT();
                } else {
                  self.okdisabled = false;
                  error(response.message);
                }
              })
              .catch((err) => {
                self.okdisabled = false;
                error(err.response.data.message);
              });
          }
          
        }
      });
    },
    getStores() {
        var self = this;
        self.loadingStoreDetails = true
        var request = {}
        request.holiday_id = self.holidayId
        api
        .getAllStores(request)
        .then((response) => {
          if ((response.code = 200)) {
            self.storeOptions = [{
            selectAll : 'Select All Stores',
            storesIndividual : response.data.stores_available
            }];
            self.assignedStores = response.data.stores_assigned
            self.loadingStoreDetails = false
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
    },
    loadDT: function () {
      var self = this;
      $("#holidayMasterTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [3] },
          { className: "dt-left", targets: [0, 1, 2] },
          { className: "dt-center", targets: [3] },
        ],
        order: [[2, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Holidays Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchantadmin/getholidays",
          type: "POST",
          data: { _token: "{{csrf_token()}}" },
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allHolidayModel = result.data;
            return result.data;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            } else {
              error(Constants.datatable_error);
              $("#holidayMasterTable_processing").hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "holiday_name" },
          { data: "date" },
          { data: "all_day_holiday" },
          // { data: "repeat" },
          {
            render: function (data, type, full, meta) {
              return '<button data-holiday-id="' +full.edit +'" class="assignHoliday btn btn-success btn-sm" title="Assign to a store" variant="outline-success">Assign</button>';
            },
          }
        ],
      });

      $("#holidayMasterTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#holidayMasterTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
        .unbind() // Unbind previous default bindings
        .bind("input", function (e) {
          // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if (this.value.length >= 3 || e.keyCode == 13) {
            // Call the API search function
            dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if (this.value == "") {
            dtable.search("").draw();
          }
          return;
        });
    },
    assignHolidayMasterToStore() {
      var self = this;
      $(document).on("click", ".assignHoliday", function (e) {
        self.holidayId = $(e.currentTarget).attr("data-holiday-id");
        self.assignHolidayDetails = self.allHolidayModel.find(
          (p) => p.edit == self.holidayId
        );
        self.getStores();
        self.modalTitle = "Assign Holiday to Store";
        self.$bvModal.show("assign-holiday-modal");
      });
    },
    hideModal() {
      this.$bvModal.hide("confirm-modal");
    },
  },
  mounted() {
    var self = this;

    $("body").delegate("#holiday-date", "focusin", function(){
        $(this).datepicker({
          format: "mm/dd/yyyy",
          autoclose: true,
          todayHighlight: true
        });
    });

    $(document).on('change', '#holiday-date', function() {
        self.holidayMasterDetails.date = moment($("#holiday-date").val()).format("YYYY-MM-DD")
        var todayDate = moment($("#holiday-date").val()).format("dddd")
        self.holidayMasterDetails.timings = {
          date: self.holidayMasterDetails.date,
          day: todayDate,
          start_time: '',
          end_time: '',
          ecommerce_close_time: '',
        };
    })

    setTimeout(function () {
      self.loadDT();
    }, 1000);
    document.title = "CanPay - Template Master";
  },
};
</script>

<style lang="scss">
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.cp-table{
  overflow-y: unset!important;
  overflow-x: unset!important;
}
</style>

