<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">Transaction Details For Store {{store_name}}</h3>
                  <b-button
                  class="btn-danger export-api-btn"
                  @click="reloadDatatable"
                  v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="transactionTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th>Transaction Number</th>
                        <th>Consumer</th>
                        <th>Merchant</th>
                        <th>Store</th>
                        <th>Terminal</th>
                        <th>Amount ($)</th>
                        <th>Transaction Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Transaction cancellation comment modal start -->
    <b-modal
      id="comment-modal"
      ref="comment-modal"
      ok-title="Save & Cancel"
      cancel-title="Close"
      ok-variant="success"
      @ok="cancelTransaction"
      cancel-variant="outline-secondary"
      hide-header
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <label for="comment">
            Tell us why you want to cancel this transaction
            <span class="red">*</span>
          </label>
          <textarea
            name="comment"
            type="text"
            v-model="comment"
            class="form-control"
          />
        </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>
      <div class="row" v-if="showMsg">
        <div class="col-12">
          <label for="comment" class="red"
            >Please fill in the required fields.</label
          >
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction cancellation comment modal end -->
  </div>
</template>
<script>
import api from "@/api/transaction.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
export default {
  mixins: [validationMixin],
  data() {
    return {
      allTransactionModel: {},
      showMsg: false,
      transaction_id: null,
      comment: "",
      currentUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      showReloadBtn:false,
      constants: Constants,
      marchent_store_id :null,
      store_name:null,
      searchDate:null
    };
  },
  created() {

  },
  methods: {
    reloadDatatable(){
      var self = this;
      self.loadDT();
    },
    loadDT: function () {
      var self = this;
      $("#transactionTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [7] },
          { className: "dt-left", targets: [0, 1, 2, 3, 4, 5, 6] },
          { className: "dt-center", targets: [7] },
        ],
        order: [[6, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Transactions Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchantadmin/getstoreleveltransactions",
          type: "POST",
          data: { _token: "{{csrf_token()}}", marchent_store_id: self.marchent_store_id,searchDate:self.searchDate },
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allTransactionModel = result.data;
            return self.allTransactionModel;
          },
          error: function (request, status, error) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#transactionTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "transaction_number" },
          { data: "consumer_name" },
          { data: "merchant_name" },
          { data: "store_name" },
          { data: "terminal_name" },
          { data: "amount" },
          { data: "transaction_time" },
          { data: "status" },
        ],
      });

      $("#transactionTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#transactionTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
    leaveComment() {
      var self = this;
      $(document).on("click", ".leave-comment", function (e) {
        //open the modal
        self.comment = "";
        self.$refs["comment-modal"].show();
        //set transaction id to a hidden field for future use
        self.transaction_id = $(e.currentTarget).attr("data-id");
      });
    },
    cancelTransaction(bvModalEvt) {
      var self = this;
      if (self.comment == "") {
        self.showMsg = true;
        bvModalEvt.preventDefault();
      } else {
        self.showMsg = false;
        var request = {
          transaction_id: self.transaction_id,
          comment: self.comment,
          transaction_place: "Web",
        };
        api
          .cancelTransaction(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
              self.$refs["comment-modal"].hide();
              self.comment == "";
              self.loadDT();
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            error(err);
          });
      }
    },
  },
  mounted() {
    var self = this;
    self.marchent_store_id = localStorage.getItem("marchent_store_id");
    self.store_name = localStorage.getItem("store_name");
    self.searchDate = localStorage.getItem("searchDate");
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    document.title = "CanPay - Transactions";
  },
};
</script>

F