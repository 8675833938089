<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Transaction Report</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storelist"
                        :multiple="true"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <multiselect
                        id="terminal"
                        name="terminal"
                        v-model="selectedTerminals"
                        :options="terminallist"
                        :multiple="true"
                        :custom-label="customLabel"
                        track-by="id"
                        placeholder="Select Terminals"
                        selectLabel
                        :searchable="true"
                        deselectLabel
                        :close-on-select="false"
                        :clear-on-select="false"
                        class="w-100"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="switch"
                        ><input
                          class="enable-employee-login"
                          type="checkbox"
                          v-model="toggle_void" /><span
                          class="slider round"
                        ></span
                      ></label>
                      Voids
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <div class="col-md-9">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="generateReport(false)"
                    >
                      Generate
                    </button>
                    <button
                      type="button"
                      @click="generateReport(true)"
                      class="btn btn-danger ml-10"
                    >
                      Generate & Export<i
                        class="fa fa-download ml-10"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button
                      type="button"
                      @click="exportAllStores()"
                      class="btn btn-danger ml-10 generateBtn"
                    >
                      Export All Stores<i
                        class="fa fa-download ml-10"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                  <div class="col-md-3" v-if="showReport">
                    <div class="form-group">
                      <label class="switch"
                        ><input
                          class="enable-employee-login latestrecord"
                          type="checkbox"
                          v-model="toggle_latest" /><span
                          class="slider round"
                        ></span
                      ></label>
                      Show All Stores Report
                    </div>
                  </div>
                </div>
                <span class="helper-text-right" v-if="helptext_visible"
                  >*Today's sales are not settled and can still be voided.</span
                >
              </div>
              <div class="card-body">
                <div class="row" v-if="showReport">
                  <div class="col-12">
                    <b-btn
                      @click="showAllDetails"
                      class="mb-10 btn-dark"
                      v-if="report.length > 0"
                      >Expand All</b-btn
                    >
                    <b-btn
                      @click="hideAllDetails"
                      class="ml-10 mb-10 btn-dark"
                      v-if="report.length > 0"
                      >Collapse All</b-btn
                    >
                    <b-table
                      show-empty
                      :fields="fields"
                      responsive
                      bordered
                      sticky-header="600px"
                      id="merchantLocationTransactionTable"
                      :items="report"
                      :tbody-tr-class="rowClass"
                    >
                      <template #cell(store_category)="data">
                        {{ data.item.store_category }} <br />
                        <b-button
                          size="sm"
                          @click="data.toggleDetails"
                          class="mr-2 btn-dark"
                          v-if="data.item.store_category != ''"
                        >
                          {{ data.detailsShowing ? "Hide" : "Show" }} Details
                        </b-button>
                      </template>
                      <template #row-details="data">
                        <b-table-simple
                          :fields="fields"
                          :tbody-tr-class="rowClass"
                          class="merchantLocationTableCls"
                        >
                          <b-thead head-variant="light">
                            <b-tr>
                              <b-th class="text-center">Terminal ID</b-th>
                              <b-th class="text-center">Transaction Date</b-th>
                              <b-th class="text-center" v-if="data.item.report_data1[1].transaction_time!=''">Transaction Time</b-th>
                              <b-th class="text-center">Transaction No</b-th>
                              <b-th class="text-center">Total Payment</b-th>
                              <b-th class="text-center">Base Amount</b-th>
                              <b-th class="text-center">Tip</b-th>
                              <b-th class="text-center">Trans Count</b-th>
                              <b-th class="text-center">Transaction Status</b-th>
                              <b-th class="text-center">Consumer Identifier</b-th>
                              <b-th class="text-center">Employee Id</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr
                              v-for="(terminalList, index) in data.item
                                .report_data1"
                              :key="index"
                            >
                              <b-td class="text-center">{{
                                terminalList.terminalID
                              }}</b-td>
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                >{{
                                  terminalList.transaction_date
                                    | moment("MM/DD/YYYY")
                                }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                 v-if="data.item.report_data1[1].transaction_time!=''">{{
                                  terminalList.transaction_time
                                    | moment("hh:mm A")
                                }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                >{{ terminalList.transaction_no }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-if="terminalList.terminalID != ''"
                                ><b>{{ terminalList.total_payment }}</b></b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-else
                                >{{ terminalList.total_payment }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-if="terminalList.terminalID != ''"
                                ><b>{{ terminalList.base_amount }}</b></b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-else
                                >{{ terminalList.base_amount }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-if="terminalList.terminalID != ''"
                                ><b>{{ terminalList.tip }}</b></b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-else
                                >{{ terminalList.tip }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-if="terminalList.terminalID != ''"
                                ><b>{{ terminalList.trans_count }}</b></b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                v-else
                                >{{ terminalList.trans_count }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                >{{ terminalList.transaction_status }}</b-td
                              >
                              <b-td
                                :class="
                                  terminalList.transaction_status == 'Voided'
                                    ? 'text-gray-italic'
                                    : 'text-center'
                                "
                                >{{ terminalList.consumer_identifier }}</b-td>
                                <b-td
                                class="text-center">{{ terminalList.employee_id }}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </template>
                      <template #cell(total_payment)="data">
                        <div v-if="data.item.transaction_status === ''">
                          <b>{{ data.value }}</b>
                        </div>
                        <div v-else>
                          {{ data.value }}
                        </div>
                      </template>
                      <template #cell(base_amount)="data">
                        <div v-if="data.item.transaction_status === ''">
                          <b>{{ data.value }}</b>
                        </div>
                        <div v-else>
                          {{ data.value }}
                        </div>
                      </template>
                      <template #cell(tip)="data">
                        <div v-if="data.item.transaction_status === ''">
                          <b>{{ data.value }}</b>
                        </div>
                        <div v-else>
                          {{ data.value }}
                        </div>
                      </template>
                      <template #cell(trans_count)="data">
                        <div v-if="data.item.transaction_status === ''">
                          <b>{{ data.value }}</b>
                        </div>
                        <div v-else>
                          {{ data.value }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="row" v-if="!showReport">
                  <div class="col-12">
                    <div class="card" style="height: 400px;">
                      <div class="card-header border-0">
                        <h4 class="card-title" style="font-size: 16px;">Last 5 generated reports</h4>
                      </div>
                      <div  class="card-body table-responsive p-0 text-center no-record-found"  v-if="!recent_generated_reports.length">
                        No Record Found
                      </div>
                      <div class="card-body table-responsive p-0" v-else>
                        <table class="table table-striped table-valign-middle td-pad-15">
                          <thead>
                            <tr>
                              <th class="text-center">Start Date</th>
                              <th class="text-center">End Date</th>
                              <th class="text-center">Report File</th>
                              <th class="text-center">Generated On</th>
                            </tr>
                          </thead>
                          <tbody  v-for="(row, index) in recent_generated_reports" :key="index">
                            <tr>
                              <td class="text-center">
                                {{row.from_date}}
                              </td>
                              <td class="text-center">{{row.to_date}}</td>
                              <td class="text-center">
                                <a :href="row.file_url" download><i class="fas fa-download" aria-hidden="true"></i></a>
                              </td>
                              <td class="text-center">
                                {{ row.created_at }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Report Exist Modal -->
    <b-modal
        id="report-exist-modal"
        ref="report-exist-modal"
        cancel-variant="outline-secondary"
        hide-header
        hide-footer
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
      >
        <div class="row">
          <div class="col-12">
          </div>
          <div class="col-12">
            <p>Your Report is ready for download. Click <a :href="existing_report.file_url" download>here</a> to download the report.</p>
          </div>
          <div class="col-12 row justify-center m-0">
            <button class="btn btn-success w-100" @click="hideModal('report-exist-modal')">Okay</button>
          </div>
        </div>
        <!-- <div class="row" style="margin-bottom: 40px"></div> -->
      </b-modal>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { saveAs } from "file-saver";
import { HourGlass } from "vue-loading-spinner";
export default {
  data() {
    return {
      report: [],
      reportExportArr: [],
      reportHiddenArr: [],
      fields: [
        { key: "store_name", class: "text-center" },
        { key: "store_type", class: "text-center" },
        { key: "store_category", class: "text-center" },
        { key: "total_payment", class: "text-center" },
        { key: "base_amount", class: "text-center" },
        { key: "tip", class: "text-center" },
        { key: "trans_count", class: "text-center" },
      ],
      storelist: [],
      selectedStore: [],
      selectedTerminals: [],
      terminallist: [],
      void_label: "",
      toggle_void: 0,
      search_element: "",
      voided_index: [],
      total_amount_index: [],
      loading: false,
      helptext_visible: false,
      recent_generated_reports: [],
      showReport: false,
      existing_report: {},
      toggle_latest: 0,
    };
  },
  components: {
    HourGlass,
  },
  watch: {
    toggle_void: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.void_label = "Checked";
      } else {
        self.void_label = "";
      }
    },
    toggle_latest: function (newval, oldval) {
      var self = this;
      if (newval == 0) {
        self.showReport = true;
      } else {
        self.showReport = false;
      }
    },
    selectedStore: function (newval, oldval) {
      this.getAllTerminals();
    },
  },
  created() {
    //fetch store list for that
    this.getAllAuthorizedStores();
  },
  mounted() {
    this.getlatestTransactionReportHistory();
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date , #end-date").datepicker("setDate", new Date());

    $("#checkall").change(function () {
      $(".cb-element").prop("checked", this.checked);
    });

    $(".cb-element").change(function () {
      if ($(".cb-element:checked").length == $(".cb-element").length) {
        $("#checkall").prop("checked", true);
      } else {
        $("#checkall").prop("checked", false);
      }
    });
  },
  methods: {
    // this method returns the voided rows as gray colored rows
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.transaction_status === "Voided") return "text-gray";
    },
    // this method arranges the rows to show in the report table
    drawTable(raw_report, reportExport) {
      var self = this;
      // variable for the hidden section of the report
      var reportData1 = {};
      var checkDataExist = false;
      self.reportHiddenArr = [];
      if(raw_report.length!=0)
      {
      for (var i = 0; i < raw_report.length; i++) {
        // creating the first row with only store level information
        console.log(raw_report);
        if (raw_report[i][0][0]["trans_count"] != 0) {
          checkDataExist = true;
          var tbody_start = {
            store_name: raw_report[i][0][0]["store_name"],
            store_type: "",
            store_category: "",
            terminalID: "",
            transaction_date: "",
            transaction_time: "",
            transaction_no: "",
            total_payment: raw_report[i][0][0]["total_payment"],
            base_amount: raw_report[i][0][0]["base_amount"],
            tip: raw_report[i][0][0]["tip"],
            trans_count: raw_report[i][0][0]["trans_count"],
            transaction_status: "",
            consumer_identifier: "",
            employee_id: "",
          };
          //For Report
          self.report.push(tbody_start);
          //For Excel Export
          self.reportExportArr.push(tbody_start);
          // creating the second row which is repeatative for store type
          $.each(raw_report[i][1], function (key, value) {
            tbody_start = {
              store_name: "",
              store_type: value.store_type,
              store_category: "",
              terminalID: "",
              transaction_date: "",
              transaction_time: "",
              transaction_no: "",
              total_payment: value.total_payment,
              base_amount: value.base_amount,
              tip: value.tip,
              trans_count: value.trans_count,
              transaction_status: "",
              consumer_identifier: "",
              employee_id: "",
            };
            //For Report
            self.report.push(tbody_start);
            //For Excel Export
            self.reportExportArr.push(tbody_start);
            // creating the third row which is repeatative for store category
            $.each(raw_report[i][2], function (k, v) {
              if (value.store_type == v.store_type) {
                self.reportHiddenArr = [];
                $.each(raw_report[i][3], function (a, val) {
                  if (
                    val.store_category == v.store_category &&
                    val.store_type == v.store_type
                  ) {
                    reportData1 = {
                      store_name: "",
                      store_type: "",
                      store_category: "",
                      terminalID: val.terminalID,
                      transaction_date: "",
                      transaction_time: "",
                      transaction_no: "",
                      total_payment: val.total_payment,
                      base_amount: val.base_amount,
                      tip: val.tip,
                      trans_count: val.trans_count,
                      transaction_status: "",
                      consumer_identifier: "",
                      employee_id: "",
                    };
                    self.reportHiddenArr.push(reportData1);
                    self.voided_index_hidden = [];
                    // creating the terminal listing (Excel Export)
                    $.each(raw_report[i][4], function (x, y) {
                      if (
                        val.store_category == y.store_category &&
                        val.store_type == y.store_type &&
                        val.terminalID == y.terminalID
                      ) {
                        reportData1 = {
                          store_name: "",
                          store_type: "",
                          store_category: "",
                          terminalID: "",
                          transaction_date: moment(y.transaction_time).format(
                            "MM/DD/YYYY"
                          ),
                          transaction_time: value.ecommerce_store == 0 ? moment(y.transaction_time).format(
                            "hh:mm A"
                          ) : "",
                          transaction_no: y.transaction_no,
                          total_payment: y.total_payment,
                          base_amount: y.base_amount,
                          tip: y.tip,
                          trans_count: y.trans_count,
                          transaction_status: y.transaction_status,
                          consumer_identifier: y.consumer_identifier,
                          employee_id: y.employee_id,
                        };
                        self.reportHiddenArr.push(reportData1);
                      }
                    });
                  }
                });

                //For Report
                tbody_start = {
                  store_name: "",
                  store_type: "",
                  store_category: v.store_category,
                  terminalID: "",
                  transaction_date: "",
                  transaction_time: "",
                  transaction_no: "",
                  total_payment: v.total_payment,
                  base_amount: v.base_amount,
                  tip: v.tip,
                  trans_count: v.trans_count,
                  transaction_status: "",
                  consumer_identifier: "",
                  employee_id: "",
                  report_data1: self.reportHiddenArr,
                  report_data2: raw_report[4],
                };
                self.report.push(tbody_start);
                //For Excel Export
                var tbody_start_excel = {
                  store_name: "",
                  store_type: "",
                  store_category: v.store_category,
                  terminalID: "",
                  transaction_date: "",
                  transaction_time: "",
                  transaction_no: "",
                  total_payment: v.total_payment,
                  base_amount: v.base_amount,
                  tip: v.tip,
                  trans_count: v.trans_count,
                  transaction_status: "",
                  consumer_identifier: "",
                  employee_id: "",
                };
                self.reportExportArr.push(tbody_start_excel);
                // creating the fourth row which is repeatative for specific terminal (Excel Export)
                $.each(raw_report[i][3], function (a, val) {
                  if (
                    val.store_category == v.store_category &&
                    val.store_type == v.store_type
                  ) {
                    tbody_start_excel = {
                      store_name: "",
                      store_type: "",
                      store_category: "",
                      terminalID: val.terminalID,
                      transaction_date: "",
                      transaction_time: "",
                      transaction_no: "",
                      total_payment: val.total_payment,
                      base_amount: val.base_amount,
                      tip: val.tip,
                      trans_count: val.trans_count,
                      transaction_status: "",
                      consumer_identifier: "",
                      employee_id: "",
                    };
                    self.reportExportArr.push(tbody_start_excel);
                    self.voided_index = [];
                    // creating the terminal listing (Excel Export)
                    $.each(raw_report[i][4], function (x, y) {
                      if (
                        val.store_category == y.store_category &&
                        val.store_type == y.store_type &&
                        val.terminalID == y.terminalID
                      ) {
                        tbody_start_excel = {
                          store_name: "",
                          store_type: "",
                          store_category: "",
                          terminalID: "",
                          transaction_date: moment(y.transaction_time).format(
                            "MM/DD/YYYY"
                          ),
                          transaction_time: value.ecommerce_store == 0 ? moment(y.transaction_time).format(
                            "hh:mm A"
                          ) : "",
                          transaction_no: y.transaction_no,
                          total_payment: y.total_payment,
                          base_amount: y.base_amount,
                          tip: y.tip,
                          trans_count: y.trans_count,
                          transaction_status: y.transaction_status,
                          consumer_identifier: y.consumer_identifier,
                          employee_id: y.employee_id,
                        };
                        self.reportExportArr.push(tbody_start_excel);
                      }
                    });
                  }
                });
              }
            });
          });
        }
      }
      if (reportExport) {
        self.exportReport();
      } else {
        self.loading = false;
      }
      }
       if(!checkDataExist) {
          error("No records found!");
          self.loading = false;
        }
    },
    //API call to fetch authorized stores
    getAllAuthorizedStores() {
      var self = this;
      apiuser
        .getStores()
        .then(function (response) {
          if (response.code == 200) {
            self.storelist = response.data;
            if (self.storelist.length == 1) {
              self.selectedStore = self.storelist[0];
            }
          } else if (response.code == 401) {
            self.getConfirmation();
            } else {
            error(response.message);
            }
        })
        .catch(function (error) {});
    },
    // on store dropdown value change this api fetches all the terminals under selected store
    getAllTerminals() {
      var self = this;
      var storeId = [];
      if(self.storelist.length == 1)
      {
        storeId.push(self.selectedStore.id);

      }
      else
      {
      self.selectedStore.filter((item) => {
        storeId.push(item.id);
      });
      }

      var request = {
        store_id: storeId,
      };
      api
        .getAllTerminals(request)
        .then(function (response) {
          if (response.code == 200) {
            self.terminallist = response.data;
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
        });
    },

    // API call to generate the merchant location transaction report
    generateReport(reportExport) {
      var self = this;
      if (self.selectedStore === null) {
        error("Please select a store in order to generate the report.");
        return false;
      }
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment($().val()).format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment($().val()).format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      var today = moment();
      if (moment($("#end-date").val()).isSame(today, "day")) {
        self.helptext_visible = true;
      } else {
        self.helptext_visible = false;
      }
      self.report = [];
      self.reportExportArr = [];
      var terminals = [];
      if (self.selectedTerminals != []) {
        $.each(self.selectedTerminals, function (key, value) {
          var terminalsDetails = {};
          terminalsDetails["merchant_store_id"] = value.merchant_store_id;
          terminalsDetails["terminal_id"] = [value.id];
          terminals.push(terminalsDetails);
        });
      }
      const arrayHashmap = terminals.reduce((obj, item) => {
        obj[item.merchant_store_id]
          ? obj[item.merchant_store_id].terminal_id.push(...item.terminal_id)
          : (obj[item.merchant_store_id] = { ...item });
        return obj;
      }, {});

      terminals = Object.values(arrayHashmap);

      var storeId = [];
            if(self.storelist.length == 1)
      {
        storeId.push(self.selectedStore.id);
      }
      else
      {
      self.selectedStore.filter((item) => {
        storeId.push(item.id);
      });
      }

      var request = {
        store_id: storeId,
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        terminals: terminals,
        show_void: self.toggle_void,
      };
      if (request.from_date > request.to_date) {
        error("To Date cannot be greater than From date");
        return false;
      }
      self.loading = true;
      api
        .generatemerchantLocationTransactionReport(request)
        .then(function (response) {
          if (response.code == 200) {
            self.showReport = true;
            self.toggle_latest = 0;
            self.drawTable(response.data, reportExport);
          } else {
            error(response.message);
            self.loading = false;
            self.toggle_latest = 0;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    // exports the report
    exportReport() {
      var self = this;
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
        report: self.reportExportArr,
        voided_index: self.voided_index,
        total_amount_index: self.total_amount_index,
      };
      $.each(self.reportExportArr, function (key, value) {
        if (value.transaction_status === "Voided") {
          self.voided_index.push(key);
        } else if (value.transaction_status === "") {
          self.total_amount_index.push(key);
        }
      });
      api
        .getmerchantlocationtransactionexport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_CanPay_Transaction_Report.xlsx"
          );
          self.toggle_latest = 0;
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    showAllDetails() {
      this.report.forEach((item) => {
        if (item.store_category != "") {
          this.$set(item, "_showDetails", true);
        }
      });
    },
    hideAllDetails() {
      this.report.forEach((item) => {
        this.$set(item, "_showDetails", false);
      });
    },
    customLabel(terminal) {
      return `${terminal.terminal_name}`;
    },
    // Export Report for all the Stores
    exportAllStores(){
      var self = this;
      var start_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      var end_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      var current_date = moment().format("YYYY-MM-DD");
      if(moment(start_date).isSame(current_date) || moment(end_date).isSame(current_date)){
        error("The report generation date must be earlier than the current date");
        return false;
      }
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      self.loading = true;
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
      };
      api
        .exportTransactionReportAllStores(request)
        .then(function (response) {
          if(response.code == 200){
            self.showReport = false;
            self.getlatestTransactionReportHistory();
            success(response.message);
            self.loading = false;
          }
          else if (response.code == 202){
            // report already generated previously
            self.existing_report = response.data;
            self.loading = false;
            self.showModal('report-exist-modal');
          }
          else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          self.loading = false;
          error(error);
        });
    },
    // get latest five report history
    getlatestTransactionReportHistory(){
      var self = this;
      self.loading = true;
        api
        .getlatestTransactionReportHistory()
        .then(function (response) {
          if (response.code == 200) {
            self.recent_generated_reports = response.data;
            self.loading = false;
          }
        })
        .catch(function (error) {
          error(error);
          self.loading = false;
        });
    },
    hideModal(val) {
      this.$bvModal.hide(val);
    },
    showModal(val) {
      this.$bvModal.show(val);
    },
  },
};
</script>
