<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">POS Transaction History</h3>
                  <b-button
                  class="btn-danger export-api-btn"
                  @click="reloadDatatable"
                  v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storeList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="generateReport(false)"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="generateReport(true)"
                  class="btn btn-danger ml-10"
                >
                  Export <i
                    class="fa fa-download ml-10"
                  ></i>
                </button>
              </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="transactionTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th>Transaction Number</th>
                        <th>Store</th>
                        <th>Terminal</th>
                        <th>Amount ($)</th>
                        <th>Tip Amount ($)</th>
                        <th>Transaction Time</th>
                        <th>Status</th>
                        <th>Cancel Transaction</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Transaction cancellation comment modal start -->
    <b-modal
      id="comment-modal"
      ref="comment-modal"
      ok-title="Save & Cancel"
      cancel-title="Close"
      ok-variant="success"
      @ok="cancelTransaction"
      cancel-variant="outline-secondary"
      hide-header
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <label for="comment">
            Tell us why you want to cancel this transaction
            <span class="red">*</span>
          </label>
          <textarea
            name="comment"
            type="text"
            v-model="comment"
            class="form-control"
          />
        </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>
      <div class="row" v-if="showMsg">
        <div class="col-12">
          <label for="comment" class="red"
            >Please fill in the required fields.</label
          >
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction cancellation comment modal end -->
    <!-- Transaction modification modal start -->
    <b-modal
      id="modification-modal"
      ref="modification-modal"
      ok-title="Send to consumer"
      cancel-title="Close"
      ok-variant="success"
      @ok="modifyTransaction"
      cancel-variant="outline-secondary"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        
        <div class="col-12">
            <div class="col-6">
            <label for="modification">
              Updated Transaction Amount
              <span class="red">*</span>
            </label>
            <input
              name="updated_amount"
              type="text"
              v-model="updated_amount"
              class="form-control numberonly"
            />
          </div>
        </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>
      <div class="row" v-if="UpAmtMsg">
        <div class="col-12">
          <label for="modification" class="red mt-2 pl-3"
            >Please fill in the required fields.</label
          >
        </div>
      </div>
      
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction modification modal end -->
  </div>
</template>
<script>
import api from "@/api/transaction.js";
import user_api from "@/api/user.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { HourGlass } from "vue-loading-spinner";
import Constants from "@/common/constant.js";
import moment from "moment";
export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      allTransactionModel: {},
      showMsg: false,
      UpAmtMsg: false,
      OnlyNumMsg: false,
      transaction_id: null,
      comment: "",
      updated_amount: '',
      storeList: [],
      selectedStore: "",
      from_date: "",
      to_date: "",
      currentUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      showReloadBtn:false,
      constants: Constants,
      headerTextVariant: "light",
      modalTitle: "Transaction Modification Amount",
    };
  },
  components: {
    HourGlass,
  },
  created() {
    this.posLeaveComment();
  },
  methods: {
    reloadDatatable(){
      var self = this;
      self.loadDT();
    },
    getStores() {
      var self = this;
      let request = {
        params: {
          is_ecommerce: 0
          }
      };
      user_api
        .getStores(request)
        .then((response) => {
          if ((response.code = 200)) {
            self.storeList = response.data;
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
    },
    loadDT: function () {
      var self = this;
      var dataString = {
        _token: "{{csrf_token()}}",
        from_date: self.from_date,
        to_date: self.to_date,
        store_id: self.selectedStore.id,
      };
      $("#transactionTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [4,7] },
          { className: "dt-left", targets: [0, 1, 2, 3, 4, 5] },
          { className: "dt-center", targets: [7] },
        ],
        order: [[5, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Transactions Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchant/gettransactions",
          type: "POST",
          data: dataString,
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allTransactionModel = result.data;
            return self.allTransactionModel;
          },
          error: function (request, status, error) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#transactionTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "transaction_number" },
          { data: "store_name" },
          { data: "terminal_name" },
          { data: "last_approve_amount" },
          { data: "last_approve_tip_amount" },
          { data: "transaction_time" },
          { data: "status" },
          {
            render: function (data, type, full, meta) {
              if (full.cancelable == 1 && full.is_v1 == 0 ) {
                return (
                  '<a style="color: white !important" class="btn btn-danger pos-leave-comment" data-id="' +
                  full.edit +
                  '"><i class="fa fa-times fa-lg"></i> Cancel</a>'
                );
              } else {
                return null;
              }
            },
          },
          
        ],
      });

      $("#transactionTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#transactionTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
    posLeaveComment() {
      var self = this;
      $(document).on("click", ".pos-leave-comment", function (e) {
        //open the modal
        self.comment = "";
        self.$bvModal.show('comment-modal');
        //set transaction id to a hidden field for future use
        self.transaction_id = $(e.currentTarget).attr("data-id");
      });
      // modify transaction
      $(document).on("click", ".pos-leave-modify", function (e) {
        //open the modal
        self.updated_amount = "";
        self.$refs["modification-modal"].show();
        //set transaction id to a hidden field for future use

        self.transaction_id = $(e.currentTarget).attr("data-id");
      });
      $(document).on("keypress", ".numberonly", function (e) {
        var keyCode = e.which ? e.which : e.keyCode
        console.log(keyCode);
        if ((keyCode != 46 || $(this).val().indexOf('.') != -1) && (keyCode < 48 || keyCode > 57)) {
          self.UpAmtMsg = true;
          return false;
        } else {
          self.UpAmtMsg = false;
          return true;
        }
      })
    },
    cancelTransaction(bvModalEvt) {
      var self = this;
      if (self.comment == "") {
        self.showMsg = true;
        bvModalEvt.preventDefault();
      } else {
        self.showMsg = false;
        var request = {
          transaction_id: self.transaction_id,
          comment: self.comment,
          transaction_place: "Web",
        };
        api
          .cancelTransaction(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
              self.$refs["comment-modal"].hide();
              self.comment == "";
              self.loadDT();
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            error(err);
          });
      }
    },
    modifyTransaction(UpAmtEvent){
      var self = this;
      console.log(self.transaction_id);
      if(self.updated_amount == ''){
        self.UpAmtMsg = true;
        UpAmtEvent.preventDefault();
      }
      else {
        self.UpAmtMsg = false;
        var request = {
          transaction_id: self.transaction_id,
          updated_amount: self.updated_amount
        };
        api
          .merchantTransactionUpdate(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
              self.$refs["modification-modal"].hide();
              self.updated_amount == "";
              self.loadDT();
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            error(err);
          });
      }
    },
    generateReport(reportExport) {
      var self = this;
      if(self.selectedStore == ''){
        error("Please select store.");
        return false;
      }
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment($("#end-date").val()).format("YYYY-MM-DD")
      ) {
        error("End Date not greater than Start Date.");
        return false;
      }
      
      self.from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      self.to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      if (reportExport) {
        self.exportReport();
      } else {
        self.loadDT();
      }
    },
    exportReport(){
        var self = this;
        self.loading = true;
        var request = {
          from_date: self.from_date,
          to_date: self.to_date,
          store_id: self.selectedStore.id,
        };
        api.getTransactionExport(request)
          .then(function (response) {
            var FileSaver = require("file-saver");
            var blob = new Blob([response], {
              type: "application/xlsx",
            });
            FileSaver.saveAs(
              blob,
              moment().format("MM/DD/YYYY") +
                "_POSTransactionList.xlsx"
            );
            self.loading = false;
          })
          .catch(function (error) {          
            self.loading = false;
            self.getStores();
          });
      },
  },
  mounted() {
    var self = this;
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-07-01'),
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date").datepicker("setDate", "-7d");
    $("#end-date").datepicker("setDate", new Date());
    self.getStores();
    // setTimeout(function () {
    //   self.loadDT();
    // }, 2000);
    document.title = "CanPay - Transactions";
  },
};
</script>

F