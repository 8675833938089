<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Dashboard</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="select-date-cls">
                <b>Selected Date : <span v-html="selected_date"></span></b>
              </li>
              <li>
                <h1 class="calendar-link">
                  <i class="fas fa-calendar-alt" id="select-date"></i>
                </h1>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box">
              <span class="info-box-icon bg-info elevation-1"
                ><i class="fas fa-dollar-sign"></i
              ></span>
              <div class="info-box-content">
                <span class="info-box-text">Total Sales</span>
                <span class="info-box-number" v-html="total_sales"> </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box mb-4">
              <span class="info-box-icon bg-danger elevation-1"
                ><i class="fas fa-calculator"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Total Transactions</span>
                <span
                  class="info-box-number"
                  v-html="total_transactions"
                ></span>
              </div>
            </div>
          </div>
          <div class="clearfix hidden-md-up"></div>
          <div class="col-12 col-sm-6 col-md-4">
            <div class="info-box mb-4">
              <span class="info-box-icon bg-success elevation-1"
                ><i class="fas fa-calendar-minus"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Total Monthly Sales</span>
                <span
                  class="info-box-number"
                  v-html="total_monthly_sales"
                ></span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header border-transparent">
                <h3 class="card-title">Storewise Transaction Details</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storeList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="generateStorewiseReport()"
                >
                  Generate
                </button>
                <button
                  type="button"
                  @click="exportStoreTransactionDetails(true)"
                  class="btn btn-danger ml-10"
                >
                  Export<i class="fa fa-download ml-10" aria-hidden="true"></i>
                </button>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <b-table
                    show-empty
                    responsive
                    head-variant="light"
                    sticky-header="600px"
                    id="merchantLocationTransactionTable"
                    :items="transactionDetails"
                    :fields="fields"
                  >
                  </b-table>
                  <div class="float-right">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @change="onPageChanged"
                      aria-controls="merchantLocationTransactionTable"
                    >
                    <template #first-text><span class="pagination-text"><i class="fas fa-angle-double-left"></i></span></template>
                    <template #prev-text><span class="pagination-text"><i class="fas fa-angle-left"></i></span></template>
                    <template #next-text><span class="pagination-text"><i class="fas fa-angle-right"></i></span></template>
                    <template #last-text><span class="pagination-text"><i class="fas fa-angle-double-right"></i></span></template>
                    <template #ellipsis-text>
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                    </template>
                    <template #page="{ page, active }">
                      <b v-if="active">{{ page }}</b>
                      <i v-else>{{ page }}</i>
                    </template>
                  </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</template>
<script>
import api from "@/api/reports.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { HourGlass } from "vue-loading-spinner";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      fields: [
        { key: "store_name", class: "text-center", sortable: "true" },
        { key: "total_sales", class: "text-center", sortable: "true" },
        { key: "total_transactions", class: "text-center", sortable: "true" },
        { key: "total_monthly_sales", class: "text-center", sortable: "true" },
        {
          key: "total_monthly_transaction",
          class: "text-center",
          sortable: "true",
        },
        {
          key: "avg_sales_over_the_last_seven_days",
          label: "Average sales (last 7 days)",
          class: "text-center",
          sortable: "true",
        },
        {
          key: "avg_ticket_cost",
          label: "Average ticket cost (last 7 days)",
          class: "text-center",
          sortable: "true",
        },
        {
          key: "total_fees",
          label: "Total fees (current month)",
          class: "text-center",
          sortable: "true",
        },
      ],
      transactionDetails: [],
      total_sales:0,
      total_transactions:0,
      total_monthly_sales:0,
      total_monthly_transaction:0,
      avg_sales_over_the_last_seven_days:0,
      avg_ticket_cost:0,
      releaseNotes:{},
      loading: false,
      selected_date: moment($().val()).format("MM/DD/YYYY"),
      searchDate: moment($().val()).format("YYYY-MM-DD"),
      user: JSON.parse(localStorage.getItem("store_user")),
      selectedStore: '',
      storeList: [],
      perPage: 10,
      currentPage: 1,
      rows: 0,
    };
  },
  components: {
    HourGlass,
  },
  created() {
    //Fetch the Transaction details
    this.getTransactionDetails();
    this.checkNewReleases();
  },
  mounted() {
    var self = this;
    // authorized stores
    self.getAuthorizedStores();
    // if merchant not allowed to show the total fees
    if (self.user.allow_merchant_fees_report == 0) {
      self.fields.splice(-1);
    }
    $("#select-date")
      .datepicker({
        format: "mm/dd/yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: new Date(),
      })
      .on("changeDate", function (e) {
        self.selected_date = e.format();
        self.searchDate = e.format("yyyy-mm-dd");
        //Fetch the Transaction details
        self.getTransactionDetails();
      });
  },
  methods: {
    // API call to generate the storewise Transaction Details
    getTransactionDetails() {
      var self = this;
      var request = {
        searchDate: self.searchDate,
        current_page: self.currentPage,
        per_page: self.perPage
      };
      if (self.selectedStore != '') {
        request.store_id = self.selectedStore.id;
      }
      self.getMerchantTransactionDetailsApi(request);
    },
    generateStorewiseReport() {
      var self = this;
      self.currentPage = 1;
      var request = {
        searchDate: self.searchDate,
        current_page: self.currentPage,
        per_page: self.perPage
      };
      if(self.selectedStore){
        request.store_id = self.selectedStore.id;
      }
      self.getMerchantTransactionDetailsApi(request);
    },
    onPageChanged(page) {
      // console.log(page);
      var self = this;
      self.currentPage = page;
      var request = {
        searchDate: self.searchDate,
        current_page: self.currentPage,
        per_page: self.perPage
      };
      if(self.selectedStore){
        request.store_id = self.selectedStore.id;
      }
      self.getMerchantTransactionDetailsApi(request);
    },
    getMerchantTransactionDetailsApi(request){
      var self = this;
      self.transactionDetails = [];
      self.loading = true;
      api
        .getMerchantTransactionDetails(request)
        .then(function (response) {
          if (response.code == 200) {
            self.transactionDetails = response.data.transactions;
            self.rows = response.data.total_rows;
            //Populate the Boxes
            self.total_sales = "$" + response.data.total_sales;
            self.total_transactions = response.data.total_transactions;
            self.total_monthly_sales = "$" + response.data.total_monthly_sales;
            self.total_monthly_transaction =
              response.data.total_monthly_transaction;
            self.avg_sales_over_the_last_seven_days =
              "$" + response.data.avg_sales_over_the_last_seven_days;
            self.avg_ticket_cost = "$" + response.data.avg_ticket_cost;
            self.loading = false;
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    exportStoreTransactionDetails(){
      var self = this;
      self.loading = true;
      var allow_merchant_fees_report = 0;
      if (self.user.allow_merchant_fees_report == 1) {
        allow_merchant_fees_report = 1;
      }
      var request = {
        report: self.transactionDetails,
        searchDate: self.searchDate,
        allow_fees: allow_merchant_fees_report
      };
      api
        .exportStoreTransactionDetails(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") +
              "_Storewise_Transaction_Details.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    dateFormatChange(date) {
      return moment
        .utc(date)
        .local()
        .format(" DD-M-YYYY");
    },
    checkNewReleases(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .checkNewReleases(request)
        .then(function (response) {
          if (response.code == 200) {
            if(response.data.length > 0){
              self.releaseNotes = response.data;
              var releaseTxt = '';
              self.releaseNotes.forEach(function(releaseNote,index) {
                if((index > 0 && releaseNote.tag!=self.releaseNotes[index - 1].tag) || index == 0){
                  releaseTxt+= '<h3 class="releasetagcls"># Release '+releaseNote.tag+' on '+self.dateFormatChange(releaseNote.created_at)+'</h3>';
                }
                releaseTxt+= '<ul class="todo-list ui-sortable" data-widget="todo-list">';
                releaseTxt+= '<li><p class="direct-chat-name">'+releaseNote.release_note+'</p><p class="release-note-description">'+releaseNote.release_note_description+'</p></li>';
                releaseTxt+= '</ul>';
              });
              self.$swal({
                html: releaseTxt,
                showConfirmButton: false,
              }).then(function () {
                self.readReleaseNotes();
              });
            }
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    readReleaseNotes(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .readReleaseNotes(request)
        .then(function (response) {
          if (response.code!= 200) {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    //get the autorized merchant list
    getAuthorizedStores() {
      var self = this;
      apiuser
        .getStores()
        .then(function (response) {
          if (response.code == 200) {
            self.storeList = response.data;
            } else {
            error(response.message);
            }
        })
        .catch(function (error) {
          error(error);
           });
    },
  },
};
</script>
<style>
.page-link {
  font-size: 14.88px;
  font-family: -apple-system,BlinkMacSystemFont;
}
</style>