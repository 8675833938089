const updateStoreDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/updatestoredetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getMerchantStoreApiKeys = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('merchantadmin/reports/getMerchantStoreApiKeys', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    updateStoreDetails,
    getMerchantStoreApiKeys
};