<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1 class="m-0">e-Commerce Dashboard</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="content">      
    <div class="container-fluid">   
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-success">
          <div class="card-header">
          <h3 class="card-title">Total Transaction To Expire</h3>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-12">
              <div class="info-box mb-3 bg-danger">
                  <span class="info-box-icon"><i class="fas fa-calendar-alt"></i></span>
                  <div class="info-box-content">
                      <span class="info-box-text" style="width:50%; float:left;">07-20-2022</span><span class="text-right" style="width:50%; float:right; font-weight:bold;">Today</span>
                      <span class="info-box-number" @click="$bvModal.show('store-details-modal')">#285</span>
                  </div>
              </div>
              </div><div class="col-md-4 col-sm-6 col-12">
              <div class="info-box mb-3 bg-primary">
                  <span class="info-box-icon"><i class="fas fa-calendar-alt"></i></span>
                  <div class="info-box-content">
                      <span class="info-box-text" style="width:50%; float:left;">07-21-2022</span><span class="text-right" style="width:50%; float:right; font-weight:bold;">Tomorrow</span>
                      <span class="info-box-number" @click="$bvModal.show('store-details-modal')">#356</span>
                  </div>
              </div>
              </div>
              <div class="col-md-4 col-sm-6 col-12">
              <div class="info-box mb-3 bg-warning">
                  <span class="info-box-icon"><i class="fas fa-calendar-alt"></i></span>
                  <div class="info-box-content">
                      <span class="info-box-text" style="width:50%; float:left;">07-22-2022</span><span class="text-right" style="width:50%; float:right; font-weight:bold;">Day After Tomorrow</span>
                      <span class="info-box-number" @click="$bvModal.show('store-details-modal')">#412</span>
                  </div>
              </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>


        <div class="row">
        <div class="col-12">
        <div class="card card-success">
        <div class="card-header">
        <h3 class="card-title">Search</h3></div>
        <div class="card-body">
        <div class="row">
        <div class="col-md-3">
        <div class="form-group">
        <select name="store" class="form-control">
        <option>Etain-Syracuse</option>
        </select>
        </div>
        </div>
        <div class="col-md-3">
        <div class="form-group">
        <input value="07-19-2022" onkeydown="return false" autocomplete="off" class="transaction-date form-control">
        </div>
        </div>
        <div class="col-md-3">
        <div class="form-group">
        <input value="07-20-2022" onkeydown="return false" autocomplete="off" class="transaction-date form-control"></div></div>
        <div class="col-md-3">
        <div class="form-group">
        <button type="button" class="btn btn-success"> Search </button></div></div>
        </div></div></div></div>

        </div>






        <div class="row">
          <div class="col-md-6">
            <div class="card">
            <div class="card-header border-0">
            <h3 class="card-title">Transaction Pending</h3>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreTransactionPending();">View more</a>
            </div>
            <div class="card-body table-responsive p-0">
            <table class="table table-striped table-valign-middle">
            <thead>
            <tr>
            <th>Transaction Number</th>
            <th>Transaction Date</th>
            <th>Amount</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>
            WQEHUREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            120
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Unpaid</td>
            <td class="text-center">
            </td>
            </tr>
            <tr>
            <td>
            WQEHUSDFUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            156
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Pending</td>
            <td class="text-center">
            <a href="#" class="text-muted">
            <button type="button" class="btn btn-success" style="padding-top:2px; padding-bottom:2px;"> Process to Bank </button>
            </a>
            </td>
            </tr>
            <tr>
            <td>
            SDERTREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            45
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Pending</td>
            <td class="text-center">
            <a href="#" class="text-muted">
            <button type="button" class="btn btn-success" style="padding-top:2px; padding-bottom:2px;"> Process to Bank </button>
            </a>
            </td>
            </tr>
            <tr>
            <td>
            ERTFGHEHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            78
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Pending</td>
            <td class="text-center">
            <a href="#" class="text-muted">
            <button type="button" class="btn btn-success" style="padding-top:2px; padding-bottom:2px;"> Process to Bank </button>
            </a>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
            <div class="card-header border-0">
            <h3 class="card-title">Consumer Approval Awaiting</h3>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreConsumerApprovalAwaiting();">View more</a>
            </div>
            <div class="card-body table-responsive p-0">
            <table class="table table-striped table-valign-middle td-pad-15">
            <thead>
                <tr>
                <th>Transaction Number</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Time Left</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                  <td>
                  WQEHUREHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-success mr-1">
                  <i class="fas fa-arrow-up"></i>
                  120
                  </small>
                  </td>
                  <td>16:21:28</td>
                  </tr>
                  <tr>
                  <td>
                  WQEHUSDFUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-danger mr-1">
                  <i class="fas fa-arrow-down"></i>
                  156
                  </small>
                  </td>
                  <td>14:12:27</td>
                  </tr>
                  <tr>
                  <td>
                  SDERTREHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-danger mr-1">
                  <i class="fas fa-arrow-down"></i>
                  45
                  </small>
                  </td>
                  <td>10:21:20</td>
                  </tr>
                  <tr>
                  <td>
                  ERTFGHEHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-success mr-1">
                  <i class="fas fa-arrow-up"></i>
                  78
                  </small>
                  </td>
                  <td>18:20:28</td>
                  </tr>
                </tbody>
                </table>
            </table>
            </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
            <div class="card-header border-0">
            <h3 class="card-title">Modification Accepted</h3>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreModificationAccepted();">View more</a>
            </div>
            <div class="card-body table-responsive p-0">
            <table class="table table-striped table-valign-middle td-pad-15">
            <thead>
            <tr>
            <th>Transaction Number</th>
            <th>Transaction Date</th>
            <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>
            WQEHUREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            120
            </small>
            </td>
            </tr>
            <tr>
            <td>
            WQEHUSDFUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            156
            </small>
            </td>
            </tr>
            <tr>
            <td>
            SDERTREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            45
            </small>
            </td>
            </tr>
            <tr>
            <td>
            ERTFGHEHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            78
            </small>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
            <div class="card-header border-0">
            <h3 class="card-title">Modification Rejected</h3>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreModificationRejected();">View more</a>
            </div>
            <div class="card-body table-responsive p-0">
            <table class="table table-striped table-valign-middle td-pad-15">
            <thead>
            <tr>
            <th>Transaction Number</th>
            <th>Transaction Date</th>
            <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>
            WQEHUREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            120
            </small>
            </td>
            </tr>
            <tr>
            <td>
            WQEHUSDFUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            156
            </small>
            </td>
            </tr>
            <tr>
            <td>
            SDERTREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            45
            </small>
            </td>
            </tr>
            <tr>
            <td>
            ERTFGHEHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            78
            </small>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    
    <!-------------------------------------- Store Details Modal ---------------------------------------->
    <b-modal
      id="store-details-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Transaction Details"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
        <div class="row">
          <div class="col-md-12">
            
            <table class="table table-striped table-valign-middle td-pad-15">
                <thead>
                <tr>
                <th>Store Name</th>
                <th class="text-center">Pending Transactions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                <td>
                <a href="#">Etain-NYC</a>
                </td>
                <td class="text-center">120</td>
                </tr>
                <tr>
                <td>
                <a href="#">Etain-Syracuse</a>
                </td>
                <td class="text-center">12</td>
                </tr>
                <tr>
                <td>
                <a href="#">Etain-Kingston</a>
                </td>
                <td class="text-center">15</td>
                </tr>
                <tr>
                <td>
                <a href="#">Etain-Yonkers</a>
                </td>
                <td class="text-center">138</td>
                </tr>
                </tbody>
                </table>
            
          </div>
        </div>
    </b-modal>

    <!-------------------------------------- Transaction Pending Modal ---------------------------------------->
    <b-modal
      id="view-more-transaction-pending-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Transaction Pending"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
        <div class="row">
          <div class="col-md-12">
            
            <table class="table table-striped table-valign-middle td-pad-15" id="transaction_pending">
                <thead>
                <tr>
                <th>Transaction Number</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Expiry Date</th>
                <th>Status</th>
                <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
            <td>
            WQEHUREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            120
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Unpaid</td>
            <td class="text-center">
            </td>
            </tr>
            <tr>
            <td>
            WQEHUSDFUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            156
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Pending</td>
            <td class="text-center">
            <a href="#" class="text-muted">
            <button type="button" class="btn btn-success" style="padding-top:2px; padding-bottom:2px;"> Process to Bank </button>
            </a>
            </td>
            </tr>
            <tr>
            <td>
            SDERTREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            45
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Pending</td>
            <td class="text-center">
            <a href="#" class="text-muted">
            <button type="button" class="btn btn-success" style="padding-top:2px; padding-bottom:2px;"> Process to Bank </button>
            </a>
            </td>
            </tr>
            <tr>
            <td>
            ERTFGHEHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            78
            </small>
            </td>
            <td>07-25-2022</td>
            <td>Pending</td>
            <td class="text-center">
            <a href="#" class="text-muted">
            <button type="button" class="btn btn-success" style="padding-top:2px; padding-bottom:2px;"> Process to Bank </button>
            </a>
            </td>
            </tr>
                </tbody>
                </table>
            
          </div>
        </div>
    </b-modal>

    <!-------------------------------------- Consumer Approval Awaiting Modal ---------------------------------------->
    <b-modal
      id="view-more-consumer-approval-awaiting-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Consumer Approval Awaiting"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
        <div class="row">
          <div class="col-md-12">
            
            <table class="table table-striped table-valign-middle td-pad-15" id="table1">
                <thead>
                <tr>
                <th>Transaction Number</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Time Left</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                  <td>
                  WQEHUREHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-success mr-1">
                  <i class="fas fa-arrow-up"></i>
                  120
                  </small>
                  </td>
                  <td>16:21:28</td>
                  </tr>
                  <tr>
                  <td>
                  WQEHUSDFUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-danger mr-1">
                  <i class="fas fa-arrow-down"></i>
                  156
                  </small>
                  </td>
                  <td>14:12:27</td>
                  </tr>
                  <tr>
                  <td>
                  SDERTREHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-danger mr-1">
                  <i class="fas fa-arrow-down"></i>
                  45
                  </small>
                  </td>
                  <td>10:21:20</td>
                  </tr>
                  <tr>
                  <td>
                  ERTFGHEHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-success mr-1">
                  <i class="fas fa-arrow-up"></i>
                  78
                  </small>
                  </td>
                  <td>18:20:28</td>
                  </tr>
                </tbody>
                </table>
            
          </div>
        </div>
    </b-modal>

    <!-------------------------------------- Modification Accepted Modal ---------------------------------------->
    <b-modal
      id="view-more-modification-accepted-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Modification Accepted"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
        <div class="row">
          <div class="col-md-12">
            
            <table class="table table-striped table-valign-middle td-pad-15" id="table2">
                <thead>
                <tr>
                <th>Transaction Number</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
            <td>
            WQEHUREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            120
            </small>
            </td>
            </tr>
            <tr>
            <td>
            WQEHUSDFUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            156
            </small>
            </td>
            </tr>
            <tr>
            <td>
            SDERTREHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-danger mr-1">
            <i class="fas fa-arrow-down"></i>
            45
            </small>
            </td>
            </tr>
            <tr>
            <td>
            ERTFGHEHUKA9
            </td>
            <td>07-20-2022</td>
            <td>
            <small class="text-success mr-1">
            <i class="fas fa-arrow-up"></i>
            78
            </small>
            </td>
            </tr>
            </tbody>
            </table>
          </div>
        </div>
    </b-modal>

    <!-------------------------------------- Modification Rejected Modal ---------------------------------------->
    <b-modal
      id="view-more-modification-rejected-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Modification Rejected"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
    >
        <div class="row">
          <div class="col-md-12">
            
            <table class="table table-striped table-valign-middle td-pad-15" id="table3">
                <thead>
                <tr>
                <th>Transaction Number</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                  <td>
                  WQEHUREHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-success mr-1">
                  <i class="fas fa-arrow-up"></i>
                  120
                  </small>
                  </td>
                  </tr>
                  <tr>
                  <td>
                  WQEHUSDFUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-danger mr-1">
                  <i class="fas fa-arrow-down"></i>
                  156
                  </small>
                  </td></td>
                  </tr>
                  <tr>
                  <td>
                  SDERTREHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-danger mr-1">
                  <i class="fas fa-arrow-down"></i>
                  45
                  </small>
                  </td>
                  </tr>
                  <tr>
                  <td>
                  ERTFGHEHUKA9
                  </td>
                  <td>07-20-2022</td>
                  <td>
                  <small class="text-success mr-1">
                  <i class="fas fa-arrow-up"></i>
                  78
                  </small>
                  </td>
                  </tr>
                </tbody>
                </table>
            
          </div>
        </div>
    </b-modal>
  </div>
</template>
<script>
import api from "@/api/reports.js";
import moment from "moment";
import { HourGlass } from "vue-loading-spinner";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      fields: [
        { key: "store_name", class: "text-center", sortable: "true" },
        { key: "total_sales", class: "text-center", sortable: "true" },
        { key: "total_transactions", class: "text-center", sortable: "true" },
        { key: "total_monthly_sales", class: "text-center", sortable: "true" },
        {
          key: "total_monthly_transaction",
          class: "text-center",
          sortable: "true",
        },
        {
          key: "avg_sales_over_the_last_seven_days",
          label: "Average sales (last 7 days)",
          class: "text-center",
          sortable: "true",
        },
        {
          key: "avg_ticket_cost",
          label: "Average ticket cost (last 7 days)",
          class: "text-center",
          sortable: "true",
        },
      ],
      headerTextVariant:"light",
      transactionDetails: [],
      total_sales:0,
      total_transactions:0,
      total_monthly_sales:0,
      total_monthly_transaction:0,
      avg_sales_over_the_last_seven_days:0,
      avg_ticket_cost:0,
      releaseNotes:{},
      loading: false,
      selected_date: moment($().val()).format("MM/DD/YYYY"),
      searchDate: moment($().val()).format("YYYY-MM-DD"),
    };
  },
  components: {
    HourGlass,
  },
  created() {
    //Fetch the Transaction details
    // this.getTransactionDetails();
    // this.checkNewReleases();
  },
  mounted() {
    var self = this;
    $("#select-date")
      .datepicker({
        format: "mm/dd/yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: new Date(),
      })
      .on("changeDate", function (e) {
        self.selected_date = e.format();
        self.searchDate = e.format("yyyy-mm-dd");
        //Fetch the Transaction details
        self.getTransactionDetails();
      });

      
  },
  methods: {
    // API call to generate the storewise Transaction Details
    getTransactionDetails() {
      var self = this;
      var request = {
        searchDate: self.searchDate,
      };
      self.transactionDetails = [];
      self.loading = true;
      api
        .getMerchantTransactionDetails(request)
        .then(function (response) {
          if (response.code == 200) {
            self.transactionDetails = response.data.transactions;
            //Populate the Boxes
            self.total_sales = "$" + response.data.total_sales;
            self.total_transactions = response.data.total_transactions;
            self.total_monthly_sales = "$" + response.data.total_monthly_sales;
            self.total_monthly_transaction =
              response.data.total_monthly_transaction;
            self.avg_sales_over_the_last_seven_days =
              "$" + response.data.avg_sales_over_the_last_seven_days;
            self.avg_ticket_cost = "$" + response.data.avg_ticket_cost;
            self.loading = false;
          } else {
            error(response.message);
            self.loading = false;
          }
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    exportStoreTransactionDetails(){
      var self = this;
      self.loading = true;
      var request = {
        report: self.transactionDetails,
        searchDate: self.searchDate,
      };
      api
        .exportStoreTransactionDetails(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") +
              "_Storewise_Transaction_Details.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
    dateFormatChange(date) {
      return moment
        .utc(date)
        .local()
        .format(" DD-M-YYYY");
    },
    checkNewReleases(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .checkNewReleases(request)
        .then(function (response) {
          if (response.code == 200) {
            if(response.data.length > 0){
              self.releaseNotes = response.data;
              var releaseTxt = '';
              self.releaseNotes.forEach(function(releaseNote,index) {
                if((index > 0 && releaseNote.tag!=self.releaseNotes[index - 1].tag) || index == 0){
                  releaseTxt+= '<h3 class="releasetagcls"># Release '+releaseNote.tag+' on '+self.dateFormatChange(releaseNote.created_at)+'</h3>';
                }
                releaseTxt+= '<ul class="todo-list ui-sortable" data-widget="todo-list">';
                releaseTxt+= '<li><p class="direct-chat-name">'+releaseNote.release_note+'</p><p class="release-note-description">'+releaseNote.release_note_description+'</p></li>';
                releaseTxt+= '</ul>';
              });
              self.$swal({
                html: releaseTxt,
                showConfirmButton: false,
              }).then(function () {
                self.readReleaseNotes();
              });
            }
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    readReleaseNotes(){
      var self = this;
      var request = {
        repo: 'Merchant Admin',
      };
      api
        .readReleaseNotes(request)
        .then(function (response) {
          if (response.code!= 200) {
            error(response.message);
          }
        })
        .catch(function (error) {
          // error(error);
        });
    },
    viewmoreTransactionPending(){
      var self = this;
      // Load Datatable
      setTimeout(function () {
        self.loadDT();
      }, 500);
      self.$bvModal.show('view-more-transaction-pending-modal');
    },
    viewmoreConsumerApprovalAwaiting(){
      var self = this;
      // Load Datatable
      setTimeout(function () {
        self.loadDT1();
      }, 500);
      self.$bvModal.show('view-more-consumer-approval-awaiting-modal');
    },
    viewmoreModificationAccepted(){
      var self = this;
      // Load Datatable
      setTimeout(function () {
        self.loadDT2();
      }, 500);
      self.$bvModal.show('view-more-modification-accepted-modal');
    },
    viewmoreModificationRejected(){
      var self = this;
      // Load Datatable
      setTimeout(function () {
        self.loadDT3();
      }, 500);
      self.$bvModal.show('view-more-modification-rejected-modal');
    },
    loadDT: function () {
      $('#transaction_pending').DataTable({
        "paging": true,
        "lengthChange": false,
        "searching": true,
        "ordering": false,
        "info": false,
        "autoWidth": false,
        "responsive": true,
      });
    },
    loadDT1: function () {
      $('#table1').DataTable({
        "paging": true,
        "lengthChange": false,
        "searching": true,
        "ordering": false,
        "info": false,
        "autoWidth": false,
        "responsive": true,
      });
    },
    loadDT2: function () {
      $('#table2').DataTable({
        "paging": true,
        "lengthChange": false,
        "searching": true,
        "ordering": false,
        "info": false,
        "autoWidth": false,
        "responsive": true,
      });
    },
    loadDT3: function () {
      $('#table3').DataTable({
        "paging": true,
        "lengthChange": false,
        "searching": true,
        "ordering": false,
        "info": false,
        "autoWidth": false,
        "responsive": true,
      });
    }
  },
};
</script>