<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Change Password</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>
                        Enter Current Password
                        <span class="red">*</span>
                      </label>
                      <input
                        type="password"
                        v-model="passwords.old_password"
                        placeholder="Enter Your Current Password"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>
                        New Password
                        <span class="red">*</span>
                      </label>
                      <input
                        type="password"
                        v-model="passwords.new_password"
                        placeholder="Enter New Password"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>
                        Confirm New Password
                        <span class="red">*</span>
                      </label>
                      <input
                        type="password"
                        placeholder="Re Enter New Password"
                        v-model="passwords.confirm_password"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="button" @click="changePassword" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <div class="loading" v-if="loading">
      <hour-glass></hour-glass>
    </div>
    <section class="content" v-if="(user.role_name === constants.role_new_remotepay_manager) && pinResetShow">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Reset Login PIN</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group" style="text-align: center;" v-if="!lastPinResetDate">
                      <button type="button" @click="resetLoginPin" class="btn btn-success">Reset Login PIN</button>
                    </div>
                    <div class="form-group" style="text-align: center;" v-else>
                      <label>PIN can be changed after {{ lastPinResetDate }}. If you want to reset PIN please connect with Corporate Parents.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/user.js";
import Constants from "@/common/constant.js";
import { HourGlass } from "vue-loading-spinner";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("store_user")),
      constants: Constants,
      lastPinResetDate: true,
      pinResetShow: false,
      loading: false,
      passwords: {
        old_password: "",
        new_password: "",
        confirm_password: ""
      }
    };
  },
  components: {
    HourGlass,
  },
  methods: {
    checkValidation() {
      let self = this;
      if (
        self.passwords.old_password == "" ||
        self.passwords.new_password == "" ||
        self.passwords.confirm_password == ""
      ) {
        error("Please fill in the required fields.");
        return false;
      }
      if (self.passwords.new_password != self.passwords.confirm_password) {
        error("Passwords does not match.");
        return false;
      }
      return true;
    },
    changePassword() {
      let self = this;
      //update profile
      if (self.checkValidation()) {
        console.log(self.passwords);
        api
          .changePassword(self.passwords)
          .then(response => {
            if (response.code == 200) {
              success('Password changed successfully');
              self.passwords = {
                old_password: "",
                new_password: "",
                confirm_password: ""
              };
            } else {
              error(response.message);
            }
          })
          .catch(err => {
            error(err);
          });
      }
    },
    lastPinResetData() {
      let self = this;
      self.loading = true;
      api
        .lastPinResetData()
        .then(response => {
          if (response.code == 200) {
            self.pinResetShow = true;
            self.lastPinResetDate = response.data;
          } else {
            error(response.message);
          }
          self.loading = false;
        })
        .catch(err => {
          error(err);
          self.loading = false;
        });
    },
    resetLoginPin() {
      let self = this;
      api
        .resetLoginPin()
        .then(response => {
          if (response.code == 200) {
            success('PIN reset mail send successfully');
            self.lastPinResetData();
          } else {
            error(response.message);
          }
        })
        .catch(err => {
          error(err);
        });
    }
  },
  mounted() {
    var self = this;
    if(self.user.role_name === self.constants.role_new_remotepay_manager){
      self.lastPinResetData();
    }
  }
};
</script>
