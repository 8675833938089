<template>
    <div>
        <span 
        class="badge badge-success d-flex align-items-center justify-content-center px-3"
        >
        {{assignedStore.retailer}}
        <button v-if="canRemove" @click="removeStore(assignedStore)" type="button" class="assign-cancel-btn ml-2">✖</button>
        </span>


         <!----- MODAL FOR REMOVE CONFIRMATION---->
        <div>
        <b-modal ref="remove-confirm-modal" hide-footer hide-header id="remove-confirm-modal">
            <div class="color">
            <div class="col-12 text-center">
                <svg width="100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 473 473" style="enable-background:new 0 0 473 473;" xml:space="preserve">
                <g>
                <path fill="#149240" d="M317.667,214.42l5.667-86.42h20.951V38h-98.384V0H132.669v38H34.285v90h20.951l20,305h140.571
                c23.578,24.635,56.766,40,93.478,40c71.368,0,129.43-58.062,129.43-129.43C438.715,275.019,385.143,218.755,317.667,214.42z
                M162.669,30h53.232v8h-53.232V30z M64.285,68h250v30h-250V68z M103.334,403L85.301,128H293.27l-5.77,87.985
                c-61.031,10.388-107.645,63.642-107.645,127.586c0,21.411,5.231,41.622,14.475,59.43H103.334z M309.285,443
                c-54.826,0-99.43-44.604-99.43-99.43s44.604-99.429,99.43-99.429s99.43,44.604,99.43,99.429S364.111,443,309.285,443z"/>
                <polygon fill="#149240" points="342.248,289.395 309.285,322.358 276.322,289.395 255.109,310.608 288.072,343.571 255.109,376.533 
                276.322,397.746 309.285,364.783 342.248,397.746 363.461,376.533 330.498,343.571 363.461,310.608 	"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
            </div>
            <div class="purchaserpower-modal-text">
                <div class="d-block text-center">
                <label class="text-justify text-secondary h4">
                    Are you sure ?
                </label>
                <br />
                <label class="text-justify text-secondary text-dark">
                    Do you want to remove this store?
                </label>
                </div>
                <div class="row">
                <div class="col-12 text-center">
                    <button
                    @click="hideModal('remove-confirm-modal')"
                    class="btn btn-secondary btn-md center-block mr-2"
                    >
                    <label class="forgetpassword-ok-label mb-0">Cancel</label>
                    </button>
                    <button
                    @click="removeStoreConfirm()"
                    class="btn btn-success btn-md center-block ml-2"
                    >
                    <label class="forgetpassword-ok-label mb-0">Confirm</label>
                    </button>
                </div>
                </div>
            </div>
            </div>
        </b-modal>
        </div>
    </div>
</template>

<script>
import api from "@/api/holidaymaster.js";

export default {
    props:{
        assignedStore:{
            type: Object,
            default: {}
        },
        holidayId:{
            type: String,
            default: ""
        },
        canRemove:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return{
        }
    },
    methods:{
        removeStore(prop){
            var self = this;
            self.$bvModal.show("remove-confirm-modal")
        },
        hideModal(val) {
            this.$bvModal.hide(val);
        },
        removeStoreConfirm() {
            var self = this;
            var request = {
                store_id: self.assignedStore.id,
                holiday_id: self.holidayId,
            };
            api
                .removeStoreFromHoliday(request)
                .then((response) => {
                if ((response.code = 200)) {
                    success("Store removed successfully.");
                    self.$bvModal.hide("remove-confirm-modal");
                    self.$bvModal.hide("assign-template-modal");
                    self.$bvModal.hide("assign-holiday-modal");
                    $("#holidayMasterTable").DataTable().ajax.reload(null, false);
                } else {
                    error(response.message);
                }
                })
                .catch((err) => {
                error(err);
                });
        
        },
    }
}
</script>

<style scoped>
.assign-cancel-btn{
    background: white;
    border: 0;
    width: 20px;
    height: 20px;
    font-size: 6px;
    border-radius: 100%;
}
</style>