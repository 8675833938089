<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">RemotePay Transaction History</h3>
                  <b-button
                  class="btn-danger export-api-btn"
                  @click="reloadDatatable"
                  v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <div class="loading" v-if="loading">
                  <hour-glass></hour-glass>
                </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storeList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                        @select="hideRefreshBtn()"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <select
                        class="form-control"
                        @change="changeStatus($event)"
                        id="user_status"
                        name="user_status"
                        v-model="search_type"
                        v-validate="'required'"
                      >
                        <option
                          v-for="(status, index) in statusList"
                          :key="index"
                          :value="status.name"
                        >
                          {{ status.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="enable_settle_toggle">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="switch"><input class="enable-employee-login" type="checkbox" v-model="toggle_settlement_date_search"><span class="slider round"></span></label> Settlement Date
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div  :class="checkSendToBankCheckbox ? 'col-md-6' : 'col-md-3'">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="generateReport(false)"
                    >
                      Generate
                    </button>
                    <button
                      type="button"
                      @click="generateReport(true)"
                      class="btn btn-danger ml-10"
                    >
                      Export <i
                        class="fa fa-download ml-10"
                      ></i>
                    </button>
                    <button
                      type="button"
                      @click="allSendToBank()"
                      class="btn btn-primary ml-10"
                      v-if="checkSendToBankCheckbox"
                    >
                      Selected Item Accept Payment
                    </button> 
                  </div>
                  <div :class="checkSendToBankCheckbox ? 'col-md-3' : 'col-md-6'">
                    <h6 class="mr-4 mt-2" v-if="showRefreshBtn && enable_settle_toggle" style="text-align: center;"> Total Settled {{ recordsTotal > 1 ? 'Transactions' : 'Transaction' }} {{ recordsTotal }}, Total Settled Amount ${{ recordsTotalAmount }}</h6> 
                  </div>
                  <div class="col-md-3">
                    <button v-if="showRefreshBtn" type="button" class="btn btn-info mr-3" style="float: right;" @click="reloadDatatable()"> <i class="fas fa-redo"></i> Refresh</button>
                  </div>
                </div>
              </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="transactionTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th width="4%">
                          <label class="form-checkbox" v-if="checkAllSelect">
                            <input type="checkbox" v-model="selectedAll" @click="selectAll">
                            <i class="form-icon"></i>
                          </label>
                        </th>
                        <th width="10%">Transaction Number</th>
                        <th width="7%">Store</th>
                        <th width="12%">Amount ($)</th>
                        <th width="12%">Tip Amount ($)</th>
                        <th width="15%">Transaction Time</th>
                        <th width="15%">Settlement Date</th>
                        <th width="10%">Status <a @click="showModal('status-help-modal')" style="color: #000;" href="javascript:void(0)"><i class="far fa-question-circle"></i></a></th>
                        <th width="10%">Action <a @click="showModal('action-help-modal')" style="color: #000;" href="javascript:void(0)"><i class="far fa-question-circle"></i></a></th>
                        <th width="10%">Void Transaction <a @click="showModal('void-help-modal')" style="color: #000;" href="javascript:void(0)"><i class="far fa-question-circle"></i></a></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Transaction cancellation comment modal start -->
    <b-modal
      id="comment-modal"
      ref="comment-modal"
      ok-title="Save & Cancel"
      cancel-title="Close"
      ok-variant="success"
      @ok="cancelTransaction"
      cancel-variant="outline-secondary"
      hide-header
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <label for="comment">
            Tell us why you want to cancel this transaction
            <span class="red">*</span>
          </label>
          <textarea
            name="comment"
            type="text"
            v-model="comment"
            class="form-control"
          />
        </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>
      <div class="row" v-if="showMsg">
        <div class="col-12">
          <label for="comment" class="red"
            >Please fill in the required fields.</label
          >
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction cancellation comment modal end -->
    <!-- Transaction modification modal step 1 start -->
    <b-modal
      id="modification-modal"
      ref="modification-modal"
      ok-title="Modify"
      cancel-title="Close"
      ok-variant="success"
      @close="hideModal('modification-modal')"
      @cancel="hideModal('modification-modal')"
      @ok="modifyTransactionStep1"
      cancel-variant="outline-secondary"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Select Modification Type <span class="red">*</span></label>&nbsp;<br />
              <input type="radio" name="selectedReasonType" id="radio1" value="increase" @change="selecteReasonType($event)" v-model="selectedReasonType">&nbsp;Increase &nbsp;&nbsp;
              <input type="radio" name="selectedReasonType" id="radio2" value="decrease" @change="selecteReasonType($event)" v-model="selectedReasonType">&nbsp;Decrease<br />
              <span class="red" v-if="reasonTypeSelectError" style="">{{reasonTypeSelectErrorMsg}}</span>
            </div>
          </div>
          <div class="col-6">
            <label for="reason">
              Select Modification Reason
              <span class="red">*</span>
            </label>
            <multiselect
              id="reason"
              name="reason"
              v-model="selectedReason"
              placeholder="Select Reason"
              label="reason"
              track-by="id"
              :options="modificationReasonList"
              @select="selecteReason"
              :multiple="false"
              :searchable="true"
              :show-labels="false"
            ></multiselect>
            <span class="red" v-if="reasonSelectError" style="">{{reasonSelectErrorMsg}}</span>
          </div>
      </div>     
      <br>
      <div class="row">
          
          <div class="loading" v-if="modalloading">
            <hour-glass></hour-glass>
          </div>
          <div class="col-6">
            <label for="modification">
              Current Payment Amount($)
            </label>
            <input
              name="tr_amount"
              type="text"
              readonly
              v-model="tr_amount"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
          </div>
          <div class="col-6">
            <label for="modification">
              Updated Payment Amount($)
              <span class="red">*</span>
            </label>
            <input
              @input="onKeyupUpdatedAmount"
              name="updated_amount"
              type="text"
              v-model="updated_amount"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
            <span class="red" v-if="UpAmtMsg" style="">{{UpAmtErrorMsg}}</span>
          </div>
      </div>
      <br> 
      <div class="row">
          <div class="col-6" v-if="tip_amount > 0">
            <label for="modification">
              Tip Amount($)
            </label>
            <input
              name="tip_amount"
              type="text"
              readonly
              v-model="tip_amount"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
          </div>
          <div class="col-6" v-if="reasonSelectOther">
            <label for="reason_text">
              Provide the reason you are modifying this payment
              <span class="red"  v-if="reasonSelectOther">*</span>
            </label>
            <textarea
              name="reason_text"
              type="text"
              v-model="reasonComment"
              class="form-control"
              style="height: 70px;resize: none;" 
            />
            <span class="red" v-if="reasonCommentError" style="">{{reasonCommentErrorMsg}}</span>
          </div>
          <div class="col-6">
            <label for="modification">
              Status
            </label>
            <input
              type="text"
              readonly
              v-model="modification_status"
              class="form-control"
            />
          </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>      
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction modification modal step 1 end -->
    <!-- Transaction modification modal step 2 start -->
    <b-modal
      id="modification-modal-step-2"
      ref="modification-modal-step-2"
      hide-footer 
      hide-header
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg width="100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
            <g id="XMLID_23_">
            <path fill="#149240" id="XMLID_24_" d="M75,180v60c0,8.284,6.716,15,15,15h60c3.978,0,7.793-1.581,10.606-4.394l164.999-165
            c5.858-5.858,5.858-15.355,0-21.213l-60-60C262.794,1.581,258.978,0,255,0s-7.794,1.581-10.606,4.394l-165,165
            C76.58,172.206,75,176.022,75,180z M105,186.213L255,36.213L293.787,75l-150,150H105V186.213z"/>
            <path fill="#149240" id="XMLID_27_" d="M315,150.001c-8.284,0-15,6.716-15,15V300H30V30H165c8.284,0,15-6.716,15-15s-6.716-15-15-15H15
            C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V165.001C330,156.716,323.284,150.001,315,150.001z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="text-justify text-secondary h4">
                Are you sure ?
            </label>
            <br />
            <label class="text-justify text-secondary text-dark">
                {{modifyTransactionMessage}}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
                <button
                @click="hideModal('modification-modal-step-2')"
                class="btn btn-secondary btn-md center-block mr-2"
                >
                <label class="forgetpassword-ok-label mb-0">Cancel</label>
                </button>
                <button
                @click="modifyTransaction()"
                class="btn btn-success btn-md center-block ml-2"
                >
                <label class="forgetpassword-ok-label mb-0">{{ModifyButton}}</label>
                </button>
            </div>
          </div>
          </div>
      </div>      
    </b-modal>
    <!-- Transaction modification modal step 2 end -->
    <!-- Transaction modification history modal start -->
    <b-modal
      id="modified-tr-history-modal"
      ref="modified-tr-history-modal"
      ok-only
      cancel-variant="outline-secondary"
      :header-text-variant="headerTextVariant"
      :title="historyModalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
          <div class="col-md-12">
            <b-table-simple
            class="cp-table"
            responsive
            show-empty
            bordered
            >
                <b-thead head-variant="light">
                    <tr>
                        <th width="15%">Amount ($)</th>
                        <th width="20%">Tip Amount ($)</th>
                        <th width="20%">Modify Time</th>
                        <th width="20%">Reason</th>
                        <th width="20%">Additional Reason</th>
                        <th width="15%">Status</th>
                    </tr>
                </b-thead>
                <b-tbody v-for="(row, index) in transactionHistory" :key="index">
                   <b-tr>
                          <b-td class="text-left text-gray">${{
                            row.amount
                          }}</b-td>
                          <b-td class="text-left text-gray">${{
                            row.tip_amount
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.local_transaction_time
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.reason
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.additional_reason
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.status
                          }}</b-td>
                        </b-tr>
                </b-tbody>
            </b-table-simple>
          </div>
        </div>
    </b-modal>
    <!-- Transaction modification history modal end -->
    <!-- Transaction Post to bank modal start -->
    <b-modal
      id="post-to-bank-model"
      ref="post-to-bank-model"
      hide-footer 
      hide-header
    >      
      <div class="color">
        <div class="col-12 text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-send" width="120" height="120" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" style="color: rgb(20, 146, 64);">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="10" y1="14" x2="21" y2="3"></line>
            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="text-justify text-secondary h4">
                Are you sure ?
            </label>
            <br />
            <label class="text-justify text-secondary text-dark">
                {{sendToBankModalMsg}}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
                <button
                @click="hideModal('post-to-bank-model')"
                class="btn btn-secondary btn-md center-block mr-2"
                >
                <label class="forgetpassword-ok-label mb-0">Cancel</label>
                </button>
                <button
                @click="postToBank()"
                class="btn btn-success btn-md center-block ml-2"
                >
                <label class="forgetpassword-ok-label mb-0">Confirm</label>
                </button>
            </div>
          </div>
          </div>
      </div>   
    </b-modal>
    <!-- Transaction Post to bank modal end -->
    <!-- resend modification modal start -->
    <b-modal
      id="re-send-model"
      ref="re-send-model"
      hide-footer 
      hide-header
    >      
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="text-justify text-secondary h4">
                Are you sure ?
            </label>
            <br />
            <label class="text-justify text-secondary text-dark">
                {{reSendModalMsg}}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
                <button
                @click="hideModal('re-send-model')"
                class="btn btn-secondary btn-md center-block mr-2"
                >
                <label class="forgetpassword-ok-label mb-0">Cancel</label>
                </button>
                <button
                @click="reSendModification()"
                class="btn btn-success btn-md center-block ml-2"
                >
                <label class="forgetpassword-ok-label mb-0">Confirm</label>
                </button>
            </div>
          </div>
          </div>
      </div>   
    </b-modal>
    <!-- resend modification modal end -->

    <!-- Status Help Text Modal -->
    <b-modal
      id="status-help-modal"
      ref="status-help-modal"
      cancel-variant="outline-secondary"
      hide-header
      hide-footer
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <h5>Status Description</h5>
        </div>
        <div class="col-12">
          <ul>
            <li class="mb-2"><strong>Request Timeout:</strong> Modification time expired</li>
            <li class="mb-2"><strong>Approved:</strong> When consumer accept modification</li>
            <li class="mb-2"><strong>Auto Approved:</strong> When Merchent admin decrease amount</li>
            <li class="mb-2"><strong>Awaiting Consumer Approval:</strong> Modification request sent to consumer</li>
            <li class="mb-2"><strong>Pending:</strong> Pending for settlement</li>
            <li class="mb-2"><strong>Voided:</strong> Canceled</li>
            <li class="mb-2"><strong>Consumer Declined:</strong> When consumer reject</li>
            <li class="mb-2"><strong>Expire:</strong> Admin driven not post to bank</li>
            <li class="mb-2"><strong>Queued for Processing:</strong> Payment accepted for settlement</li>
            <li><strong>Return:</strong> Transaction return</li>
          </ul>
        </div>
        <div class="col-12 row justify-center m-0">
          <button class="btn btn-success w-100" @click="hideModal('status-help-modal')">Okay</button>
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Status Help Text Modal -->

    <!-- Action Help Text Modal -->
    <b-modal
      id="action-help-modal"
      ref="action-help-modal"
      cancel-variant="outline-secondary"
      hide-header
      hide-footer
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <h5>Action Description</h5>
        </div>
        <div class="col-12">
          <ul>
            <li class="mb-2"><strong>Modify:</strong> Use for transaction amount update</li>
            <li class="mb-2"><strong>Re-Send:</strong> Use for Re-Send modification request again</li>
            <li class="mb-2"><strong>Accept Payment:</strong> Use for accepting the transaction</li>
          </ul>
        </div>
        <div class="col-12 row justify-center m-0">
          <button class="btn btn-success w-100" @click="hideModal('action-help-modal')">Okay</button>
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Action Help Text Modal -->

    <!-- Void Help Text Modal -->
    <b-modal
      id="void-help-modal"
      ref="void-help-modal"
      cancel-variant="outline-secondary"
      hide-header
      hide-footer
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <h5>Void Description</h5>
        </div>
        <div class="col-12">
          <ul>
            <li class="mb-2"><strong>Void:</strong> Use for cancel transaction</li>
          </ul>
        </div>
        <div class="col-12 row justify-center m-0">
          <button class="btn btn-success w-100" @click="hideModal('void-help-modal')">Okay</button>
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Void Help Text Modal -->
  </div>
</template>
<script>
import api from "@/api/transaction.js";
import user_api from "@/api/user.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
import { HourGlass } from "vue-loading-spinner";
import moment from "moment";
export default {
  mixins: [validationMixin],
  data() {
    return {
      allTransactionModel: {},
      loading: false,
      modalloading: false,
      showMsg: false,
      UpAmtMsg: false,
      OnlyNumMsg: false,
      transaction_id: null,
      tr_amount: null,
      tip_amount: null,
      comment: "",
      updated_amount: '',
      storeList: [],
      transactionHistory: [],
      modificationReasonList: [],
      selectedReason: '',
      reasonSelectError: false,
      reasonSelectErrorMsg: '',
      reasonComment: '',
      reasonCommentError: false,
      reasonCommentErrorMsg: '',
      reasonSelectOther: false,
      selectedTransactionIds: [],
      checkAllSelect: false,
      checkSendToBankCheckbox: false,
      allSendToBankClick: false,
      selectedAll: '',
      selectedStore: "",
      from_date: "",
      to_date: "",
      ModifyButton: "",
      modifyTransactionMessage: "",
      sendToBankModalMsg: "",
      reSendModalMsg: "",
      currentUser: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      showReloadBtn:false,
      showRefreshBtn:false,
      constants: Constants,
      headerTextVariant: "light",
      modalTitle: "Modify Payment Amount",
      historyModalTitle: "Transaction history",
      statusTooltip: {
        'Approved' : 'Consumer approved.',
        'Awaiting Consumer Approval' : 'Awaiting for consumer approval.',
        'Consumer Declined' : 'Consumer declined last modification request.',
        'Settled' : 'Already sent to bank for settlement.',
        'Unpaid' : 'Due to low perchech power transaction goes to unpaid.',
        'Pending' : 'Pending for settlement.',
        'Voided' : 'Transaction voided.',
      },
      selectedReasonType : '',
      modificationReasonTypeList : 
      [
        { 'id': 'increase', 'name': 'Increase' },
        { 'id': 'decrease', 'name': 'Decrease' }
      ],
      reasonTypeSelectError : false,
      reasonTypeSelectErrorMsg : '',
      user: JSON.parse(localStorage.getItem("store_user")),
      statusList: [
        { 'label': 'Pending', 'name': 'pending' },
        { 'label': 'Settled', 'name': 'settled' },
        { 'label': 'Voided', 'name': 'voided' }
      ],
      search_type: 'pending',
      enable_settle_toggle: false,
      toggle_settlement_date_search: false,
      orderable_columns: [0],
      dtLeftColumns: [1,2,3,4,5],
      dtCenterColumns: [0,7,8,9],
      hidColumns: [6],
      is_ecommerce_admin_driven: '',
      current_date: moment().format("MM-DD-YYYY"),
      modification_status: '',
      recordsTotal: 0,
      recordsTotalAmount: 0,
    };
  },
  components: {
    HourGlass,
  },
  created() {
    this.ecommercePendingLeaveComment();
  },
  methods: {
    hideModal(val) {
      this.$bvModal.hide(val);
      if (localStorage.getItem("transaction_modification_id")) {
        localStorage.removeItem("transaction_modification_id");
      }
    },
    showModal(val) {
      this.$bvModal.show(val);
      return false
    },
    selectAll(){
      var self = this;
     
      self.selectedTransactionIds = [];
			if (!self.selectedAll) {
				for (let i in self.allTransactionModel) {
          if(self.allTransactionModel[i].post_to_bank == 1){
					  self.selectedTransactionIds.push(self.allTransactionModel[i].edit);
          }
				}
        $(".indivisual-checkbox").prop('checked', true);
        self.checkSendToBankCheckbox = true;
			}else{
        $(".indivisual-checkbox").prop('checked', false);
        self.checkSendToBankCheckbox = false;
      }
    },
    reloadDatatable(){
      var self = this;
      self.loadDT();
    },
    getStores() {
      var self = this;
      self.loading = true;
      let request = {
        params: {
          is_ecommerce: 1
          }
      };
      user_api
        .getStores(request)
        .then((response) => {
          if ((response.code == 200)) {
            self.storeList = response.data;
          } else {
            error(response.message);
          }
          self.loading = false;
        })
        .catch((err) => {
          self.loading = false;
          error(err.response.data.message);
        });
    },
    loadDT: function () {
      var self = this;
      self.checkSendToBankCheckbox = false;
      self.selectedAll = false;
      self.selectedTransactionIds = [];
      var dataString = {
        _token: "{{csrf_token()}}",
        from_date: self.from_date,
        to_date: self.to_date,
        store_id: self.selectedStore.id,
        search_type: self.search_type,
        is_ecommerce: 1,
      };
      
      
      $('#transactionTable').DataTable().destroy();
      if(self.search_type == 'settled'){
        dataString.toggle_settlement_date_search = self.toggle_settlement_date_search;
      }
      var order = [[5, "desc"]];
      $("#transactionTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        scrollY: 500,
        scrollX: true,
        columnDefs: [
          { 'visible': false, 'targets': self.hidColumns },
          { orderable: false, targets: self.orderable_columns },
          { className: "dt-left", targets: self.dtLeftColumns },
          { className: "dt-center", targets: self.dtCenterColumns },
        ],
        order: order,
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Transactions Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchant/gettransactions",
          type: "POST",
          data: dataString,
          dataType: "json",
          dataSrc: function (result) {
            self.is_ecommerce_admin_driven = result.is_ecommerce_admin_driven;
            self.showReloadBtn = false;
            self.showRefreshBtn = true;
            self.checkAllSelect = false;
            self.allTransactionModel = result.data;
            self.recordsTotal = result.recordsTotal;
            self.recordsTotalAmount = result.recordsTotalAmount;
            return self.allTransactionModel;
          },
          error: function (request, status, error) {
            self.checkAllSelect = false;
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              console.log(Constants.datatable_error);
              error(Constants.datatable_error);
              $('#transactionTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          {
            render: function (data, type, full, meta) {
              if(full.post_to_bank == 1  && self.is_ecommerce_admin_driven == 1 && self.current_date <= full.expiry_date_for_book_now){
                self.checkAllSelect = true;
                return ('<label class="form-checkbox"><input class="indivisual-checkbox" type="checkbox" data-id="'+full.edit+'"></label>');
              }else{
                return "";
              }
            },
          },
          { data: "transaction_number" },
          { data: "store_name" },
          {
            render: function (data, type, full, meta) {
              if (full.attempt_count > 0) {
                var amount_str = '$' + full.amount + ' ... ';
                amount_str =amount_str + '$' +full.updated_amount;
                return amount_str + (
                  ' <b-button data-tr-number="' +full.transaction_number +'" data-id="' + full.edit +'" class="pendingviewModifiedTransaction custom-edit-btn" title="View All Modified Transaction" variant="outline-success"><i class="nav-icon fas fa-eye"></i></b-button>'
                );
              } else {
                return '$' + full.amount;
              }
            },
          },
          {
            render: function (data, type, full, meta) {
              return '$' + full.last_approve_tip_amount;
            },
          },
          { data: "transaction_time" },
          { data: "scheduled_posting_date" },
          {
            render: function (data, type, full, meta) {
              return (
                  '<div class="cp-tooltip">'+full.status.substr(0, 5)+'...\
                    <span class="cp-tooltiptext">'+full.status+'</span>\
                  </div>'
                );
            },
          },
          {
            render: function (data, type, full, meta) {
              if(full.post_to_bank == 1 && self.is_ecommerce_admin_driven == 1 && self.current_date <= full.expiry_date_for_book_now){
                var $post_to_bank_bt = ' <a style="color: white !important; margin-bottom: 2px;" class="btn btn-primary pending-post-to-bank mb-2" data-tr-number="' +full.transaction_number +'" data-id="' +full.edit +'">Accept Payment</a>';
              }else{
                var $post_to_bank_bt = '';
              }
              if(full.updated_amount > 0 && full.consumer_approval_for_change_request == 0 && ['Awaiting Consumer Approval', 'Declined By Consumer', 'Request Timeout'].includes(full.status) && (self.current_date <= full.expiry_date_for_book_now || full.expiry_date_for_book_now == '')){
                var $re_send_bt = ' <a style="color: white !important; margin-bottom: 2px;" class="btn btn-info re-send mb-2" data-tr-number="' +full.transaction_number +'" data-id="' +full.edit +'">Re-Send</a>';
              }else{
                var $re_send_bt = '';
              }
              if (full.consumer_approval_for_change_request == 0 && (self.current_date <= full.expiry_date_for_book_now || full.expiry_date_for_book_now == '')) {
                var $modify_bt = '<a style="color: white !important; margin-bottom: 2px;" class="btn btn-success ecommerce-leave-modify mb-2" data-tr-number="' +full.transaction_number +'" data-tr-amount="' +full.last_approve_amount +'" data-tr-tip-amount="'+full.last_approve_tip_amount+'" data-id="' +full.edit +'" data-tr-status="'+ full.status +'"><i class="fa fa-edit" aria-hidden="true"></i> Modify</a>';
              }
              else {
                var $modify_bt = '';
              }
              return '<div class="d-flex flex-column">' + $modify_bt + $re_send_bt + $post_to_bank_bt + '</div>'
            },
          },
          {
            render: function (data, type, full, meta) {
              if (full.cancelable == 1 && full.is_v1 == 0 && full.status != 'Voided' && full.status != 'Decline By Consumer' && (self.current_date <= full.expiry_date_for_book_now || full.expiry_date_for_book_now == '')) {
                return (
                  '<a style="color: white !important" class="btn btn-danger ecommerce-leave-comment" data-id="' +
                  full.edit +
                  '"><i class="fa fa-times fa-lg"></i> Void</a>'
                );
              }
              else {
                return null;
              }
            },
          },
          
        ],
      });

      $("#transactionTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#transactionTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
    ecommercePendingLeaveComment() {
      var self = this;
      $(document).on("click", ".ecommerce-leave-comment", function (e) {
        //open the modal
        self.comment = "";
        self.$bvModal.show("comment-modal");
        //set transaction id to a hidden field for future use
        self.transaction_id = $(e.currentTarget).attr("data-id");
        
      });
      // modify transaction
      $(document).on("click", ".ecommerce-leave-modify", function (e) {
        //open the modal
        self.updated_amount = "";
        self.selectedReason = "";
        self.selectedReasonType = "";
        self.reasonSelectOther = false;
        self.reasonComment = '';
        self.resetModificationDAta();
        self.modificationReasonList = [];
        self.transaction_id = $(e.currentTarget).attr("data-id");
        self.modalTitle = "Modify Payment Amount for " + $(e.currentTarget).attr("data-tr-number");
        self.tr_amount = parseFloat($(e.currentTarget).attr("data-tr-amount")).toFixed(2);
        self.tip_amount = parseFloat($(e.currentTarget).attr("data-tr-tip-amount")).toFixed(2);
        self.modification_status = $(e.currentTarget).attr("data-tr-status");
        self.$bvModal.show("modification-modal");
        //set transaction id to a hidden field for future use

      });
      // modify transaction history
      $(document).on("click", ".pendingviewModifiedTransaction", function (e) {
        //open the modal
        self.transactionHistory = [];
        self.transaction_id = $(e.currentTarget).attr("data-id");
        self.historyModalTitle = "Transaction history for " + $(e.currentTarget).attr("data-tr-number");
        self.modifiedTransactionHistory();
      });
      // post to bank click
      $(document).on("click", ".pending-post-to-bank", function (e) {
        //open the modal
        self.transaction_id = $(e.currentTarget).attr("data-id");
        self.sendToBankModalMsg = 'Do you want to post this transaction to the bank?';
        self.$bvModal.show("post-to-bank-model");

      });
      // re send modification 
      $(document).on("click", ".re-send", function (e) {
        //open the modal
        self.transaction_id = $(e.currentTarget).attr("data-id");
        self.reSendModalMsg = 'Do you want to re send modification request?';
        self.$bvModal.show("re-send-model");

      });
      $(document).on("keypress", ".numberonly", function (e) {
        var keyCode = e.which ? e.which : e.keyCode
        if ((keyCode != 46 || $(this).val().indexOf('.') != -1) && (keyCode < 48 || keyCode > 57)) {
          self.UpAmtMsg = true;
          self.UpAmtErrorMsg = 'Please fill in the required fields.';
          return false;
        } else {
          self.UpAmtMsg = false;
          return true;
        }
      });
      $(document).on("change", ".indivisual-checkbox", function (e) {
        var selectId = $(e.currentTarget).attr("data-id");
        if(this.checked){
            self.selectedTransactionIds.push(selectId);
        }else{
          self.selectedTransactionIds.pop(selectId);
        }
        if(self.selectedTransactionIds.length == 0){
          self.selectedAll = false;
          self.checkSendToBankCheckbox = false;
        }else{
          self.checkSendToBankCheckbox = true;
        }
      })
    },
    cancelTransaction(bvModalEvt) {
      var self = this;
      if (self.comment == "") {
        self.showMsg = true;
        bvModalEvt.preventDefault();
      } else {
        self.showMsg = false;
        var request = {
          transaction_id: self.transaction_id,
          comment: self.comment,
          transaction_place: "Web",
        };
        self.loading = true;
        api
          .cancelTransaction(request)
          .then((response) => {
            self.loading = false;
            if ((response.code == 200)) {
              success(response.message);
              self.hideModal('comment-modal');
              self.comment == "";
              self.loadDT();
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            self.loading = false;
            error(err);
          });
      }
    },
    resetModificationDAta(){
      var self = this;
      self.UpAmtMsg = false;
      self.reasonSelectError = false;
      self.reasonTypeSelectError = false;
      self.reasonCommentError = false;
      self.UpAmtErrorMsg = '';
      self.reasonSelectErrorMsg = '';
      self.reasonTypeSelectErrorMsg = '';
      self.reasonCommentErrorMsg = '';

      self.modifyTransactionMessage = '';
      self.ModifyButton = ''
    },
    modifyTransactionStep1(UpAmtEvent){
      var self = this;
      self.resetModificationDAta();
      if(self.updated_amount != ''){
        self.updated_amount = parseFloat(self.updated_amount).toFixed(2);
      }
      if(self.selectedReasonType == ''){
        self.reasonTypeSelectError = true;
        self.reasonTypeSelectErrorMsg = 'Please fill in the required fields.';
        UpAmtEvent.preventDefault();
      }else if(self.selectedReason == ''){
        self.reasonSelectError = true;
        self.reasonSelectErrorMsg = 'Please fill in the required fields.';
        UpAmtEvent.preventDefault();
      }else if(self.updated_amount == ''){
        self.UpAmtMsg = true;
        self.UpAmtErrorMsg = 'Please fill in the required fields.';
        UpAmtEvent.preventDefault();
      }else if(self.selectedReason.id == 'custom' && self.reasonComment == ''){
        self.reasonCommentError = true;
        self.reasonCommentErrorMsg = 'Please fill in the required fields.';
        UpAmtEvent.preventDefault();
      }else {
        self.updated_amount = parseFloat(self.updated_amount);
        self.tr_amount = parseFloat(self.tr_amount);
        if(self.updated_amount <= self.tr_amount && self.selectedReasonType == 'increase'){
          self.updated_amount = parseFloat(self.updated_amount).toFixed(2);
          self.tr_amount = parseFloat(self.tr_amount).toFixed(2);
          self.UpAmtMsg = true;
          self.UpAmtErrorMsg = 'You are selecting price increase reason. So, new amount must be greater than old amount.';
          UpAmtEvent.preventDefault();
        }else if(self.updated_amount >= self.tr_amount && self.selectedReasonType == 'decrease'){
          self.updated_amount = parseFloat(self.updated_amount).toFixed(2);
          self.tr_amount = parseFloat(self.tr_amount).toFixed(2);
          self.UpAmtMsg = true;
          self.UpAmtErrorMsg = 'You are selecting price decrease reason. So, new amount must be less than old amount.';
          UpAmtEvent.preventDefault();
        }else if((self.updated_amount > self.tr_amount ) || self.selectedReason.need_approval == 1){
          self.modifyTransactionMessage = 'This transaction modification requires consumer approval. Do you want to modify this transaction?';
          self.$refs["modification-modal-step-2"].show();
          self.ModifyButton = 'Confirm & Send'
        } else if(self.updated_amount < self.tr_amount){
          self.modifyTransactionMessage = 'Do you want to modify this transaction?';
          self.$refs["modification-modal-step-2"].show();
          self.ModifyButton = 'Confirm'
        } else {
          self.UpAmtMsg = true;
          self.UpAmtErrorMsg = 'Old and new amount not equal.';
          UpAmtEvent.preventDefault();
        }
      }
    },
    modifyTransaction(UpAmtEvent){
      var self = this;
      if(self.updated_amount == ''){
        self.UpAmtMsg = true;
        UpAmtEvent.preventDefault();
      }
      else {
        self.loading = true;
        self.UpAmtMsg = false;
        var is_transaction_modification_id = localStorage.getItem("transaction_modification_id");
        var request = {
          transaction_id: self.transaction_id,
          updated_amount: self.updated_amount,
          reason_id: self.selectedReason.id,
          reason_comment: self.reasonComment,
        };
        self.hideModal('modification-modal-step-2');
        self.hideModal('modification-modal');
        api
          .merchantTransactionUpdate(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
              self.updated_amount = "";
              if (!is_transaction_modification_id) {
                self.loadDT();
              }
            } else {
              error(response.message);
            }
            self.loading = false;
          })
          .catch((err) => {
            self.loading = false;
            error(err);
          });
      }
    },
    modifiedTransactionHistory(){
      var self = this;
      self.loading = true;
      self.UpAmtMsg = false;
      var request = {
        transaction_id: self.transaction_id,
      };
      api
        .modifiedTransactionHistory(request)
        .then((response) => {
          if ((response.code = 200)) {
            self.transactionHistory = response.data;
            self.$bvModal.show("modified-tr-history-modal");
            success(response.message);
          } else {
            error(response.message);
          }
          self.loading = false;
        })
        .catch((err) => {
          self.loading = false;
          error(err);
        });
    },
    postToBank(){
      var self = this;
      if(self.allSendToBankClick){
        self.allSendToBankClick = false;
        var request = {
          transaction_ids: self.selectedTransactionIds,
        };
      }else{
        var request = {
          transaction_ids: [self.transaction_id],
        };
      }
      self.sendToBank(request);
    },
    allSendToBank(){
      var self = this;
      self.allSendToBankClick = true;
      if(self.selectedTransactionIds.length > 1){
        self.sendToBankModalMsg = 'Do you want to post selected transactions to bank?';
      }else{
        self.sendToBankModalMsg = 'Do you want to post selected transaction to bank?';
      }
      self.$bvModal.show("post-to-bank-model");
    },
    sendToBank(request){
      var self = this;
      self.hideModal('post-to-bank-model');
      self.loading = true;
      self.UpAmtMsg = false;
      api
        .postToBank(request)
        .then((response) => {
          if ((response.code = 200)) {
            success(response.message);
            self.loadDT();
          } else {
            error(response.message);
          }
          self.loading = false;
        })
        .catch((err) => {
          self.loading = false;
          error(err);
        });
    },
    generateReport(reportExport) {
      var self = this;
      // self.$bvModal.show("modification-modal");
      // return false;
      var self = this;
      if(self.selectedStore == ''){
        error("Please select store.");
        return false;
      }
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("The start date cannot be in the future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
      ) {
        error("The end date cannot be from future.");
        return false;
      }
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment($("#end-date").val()).format("YYYY-MM-DD")
      ) {
        error("The end date is not greater than the start date.");
        return false;
      }
      
      self.from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      self.to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      if (reportExport) {
        self.exportReport();
      } else {
        self.loadDT();
      }
    },
    exportReport(){
      var self = this;
      self.loading = true;
      var request = {
        from_date: self.from_date,
        to_date: self.to_date,
        store_id: self.selectedStore.id,
        search_type: self.search_type,
        is_ecommerce: 1,
      };
      if(self.search_type == 'settled'){
        request.toggle_settlement_date_search = self.toggle_settlement_date_search;
      }
      api.getTransactionExport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") +
              "_PendingTransactionList.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {         
          self.loading = false;
          self.getStores();
        });
    },

    getModificationReason(type) {
      var self = this;
      let request = {
        reason_type: type
      };
      self.modificationReasonList = [{'id': 'custom', 'reason' : 'Custom', 'need_approval' : 0}];
      user_api
        .getModificationReason(request)
        .then((response) => {
          self.modalloading = false;
          if ((response.code == 200)) {
            self.modificationReasonList = response.data.concat([{'id': 'custom', 'reason' : 'Custom', 'need_approval' : 0}]);
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          self.modalloading = false;
          error(err.response.data.message);
        });
    },
    selecteReasonType(event) {
      var self = this;
      self.modalloading = true;
      self.reasonTypeSelectError = "";
      self.selectedReason = "";
      self.getModificationReason(event.target.value);
    },
    selecteReason(val) {
      if(val.id == 'custom'){
        this.reasonSelectOther = true;
      }else{
        this.reasonSelectOther = false;
      }
    },
    reSendModification(){
      var self = this;
      self.hideModal('re-send-model');
      self.loading = true;
      var request = {
        transaction_id: self.transaction_id,
      };
      api
        .reSendModification(request)
        .then((response) => {
          if ((response.code = 200)) {
            success(response.message);
            self.loadDT();
          } else {
            error(response.message);
          }
          self.loading = false;
        })
        .catch((err) => {
          self.loading = false;
          error(err);
        });
    },
    onKeyupUpdatedAmount(val){
      if(this.updated_amount == '.'){
        this.updated_amount = '0' + '.'
      }
    },
    directTransactionModificationPopupOpen() {
      var self = this;
      self.transaction_id = self.user.modification_transaction.edit;
      self.modificationReasonList = [];
      self.modalTitle = "Modify Payment Amount for " + self.user.modification_transaction.transaction_number;
      self.tr_amount = self.user.modification_transaction.last_approve_amount;
      self.tip_amount = self.user.modification_transaction.last_approve_tip_amount;
      self.modification_status = self.user.modification_transaction.transaction_status;
      self.$bvModal.show("modification-modal");
    },
    changeStatus(event) {
      var self = this;
      self.showRefreshBtn = false;
      self.enable_settle_toggle = false;
      if(event.target.value == 'settled'){
        self.enable_settle_toggle = true;
        self.hidColumns = [0];
        self.orderable_columns = [6,7,8,9];
        self.dtLeftColumns = [1,2,3,4];
        self.dtCenterColumns = [5,7];
      }
      else if(event.target.value == 'pending'){
        self.hidColumns = [6];
        self.orderable_columns = [0,7,8,9];
        self.dtLeftColumns = [1, 2, 3, 4,5];
        self.dtCenterColumns = [0,7,8,9];
      }
      else{
        self.hidColumns = [0,6,8,9];
        self.orderable_columns = [7];
        self.dtLeftColumns = [1, 2, 3, 4];
        self.dtCenterColumns = [5,7];
      }
    },
    hideRefreshBtn() {
      var self = this;
      self.showRefreshBtn = false;
    }
  },
  mounted() {
    var self = this;
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-07-01')
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date").datepicker("setDate", "-7d");
    $("#end-date").datepicker("setDate", new Date());
    $("#start-date, #end-date").on("change", function() {
      self.showRefreshBtn = false;
    });
    self.getStores();
    document.title = "CanPay - Transactions";
    if (localStorage.getItem("transaction_modification_id")) {
      self.directTransactionModificationPopupOpen();
    }
  },
  beforeDestroy(){
    $(document).off('click', '.pendingviewModifiedTransaction');
  },
};
</script>
<style>
.loading{
  /* z-index: 1100; */
}
.amount_input{
  width: 100%;
  padding: 0px 30px !important;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.doller_icon{
  position: absolute;
  left: 22px;
  top: 42px;
  color: gray;
}



/* cp tooltip  */
.cp-tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.cp-tooltip .cp-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.cp-tooltip .cp-tooltiptext::after {
  content: "";
  position: absolute;
  top: -20%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #555555;
}

.cp-tooltip:hover .cp-tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>