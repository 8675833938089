<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Update User Profile</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div :class="userDetails.role_name != constants.role_corporate_parent ? 'col-md-4' : 'col-md-6'">
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        First Name
                        <span class="red">*</span>
                      </label>
                      <input
                        type="email"
                        v-model="userDetails.first_name"
                        placeholder="Enter First Name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4" v-if="userDetails.role_name != constants.role_corporate_parent">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Middle Name</label>
                      <input
                        type="text"
                        v-model="userDetails.middle_name"
                        placeholder="Enter Middle Name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div :class="userDetails.role_name != constants.role_corporate_parent ? 'col-md-4' : 'col-md-6'">
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        Last Name
                        <span class="red">*</span>
                      </label>
                      <input
                        type="email"
                        v-model="userDetails.last_name"
                        placeholder="Enter Last Name"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        Email address
                        <span class="red">*</span>
                      </label>
                      <input
                        type="email"
                        v-model="userDetails.email"
                        placeholder="Enter email"
                        class="form-control"
                        autocomplete="off"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputPassword1">
                        Phone Number
                        <span class="red">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Phone Number"
                        v-model="userDetails.phone"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Street Address</label>
                      <input
                        type="text"
                        v-model="userDetails.street_address"
                        placeholder="Enter Street Address"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputEmail1">City</label>
                      <input
                        type="text"
                        v-model="userDetails.city"
                        placeholder="Enter City"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputEmail1">State</label>
                      <input
                        type="text"
                        v-model="userDetails.state"
                        placeholder="Enter State"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Zip Code</label>
                      <input
                        type="text"
                        v-model="userDetails.zipcode"
                        placeholder="Enter Zip Code"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="button" @click="updateProfile" class="btn btn-success">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/user.js";
import moment from "moment";
import Constants from "@/common/constant.js";
export default {
  data() {
    return {
      userDetails: JSON.parse(localStorage.getItem("store_user")),
      constants: Constants
    };
  },
  methods: {
    checkValidation() {
      let self = this;
      if (
        self.userDetails.first_name == "" ||
        self.userDetails.last_name == "" ||
        self.userDetails.email == "" ||
        self.userDetails.phone == ""
      ) {
        error("Please fill in the required fields!");
        return false;
      }
      return true;
    },
    updateProfile() {
      let self = this;
      //update profile
      if (self.checkValidation()) {
        api
          .updateProfile(self.userDetails)
          .then(response => {
            if (response.code == 200) {
              localStorage.setItem("store_user", JSON.stringify(response.data));
              console.log(response);
              success(response.message);
              self.$router.go();
            } else {
              error(response.message);
            }
          })
          .catch(err => {
            error(err);
          });
      }
    }
  },
  mounted() {
    var self = this;
    self.dob = moment(JSON.parse(localStorage.getItem("store_user")).date_of_birth).format("MM-DD-YYYY");
    if(self.userDetails.role_name == Constants.role_corporate_parent){
      self.userDetails.first_name = self.userDetails.contact_person_first_name;
      self.userDetails.last_name = self.userDetails.contact_person_last_name;
      self.userDetails.email = self.userDetails.contact_person_email;
      self.userDetails.phone = self.userDetails.contact_person_phone;
    }
    document.title = "CanPay - Edit Profile";
  }
};
</script>
