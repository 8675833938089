<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title mb-0">Store</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <multiselect
                              id="store"
                              name="store"
                              v-model="selectedStore"
                              placeholder="Select Store"
                              label="retailer"
                              track-by="id"
                              :options="storeList"
                              :multiple="false"
                              :searchable="true"
                              :show-labels="false"
                            ></multiselect>
                          </div>
                        </div>
                        <div class="col-md-6">
                            <button
                            :disabled="loading"
                            type="button"
                            class="btn btn-success mr-3"
                            @click="searchStore()"
                            >
                            Search
                            </button>
                            <button
                            type="button"
                            @click="reset()"
                            class="btn btn-success margin-left-5"
                            >
                            Reset
                            </button>
                        </div>
                    </div>
                    
                    <div class="loading" v-if="loading">
                      <hour-glass></hour-glass>
                    </div>
                </div>
                <div v-if="storeDetails && storeDetails.store_name">
                  <div 
                  class="card-footer">
                      <div class="row m-0">
                        <div class="col-12 col-md-6 row align-items-center">
                          <h5 class="mb-0">Store Name: <strong>{{storeDetails.store_name}}</strong>, Timezone: <strong>{{storeDetails.timezone}}</strong></h5>
                        </div>
                        <div class="col-12 col-md-6 row align-items-center">
                          <b class="mr-2">Google Sync</b>
                          <label class="switch mb-0"
                          ><input
                            class="enable-employee-login"
                            type="checkbox"
                            @change="updateStoreSyncing"
                            v-model="storeDetails.sync_disabled" /><span
                            class="slider round"
                          ></span
                          ></label>
                        </div>
                      </div>
                  </div>
                  <hr class="my-0">
                  <div class="card-body">
                      <b-table-simple
                        responsive
                        show-empty
                        bordered
                        sticky-header="800px"
                      >
                          <b-thead head-variant="light">
                              <tr>
                                  <th width="20%">Day</th>
                                  <th width="20%">RemotePay Close</th>
                                  <th width="20%">RemotePay Open 24hrs</th>
                                  <th width="20%">Action</th>
                              </tr>
                          </b-thead>
                          <b-tbody v-for="(row, index) in storeDetails.timings" :key="index">
                              <b-tr>
                                  <b-td class="text-left text-gray">
                                      {{row.day}}
                                  </b-td>
                                  <b-td class="text-left text-gray">
                                      {{row.ecommerce_close_time}}
                                  </b-td>
                                  <b-td class="text-left text-gray">
                                      {{row.ecommerce_open_all_day == 1 ? 'Yes' : 'No'}}
                                  </b-td>
                                  <b-td class="text-left text-gray">
                                      <a @click="openModal('edit', 'st-modal', row)" class="custom-edit-btn" title="Edit holiday" variant="outline-success" style="border:none"><i class="nav-icon fas fa-edit"></i></a>
                                  </b-td>
                              </b-tr>
                          </b-tbody>
                      </b-table-simple>
                  </div>
                  <div class="card-footer">
                      <h5 class="mb-0">Holiday List</h5>
                  </div>
                  <hr class="my-0">
                  <div class="card-body">
                      <multiselect
                      id="holiday_id"
                      name="holiday_id"
                      @select="onSelectHoliday"
                      :options="holidayOptions"
                      :multiple="true"
                      track-by="holiday_name"
                      label="holiday_name" 
                      :custom-label="customHolidaySelectLabel"
                      placeholder="Assign Holiday or Restricted Operational Hours"
                      deselectLabel="Un assign"
                      selectedLabel="Assigned"
                      :close-on-select="true"
                      :clear-on-select="true"
                      group-values="holidayIndividual"
                      group-label="selectAll"
                      :group-select="true"
                    ></multiselect>
                  </div>
                  <div class="card-body">
                      <b-table-simple
                        responsive
                        show-empty
                        bordered
                        sticky-header="800px"
                      >
                          <b-thead head-variant="light">
                              <tr>
                                  <th width="20%">Holiday name</th>
                                  <th width="20%">Date</th>
                              </tr>
                          </b-thead>
                          <b-tbody v-for="(row, index) in storeDetails.holidays" :key="index">
                              <b-tr>
                                  <b-td class="text-left text-gray">
                                      {{row.holiday_name}}
                                  </b-td>
                                  <b-td class="text-left text-gray">
                                      {{row.date}}
                                  </b-td>
                              </b-tr>
                          </b-tbody>
                      </b-table-simple>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Store Timing Update Modal Start -->
    <b-modal
      id="st-modal"
      ref="st-modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="false"
    >
      <form ref="timingForm" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
           <div class="col-md-12">
            <div class="form-group">
              <label for="exampleInputEmail1">
                {{storeTimingDetails.day}}
              </label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="exampleInputEmail1">
                RemotePay Close Time
                <span class="red">*</span>
              </label>
              <CPTimePicker 
              :cp-disabled="storeTimingDetails.ecommerce_open_all_day == 1"
              v-validate="'required'" 
              v-model="storeTimingDetails.ecommerce_close_time"
              />
              <span v-show="errors.has('ecommerce_close_time')" class="text-danger">{{
              errors.first("ecommerce_close_time")
              }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="exampleInputEmail1">
                RemotePay Open 24hrs
                <span class="red">*</span>
              </label>
              <br>
              <label class="switch mb-0"
              ><input
                class="enable-employee-login"
                type="checkbox"
                v-model="storeTimingDetails.ecommerce_open_all_day" /><span
                class="slider round"
              ></span
              ></label>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Store Timing Update Modal End -->

  </div>
</template>
<script>
import api from "@/api/holidaymaster.js";
import user_api from "@/api/user.js";
import { validationMixin } from "vuelidate";
import CPTimePicker from './components/CPTimePicker.vue';
import { HourGlass } from "vue-loading-spinner";

export default {
  mixins: [validationMixin],
  data() {
    return {
        headerTextVariant: "light",
        selectedStore: "",
        storeList: [],
        storeDetails: {},
        google_sync: false,
        modalTitle: "",
        holidayOptions: [],
        loading: false,
        storeTimingDetails: {},
    };
  },
  components: {
    HourGlass,
    CPTimePicker
  },
  methods: {
    searchStore(){
      var self = this;
      if(self.selectedStore == ''){
        error("Please select store.");
        return false;
      }
      var request = {
        store_id: self.selectedStore.id,
      };
      self.loading = true;
      api
      .getStoreBySearch(request)
      .then(function (response) {
        if (response.code == 200) {
          self.storeDetails = response.data;
          self.storeDetails.sync_disabled = response.data.sync_disabled == 0 ? true : false;
          self.loading = false;
          self.getHolidays(response.data.store_id);
        } else {
          error(response.message);
          self.loading = false;
        }
      })
      .catch(function (error) {
        error(error);
        self.loading = false;
      });
    },
    reset(){
      var self = this;
      self.selectedStore = "";
      self.storeDetails = {}
    },
    resetModal() {
      var self = this;
      self.storeTimingDetails = {};
    },
    getHolidays(store_id){
      var self = this;
      var request = {}
      request.store_id = store_id
      api
      .getHolidays(request)
      .then(function (response) {
        if (response.code == 200) {
          self.holidayOptions = [
            {
                selectAll : 'Select All Holidays',
                holidayIndividual :  response.data
            }
          ]
        } else {
          error(response.message);
        }
      })
      .catch(function (error) {
        error(error);
      });
    },
    updateStoreSyncing(){
      var self = this;
      var request = {
        store_id: self.storeDetails.store_id,
        sync_disabled: self.storeDetails.sync_disabled ? 0 : 1,
      };
      api
      .updateStoreSyncing(request)
      .then(function (response) {
        if (response.code == 200) {
          success(response.message);
        } else {
          error(response.message);
        }
      })
      .catch(function (error) {
        error(error);
      });
    },
    handleOk(bvModalEvt){
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },
    save(){
      var self = this;
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {
        if (result) {
          // CHECK WITH formTYPE 

          let request = {};
          request.store_id = self.storeTimingDetails.store_id
          request.day = self.storeTimingDetails.day

          if(self.storeTimingDetails.store_open_all_day == true){
            request.store_open_all_day = 1
          }else{
            request.open_time = self.storeTimingDetails.open_time
            request.close_time = self.storeTimingDetails.close_time
            request.store_open_all_day = 0
          }
          if(self.storeTimingDetails.ecommerce_open_all_day == true){
            request.ecommerce_open_all_day = 1
          }else{
            request.ecommerce_close_time = self.storeTimingDetails.ecommerce_close_time
            request.ecommerce_open_all_day = 0
          }

          //call to api to save the details
          if (self.storeTimingDetails.id) {
            // Add section
            api
              .saveStoreTiming(request)
              .then((response) => {
                if (response.code == 200) {
                  success(response.message);
                  self.searchStore();
                  self.$bvModal.hide("st-modal");
                  self.resetModal();
                } else {
                  error(response.message);
                }
              })
              .catch((err) => {
                error(err.response.data.message);
              });
          }
        }
      });
    },
    customHolidaySelectLabel(holiday) {
      return `${holiday.holiday_name}`;
    },
    openModal(type, modalType, data = null) {
      var self = this;
      if(modalType == 'st-modal'){
        if (type == "edit") {
          self.modalTitle = "Edit Store Timing";
          self.$bvModal.show("st-modal");
          self.storeTimingDetails = {
            id: data.id,
            store_id: data.store_id,
            day: data.day,
            open_time: data.open_time,
            close_time: data.close_time,
            store_open_all_day: data.store_open_all_day,
            ecommerce_close_time: data.ecommerce_close_time,
            ecommerce_open_all_day: data.ecommerce_open_all_day
          }
        } else {
          self.modalTitle = "Add Store Timing";
          self.$bvModal.show("st-modal");
        }
      }
    },
    onSelectHoliday(val){
      var self = this;
      if(!val.length){
        val = [val]
      }
      var request = {
        store_id: null,
        holiday_ids: [],
      }
      request.store_id = self.storeDetails.store_id;
      val.forEach(holiday => {
        request.holiday_ids.push(holiday.id);
      });

      // Add section
      api
        .assignStoreToMulitpleHoliday(request)
        .then((response) => {
          if (response.code == 200) {
            success(response.message);
            self.searchStore();
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
    },
    getStores() {
      var self = this;
      self.loading = true;
      let request = {
        params: {
          is_ecommerce: 1
          }
      };
      user_api
        .getStores(request)
        .then((response) => {
          if ((response.code == 200)) {
            self.storeList = response.data;
          } else {
            error(response.message);
          }
          self.loading = false;
        })
        .catch((err) => {
          self.loading = false;
          error(err.response.data.message);
        });
    },
  },
  mounted() {
    var self = this;
    document.title = "CanPay - Stores List";
    self.getStores();
  },
};
</script>

<style scoped>
.multiselect__input::placeholder{
  color: violet!important;
}
</style>

