<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-button
                variant="outline-success"
                style="margin-top: -48px"
                @click="openModal('add')"
              >
                <i class="fas fa-user-plus"></i> Add Template
              </b-button>
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title mb-0">Holiday Template Master</h3>
                  <b-button
                    class="btn-danger export-api-btn"
                    @click="reloadDatatable"
                    v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <div class="card-body">
                  <table
                    id="templateMasterTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th>Template Name</th>
                        <th>Assigned To</th>
                        <th>Assign</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Holiday Template Modal Start -->
    <b-modal
      id="ht-modal"
      ref="ht-modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div v-if="loadingTemplateDetails == true" class="d-flex flex-column align-items-center">
        <i  class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <div>Please wait, we are featching template details...</div>
      </div>
      <form v-else @submit.stop.prevent="save" class="needs-validation">
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="template_name">
              Template Name
              <span class="red">*</span>
            </label>
            <input
              name="template_name"
              v-validate="'required'"
              type="text"
              v-model="templateMasterDetails.template_name"
              class="form-control"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('template_name')" class="text-danger">{{
              errors.first("template_name")
            }}</span>
          </div>
        </div>

        <div v-if="templateMasterDetails.store_associated" class="row mb-3">
          <div class="col-md-12">
            <label for="template_name">
              Total {{templateMasterDetails.store_associated}} store associated with this template.
            </label>
            
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <label for="holidays">
              Assign holiday
            </label>
            <multiselect
              id="holidays"
              name="holidays"
              v-model="templateMasterDetails.holidays"
              :options="holidayOptions"
              :multiple="true"
              track-by="holiday_name"
              label="holiday_name" 
              :custom-label="customHolidaySelectLabel"
              placeholder="Select Holiday"
              selectLabel
              deselectLabel
              :close-on-select="false"
              :clear-on-select="false"
              group-values="holidayIndividual"
              group-label="selectAll"
              :group-select="true"
            ></multiselect>
            <input
              type="hidden"
              v-model="templateMasterDetails.holidays"
              name="holidays"
              v-validate="'required'"
              />
            <span v-show="errors.has('holidays')" class="text-danger">{{
            errors.first("holidays")
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table-simple
            class="cp-table"
            responsive
            show-empty
            bordered
            sticky-header="800px"
            >
                <b-thead head-variant="light">
                    <tr>
                        <th width="20%">Day</th>
                        <th width="20%">RemotePay Close</th>
                        <th width="20%">RemotePay Open 24hrs</th>
                    </tr>
                </b-thead>
                <b-tbody v-for="(row, index) in templateMasterDetails.timings" :key="index">
                    <TemplateMasterTimingRow :row="row" />
                </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Holiday Template Modal End -->

    <!-- Holiday Template Copy Modal Start -->
    <b-modal
      id="copy-ht-modal"
      ref="copy-ht-modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div v-if="loadingTemplateDetails == true" class="d-flex flex-column align-items-center">
        <i  class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <div>Please wait, we are featching template details...</div>
      </div>
      <form v-else ref="holidayTemplateForm" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <label for="template_name">
              Template Name
              <span class="red">*</span>
            </label>
            <input
              name="template_name"
              v-validate="'required'"
              type="text"
              v-model="templateMasterDetails.template_name"
              class="form-control"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('template_name')" class="text-danger">{{
              errors.first("template_name")
            }}</span>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-md-12">
            <label for="holidays">
              Assign holiday
              <span class="red">*</span>
            </label>
            <multiselect
              id="holidays"
              name="holidays"
              v-model="templateMasterDetails.holidays"
              :options="holidayOptions"
              :multiple="true"
              track-by="holiday_name"
              label="holiday_name" 
              :custom-label="customHolidaySelectLabel"
              placeholder="Select Holiday"
              selectLabel
              deselectLabel
              :close-on-select="false"
              :clear-on-select="false"
              group-values="holidayIndividual"
              group-label="selectAll"
              :group-select="true"
            ></multiselect>
            <input
              type="hidden"
              v-model="templateMasterDetails.holidays"
              name="holidays"
              v-validate="'required'"
              />
            <span v-show="errors.has('holidays')" class="text-danger">{{
            errors.first("holidays")
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table-simple
            class="cp-table"
            responsive
            show-empty
            bordered
            sticky-header="800px"
            >
                <b-thead head-variant="light">
                    <tr>
                        <th width="20%">Day</th>
                        <th width="20%">RemotePay Close</th>
                        <th width="20%">RemotePay Open 24hrs</th>
                    </tr>
                </b-thead>
                <b-tbody v-for="(row, index) in templateMasterDetails.timings" :key="index">
                    <TemplateMasterTimingRow :row="row" />
                </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Holiday Template Copy Modal End -->

    <!-- Assign Template Modal Start -->
    <b-modal
      id="assign-template-modal"
      ref="assign-template-modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="assignTemplateToStore"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div v-if="loadingStoreDetails == true" class="d-flex flex-column align-items-center">
        <i  class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <div>Please wait, we are featching store details...</div>
      </div>
      <form v-else ref="assignTemplateForm" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <label for="template_name">
              Template Name: {{assignTemplateDetails.template_name}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="assigned_name">
              Assigned Stores:
            </label>
          </div>
          <AssignStoreBadgeToTemplate
          class="col-auto"
          v-for="assignedStore in assignedStores" 
          :key="assignedStore.id" 
          :assigned-store="assignedStore"
          :template-id="templateId"
          :can-remove="true"
          />
        </div>
        <div class="row my-3">
          <div class="col-md-12">
            <label for="store_id">
              Available Stores
              <span class="red">*</span>
            </label>
            <multiselect
              id="store_id"
              name="store_id"
              v-model="assignTemplateDetails.store_ids"
              :options="storeOptions"
              :multiple="true"
              track-by="retailer"
              label="retailer" 
              :custom-label="customStoreSelectLabel"
              placeholder="Select Store"
              selectLabel
              deselectLabel
              :close-on-select="false"
              :clear-on-select="false"
              group-values="storeIndividual"
              group-label="selectAll"
              :group-select="true"
            ></multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="exampleInputEmail1">
                Do not sync with Google
              </label>
              <br>
              <label class="switch mb-0"
              ><input
                class="enable-employee-login"
                type="checkbox"
                v-model="assignTemplateDetails.sync_disabled" /><span
                class="slider round"
              ></span
              ></label>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Assign Template Modal End -->

    <!----- MODAL FOR DELETE CONFIRMATION---->
    <div>
      <b-modal ref="confirm-modal" hide-footer hide-header id="confirm-modal">
        <div class="color">
          <div class="col-12 text-center">
            <svg width="100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 473 473" style="enable-background:new 0 0 473 473;" xml:space="preserve">
            <g>
            <path fill="#149240" d="M317.667,214.42l5.667-86.42h20.951V38h-98.384V0H132.669v38H34.285v90h20.951l20,305h140.571
            c23.578,24.635,56.766,40,93.478,40c71.368,0,129.43-58.062,129.43-129.43C438.715,275.019,385.143,218.755,317.667,214.42z
            M162.669,30h53.232v8h-53.232V30z M64.285,68h250v30h-250V68z M103.334,403L85.301,128H293.27l-5.77,87.985
            c-61.031,10.388-107.645,63.642-107.645,127.586c0,21.411,5.231,41.622,14.475,59.43H103.334z M309.285,443
            c-54.826,0-99.43-44.604-99.43-99.43s44.604-99.429,99.43-99.429s99.43,44.604,99.43,99.429S364.111,443,309.285,443z"/>
            <polygon fill="#149240" points="342.248,289.395 309.285,322.358 276.322,289.395 255.109,310.608 288.072,343.571 255.109,376.533 
            276.322,397.746 309.285,364.783 342.248,397.746 363.461,376.533 330.498,343.571 363.461,310.608 	"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="text-justify text-secondary h4">
                Are you sure ?
              </label>
              <br />
              <label class="text-justify text-secondary text-dark">
                Do you want to delete this template?
              </label>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  @click="hideModal('confirm-modal')"
                  class="btn btn-secondary btn-md center-block mr-2"
                >
                  <label class="forgetpassword-ok-label mb-0">Cancel</label>
                </button>
                <button
                  @click="deleteTemplateMasterConfirm()"
                  class="btn btn-success btn-md center-block ml-2"
                >
                  <label class="forgetpassword-ok-label mb-0">Confirm</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <!----- MODAL FOR EDIT CONFIRMATION---->
    <div>
      <b-modal ref="edit-confirm-modal" hide-footer hide-header id="edit-confirm-modal">
        <div class="color">
          <div class="col-12 text-center">
            <svg width="100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
            <g id="XMLID_23_">
            <path fill="#149240" id="XMLID_24_" d="M75,180v60c0,8.284,6.716,15,15,15h60c3.978,0,7.793-1.581,10.606-4.394l164.999-165
            c5.858-5.858,5.858-15.355,0-21.213l-60-60C262.794,1.581,258.978,0,255,0s-7.794,1.581-10.606,4.394l-165,165
            C76.58,172.206,75,176.022,75,180z M105,186.213L255,36.213L293.787,75l-150,150H105V186.213z"/>
            <path fill="#149240" id="XMLID_27_" d="M315,150.001c-8.284,0-15,6.716-15,15V300H30V30H165c8.284,0,15-6.716,15-15s-6.716-15-15-15H15
            C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V165.001C330,156.716,323.284,150.001,315,150.001z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="text-justify text-secondary h4">
                Are you sure ?
              </label>
              <br />
              <label class="text-justify text-secondary text-dark">
                Do you want to edit this template?
              </label>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  @click="hideModal('edit-confirm-modal')"
                  class="btn btn-secondary btn-md center-block mr-2"
                >
                  <label class="forgetpassword-ok-label mb-0">Cancel</label>
                </button>
                <button
                  @click="editTemplateMasterConfirm()"
                  class="btn btn-success btn-md center-block ml-2"
                >
                  <label class="forgetpassword-ok-label mb-0">Confirm</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    </div>
</template>
<script>
import moment from "moment";
import api from "@/api/holidaymaster.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
import TemplateMasterTimingRow from "./components/TemplateMasterTimingRow.vue";
import AssignStoreBadgeToTemplate from "./components/AssignStoreBadgeToTemplate.vue";

export default {
  mixins: [validationMixin],
  data() {
    return {
        modalTitle: "",
        formType: "",
        headerTextVariant: "light",

        templateId: null,

        allTemplateModel: [],
        templateMasterDetails: {
          template_id: null,
          template_name: null,
          holidays: [],
          timings: [
            {
                day: 'Monday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            },
            {
                day: 'Tuesday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            },
            {
                day: 'Wednesday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            },
            {
                day: 'Thursday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            },
            {
                day: 'Friday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            },
            {
                day: 'Saturday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            },
            {
                day: 'Sunday',
                open_time: '',
                close_time: '',
                ecommerce_close_time: '',
                store_open_all_day: false,
                ecommerce_open_all_day: false,
            }
          ]
        },

        showReloadBtn: false,
        constants: Constants,

        holidayOptions: [],

        assignTemplateDetails: {},
        storeOptions: [],
        assignedStores: [],
        loadingStoreDetails: false,
        loadingTemplateDetails: false
    };
  },
  components:{
    TemplateMasterTimingRow,
    AssignStoreBadgeToTemplate
  },
  created() {
    var self = this;
    self.assignTemplateMasterToStore();
    self.copyTemplateMaster();
    self.editTemplateMaster();
    self.deleteTemplateMaster();
  },
  methods: {
    reloadDatatable() {
      var self = this;
      self.loadDT();
    },
    customHolidaySelectLabel(holiday) {
      return `${holiday.holiday_name}`;
    },
    customStoreSelectLabel(store) {
      return `${store.retailer}`;
    },
    openModal(type) {
      var self = this;
      if (type == "edit") {
        self.modalTitle = "Edit Template Master";
        self.formType = 'edit';
        self.$bvModal.show("ht-modal");
      } else {
        self.formType = 'add';
        self.modalTitle = "Add Template Master";
        self.getHolidays();
        self.$bvModal.show("ht-modal");
      }
    },
    resetModal() {
      var self = this;
      self.templateId = null
      self.templateMasterDetails = {
        template_id: null,
        template_name: null,
        holidays: [],
        timings: [
          {
              day: 'Monday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          },
          {
              day: 'Tuesday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          },
          {
              day: 'Wednesday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          },
          {
              day: 'Thursday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          },
          {
              day: 'Friday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          },
          {
              day: 'Saturday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          },
          {
              day: 'Sunday',
              open_time: '',
              close_time: '',
              ecommerce_close_time: '',
              store_open_all_day: false,
              ecommerce_open_all_day: false,
          }
        ]
      }
      self.assignTemplateDetails = {};
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if(self.formType == 'edit' && self.templateMasterDetails.store_associated > 0){
        self.$bvModal.show("edit-confirm-modal");
      }else{
        self.save();
      }
      // Trigger submit handler
    },
    assignTemplateToStore(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.$validator.validateAll().then((result) => {
        if (result) {
          // CHECK WITH formTYPE 
           var request = {
            template_id: null,
            store_ids: [],
            sync_disabled: null,
          }
          request.template_id = self.assignTemplateDetails.edit;
          self.assignTemplateDetails.store_ids.forEach(store => {
            request.store_ids.push(store.id);
          });
          request.sync_disabled = self.assignTemplateDetails.sync_disabled == true ? 0 : 1;

          //call to api to save the details
          // Add section
          api
            .assignTemplateToStore(request)
            .then((response) => {
              if (response.code == 200) {
                success(response.message);
                $("#templateMasterTable").DataTable().ajax.reload(null, false);
                self.$bvModal.hide("assign-template-modal");
                self.resetModal();
              } else {
                error(response.message);
              }
            })
            .catch((err) => {
              error(err.response.data.message);
            });
         
        }
      });
    },
    save() {
      var self = this;
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {
        if (result) {
          // CHECK WITH formTYPE 

          var request = {
            holiday_ids: [],
            days: [],
            open_time: [],
            close_time: [],
            ecommerce_close_time: [],
            store_open_all_day: [],
            ecommerce_open_all_day: [],
          }

          if(self.templateMasterDetails.template_id){
            request.template_id = self.templateMasterDetails.template_id
          }

          request.template_name = self.templateMasterDetails.template_name
          self.templateMasterDetails.holidays.forEach(holiday => {
            request.holiday_ids.push(holiday.id);
          });
          self.templateMasterDetails.timings.forEach(timing => {
            request.days.push(timing.day);
            request.open_time.push(timing.open_time);
            request.close_time.push(timing.close_time);
            request.ecommerce_close_time.push(timing.ecommerce_close_time);
            request.store_open_all_day.push(timing.store_open_all_day == true ? 1 : 0);
            request.ecommerce_open_all_day.push(timing.ecommerce_open_all_day == true ? 1 : 0);
          });

          //call to api to save the details
          // Add section
          api
            .saveTemplate(request)
            .then((response) => {
              if (response.code == 200) {
                success(response.message);
                $("#templateMasterTable").DataTable().ajax.reload(null, false);
                self.$bvModal.hide("ht-modal");
                self.$bvModal.hide("copy-ht-modal");
                self.$bvModal.hide("edit-confirm-modal");
                self.resetModal();
              } else {
                error(response.message);
              }
            })
            .catch((err) => {
              error(err.response.data.message);
            });
          
        }
      });
    },
    getTemplateDetails(template_id, type) {
        var self = this;
        self.loadingTemplateDetails = true
        var request = {};
        request.template_id = template_id
        api
        .getTemplateDetails(request)
        .then(function (response) {
          if (response.code == 200) {
            
            if(type == 'edit'){
              self.templateMasterDetails.template_id = template_id
            }
            if(type != 'copy'){
              self.templateMasterDetails.template_name = response.data.template_name
            }
            self.templateMasterDetails.store_associated = response.data.store_associated
            self.templateMasterDetails.holidays = response.data.holidays
            self.templateMasterDetails.timings = []
            response.data.store_timings.forEach(timing => {
              self.templateMasterDetails.timings.push({
                day: timing.day,
                open_time: timing.open_time,
                close_time: timing.close_time,
                ecommerce_close_time: timing.ecommerce_close_time,
                store_open_all_day: timing.store_open_all_day == 1 ? true : false,
                ecommerce_open_all_day: timing.ecommerce_open_all_day == 1 ? true : false,
              })
            });

            self.loadingTemplateDetails = false

          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
        });
    },
    getHolidays() {
        var self = this;
        api
        .getHolidays()
        .then(function (response) {
          if (response.code == 200) {
            self.holidayOptions = [
              {
                  selectAll : 'Select All Holidays',
                  holidayIndividual :  response.data
              }
            ]
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
        });
    },
    getStoresForTemplates(template_id) {
        var self = this;
        self.loadingStoreDetails = true
        var request = {}
        request.template_id = template_id
        api
        .getStoresForTemplates(request)
        .then((response) => {
          if ((response.code = 200)) {
            self.storeOptions = [{
              selectAll : 'Select All Stores',
              storeIndividual : response.data.stores_available
            }];
            self.assignedStores = response.data.stores_assigned
            self.loadingStoreDetails = false
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
    },
    loadDT: function () {
      var self = this;
      $("#templateMasterTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [3] },
          { className: "dt-left", targets: [0, 1, 2] },
          { className: "dt-center", targets: [3] },
        ],
        order: [[2, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Templates Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchantadmin/gettemplates",
          type: "POST",
          data: { _token: "{{csrf_token()}}", user_type: "Store Manager" },
          dataType: "json",
          dataSrc: function (result) {

            self.showReloadBtn = false;
            self.allTemplateModel = result.data;
            return result.data;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            } else {
              error(Constants.datatable_error);
              $("#templateMasterTable_processing").hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "template_name" },
          { data: "assigned_stores" },
          {
            render: function (data, type, full, meta) {
              return '<button data-template-id="' +full.edit +'" class="assignTemplate btn btn-success btn-sm" title="Assign to a store" variant="outline-success">Assign</button>';
            },
          },
          {
            render: function (data, type, full, meta) {
              var deleteButtonHtml = ''
              if(full.can_edit_delete === 1){
                deleteButtonHtml = '<b-button data-template-id="' + full.edit + '" class="deleteTemplateDetails custom-edit-btn" title="Delete Template Details" variant="outline-success"><i class="nav-icon fas fa-trash"></i></b-button>'
              }
              return (
                '<b-button data-template-id="' +
                full.edit +
                '" class="copyTemplateDetails custom-edit-btn" title="Copy Template Details" variant="outline-success"><i class="nav-icon fas fa-copy"></i>&nbsp;&nbsp;</b-button><b-button data-template-id="' +
                full.edit +
                '" class="editTemplateDetails custom-edit-btn" title="Edit Template Details" variant="outline-success"><i class="nav-icon fas fa-edit"></i>&nbsp;&nbsp;</b-button>' + deleteButtonHtml

              );
            },
          },
        ],
      });

      $("#templateMasterTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#templateMasterTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
        .unbind() // Unbind previous default bindings
        .bind("input", function (e) {
          // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if (this.value.length >= 3 || e.keyCode == 13) {
            // Call the API search function
            dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if (this.value == "") {
            dtable.search("").draw();
          }
          return;
        });
    },
    copyTemplateMaster() {
        var self = this;
        $(document).on("click", ".copyTemplateDetails", function (e) {
            self.templateId = $(e.currentTarget).attr("data-template-id");
            self.getTemplateDetails(self.templateId, 'copy');
            self.getHolidays();
            self.modalTitle = "Copy Template Master and Create New";
            self.formType = 'copy';
            self.$bvModal.show("copy-ht-modal");
        });
    },
    editTemplateMaster() {
      var self = this;
      $(document).on("click", ".editTemplateDetails", function (e) {
        self.templateId = $(e.currentTarget).attr("data-template-id");
        self.getTemplateDetails(self.templateId, 'edit');
        self.getHolidays();
        self.modalTitle = "Edit Template Masterss";
        self.formType = 'edit';
        self.$bvModal.show("ht-modal");
      });
    },
    deleteTemplateMaster() {
      var self = this;
      $(document).on("click", ".deleteTemplateDetails", function (e) {
        self.templateId = $(e.currentTarget).attr("data-template-id");
        self.$bvModal.show("confirm-modal");
      });
    },
    assignTemplateMasterToStore() {
      var self = this;
      $(document).on("click", ".assignTemplate", function (e) {
        self.templateId = $(e.currentTarget).attr("data-template-id");
        self.assignTemplateDetails = self.allTemplateModel.find(
          (p) => p.edit == self.templateId
        );
        self.modalTitle = "Assign Template to Store";
        self.$bvModal.show("assign-template-modal");
        self.getStoresForTemplates(self.templateId);
      });
    },
    deleteTemplateMasterConfirm() {
      var self = this;
      var request = {
        template_id: self.templateId,
      };
      api
        .deleteTemplate(request)
        .then((response) => {
          if ((response.code = 200)) {
            success("Template deleted successfully.");
            $("#templateMasterTable").DataTable().ajax.reload(null, false);
            self.$bvModal.hide("confirm-modal");
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
     
    },
    editTemplateMasterConfirm(){
      var self = this;
      self.save();
    },
    hideModal(val) {
      this.$bvModal.hide(val);
    },
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    document.title = "CanPay - Template Master";
  },
};
</script>

<style lang="scss">
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.cp-table{
  overflow-y: unset!important;
  overflow-x: unset!important;
}
</style>

