const login = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchantadmin/login', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const forgotPassword = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchantadmin/forgotpassword', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const loginWthPin = (request) => {
    return new Promise((res, rej) => {
        axios.post('merchantadmin/loginwithpin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    login,
    forgotPassword,
    loginWthPin
};