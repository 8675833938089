<template>
  <router-view />
</template>
<script>
export default {
  mounted() {
    if (localStorage.getItem("token") == null && !this.$router.currentRoute.fullPath.match(/^\/login\/((?:[^\/]+?))(?:\/(?=$))?$/i)) {
      this.$router.push("/login").catch(()=>{});
    }
    else if(sessionStorage.getItem("token") == null && !this.$router.currentRoute.fullPath.match(/^\/login\/((?:[^\/]+?))(?:\/(?=$))?$/i))
    {
      this.$router.push("/login").catch(()=>{});
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
@import "@/assets/styles/main.scss";
</style>
