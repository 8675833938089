const addStoreDailyTransactionEmail = (request) => {
    return new Promise((res, rej) => {
        axios.post('/merchantadmin/addstoredailytransactionemail', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    addStoreDailyTransactionEmail
};